//import React, { memo, useState } from "react";
//import { Text, View } from "react-native";

export const colleges = [
  {
    label: "Abilene Christian University",
    value: "ACU",
    url: "http://www.acu.edu/",
  },
  {
    label: "Academy of Art College",
    value: "AAC",
    url: "http://www.academyart.edu/",
  },
  { label: "Adams State College", value: "ASC", url: "http://www.adams.edu/" },
  { label: "Adelphi University", value: "AU", url: "http://www.adelphi.edu/" },
  {
    label: "Adler School of Professional Psychology",
    value: "ASPP",
    url: "http://www.adler.edu/",
  },
  { label: "Adrian College", value: "AC", url: "http://www.adrian.edu/" },
  {
    label: "Agnes Scott College",
    value: "ASC_1",
    url: "http://www.scottlan.edu/",
  },
  {
    label: "Air Force Institute of Technology",
    value: "AFIT",
    url: "http://www.afit.af.mil/",
  },
  {
    label: "Alabama Agricultural and Mechanical University",
    value: "AAMU",
    url: "http://www.aamu.edu/",
  },
  {
    label: "Alabama State University",
    value: "ASU",
    url: "http://www.alasu.edu/",
  },
  {
    label: "Alaska Bible College",
    value: "ABC",
    url: "http://www.akbible.edu/",
  },
  {
    label: "Alaska Pacific University",
    value: "APU",
    url: "http://www.alaskapacific.edu/",
  },
  {
    label: "Albany College of Pharmacy",
    value: "ACP",
    url: "http://www.acp.edu/",
  },
  {
    label: "Albany Law School",
    value: "ALS",
    url: "http://www.albanylaw.edu/",
  },
  { label: "Albany Medical Center", value: "AMC", url: "http://www.amc.edu/" },
  {
    label: "Albany State University",
    value: "ASU_1",
    url: "http://www.asurams.edu/",
  },
  {
    label: "Albertus Magnus College",
    value: "AMC_1",
    url: "http://www.albertus.edu/",
  },
  { label: "Albion College", value: "AC_1", url: "http://www.albion.edu/" },
  { label: "Albright College", value: "AC_2", url: "http://www.albright.edu/" },
  {
    label: "Alcorn State University",
    value: "ASU_2",
    url: "http://www.alcorn.edu/",
  },
  {
    label: "Alderson Broaddus College",
    value: "ABC_1",
    url: "http://www.ab.edu/",
  },
  {
    label: "Alfred Adler Graduate School",
    value: "AAGS",
    url: "http://www.alfredadler.edu/",
  },
  { label: "Alfred University", value: "AU_1", url: "http://www.alfred.edu/" },
  { label: "Alice Lloyd College", value: "ALC", url: "http://www.alc.edu/" },
  { label: "Allegheny College", value: "AC_3", url: "http://www.alleg.edu/" },
  {
    label: "Allen University",
    value: "AU_2",
    url: "http://www.scicu.org/allen/",
  },
  { label: "Alma College", value: "AC_4", url: "http://www.alma.edu/" },
  { label: "Alvernia College", value: "AC_5", url: "http://www.alvernia.edu/" },
  { label: "Alverno College", value: "AC_6", url: "http://www.alverno.edu/" },
  {
    label: "Ambassador University",
    value: "AU_3",
    url: "http://www.ambassador.edu/",
  },
  { label: "Amber University", value: "AU_4", url: "http://www.amberu.edu/" },
  {
    label: "American Academy of Nutrition",
    value: "AAN",
    url: "http://www.nutritioneducation.com/",
  },
  {
    label: "American Business & Technology University",
    value: "ABTU",
    url: "http://www.abtu.edu/",
  },
  {
    label: "American Conservatory of Music",
    value: "ACM",
    url: "http://members.aol.com/amerconsmu/",
  },
  {
    label: "American Conservatory Theater",
    value: "ACT",
    url: "http://www.act-sfbay.org/",
  },
  {
    label: "American-European School of Management",
    value: "ASM",
    url: "http://www.aesom.com/",
  },
  {
    label:
      "American Film Institute Center for Advanced Film and Television Studies",
    value: "AFICFAFTS",
    url: "http://www.afionline.org/cafts/cafts.home.html",
  },
  {
    label: "American Indian College",
    value: "AIC",
    url: "http://www.aicag.edu/",
  },
  {
    label: "American InterContinental University - Atlanta",
    value: "AIU-A",
    url: "http://www.aiuniv.edu/",
  },
  {
    label: "American InterContinental University - Ft. Lauderdale",
    value: "AIU-FL",
    url: "http://www.aiuniv.edu/",
  },
  {
    label: "American InterContinental University - Georgia",
    value: "AIU-G",
    url: "http://www.aiuniv.edu/",
  },
  {
    label: "American InterContinental University Online",
    value: "AIUO",
    url: "http://www.aiu-online.com/",
  },
  {
    label: "American International College",
    value: "AIC_1",
    url: "http://www.aic.edu/",
  },
  {
    label: "American Jewish University",
    value: "AJU",
    url: "http://www.ajula.edu/",
  },
  {
    label: "American Military University",
    value: "AMU",
    url: "http://www.amunet.edu/",
  },
  {
    label: "American Public University",
    value: "APU_1",
    url: "http://www.apus.edu/",
  },
  {
    label: "American University",
    value: "AU_5",
    url: "http://www.american.edu/",
  },
  {
    label: "American World University",
    value: "AWU",
    url: "http://www.awu.edu/",
  },
  { label: "Amherst College", value: "AC_7", url: "http://www.amherst.edu/" },
  {
    label: "Anderson College",
    value: "AC_8",
    url: "http://www.anderson-college.edu/",
  },
  {
    label: "Anderson University",
    value: "AU_6",
    url: "http://www.anderson.edu/",
  },
  {
    label: "Andon College - Modesto",
    value: "AC-M",
    url: "http://www.andoncollege.com/",
  },
  {
    label: "Andon College - Stockton",
    value: "AC-S",
    url: "http://www.andoncollege.org/",
  },
  {
    label: "Andrew Jackson University",
    value: "AJU_1",
    url: "http://www.aju.edu/",
  },
  {
    label: "Andrews University",
    value: "AU_7",
    url: "http://www.andrews.edu/",
  },
  {
    label: "Angelo State University",
    value: "ASU_3",
    url: "http://www.angelo.edu/",
  },
  {
    label: "Anna Maria College",
    value: "AMC_2",
    url: "http://www.anna-maria.edu/",
  },
  {
    label: "Antioch New England Graduate School",
    value: "ANEGS",
    url: "http://www.antiochne.edu/",
  },
  {
    label: "Antioch University",
    value: "AU_8",
    url: "http://www.antioch.edu/",
  },
  {
    label: "Antioch University Los Angeles",
    value: "AULA",
    url: "http://www.antiochla.edu/",
  },
  {
    label: "Antioch University Santa Barbara",
    value: "AUSB",
    url: "http://www.antiochsb.edu/",
  },
  {
    label: "Antioch University Seattle",
    value: "AUS",
    url: "http://www.antiochsea.edu/",
  },
  {
    label: "Appalachian Bible College",
    value: "ABC_2",
    url: "http://www.appbibco.edu/",
  },
  {
    label: "Appalachian State University",
    value: "ASU_4",
    url: "http://www.appstate.edu/",
  },
  { label: "Aquinas College", value: "AC_9", url: "http://www.aquinas.edu/" },
  {
    label: "Arcadia University",
    value: "AU_9",
    url: "http://www.arcadia.edu/",
  },
  { label: "Argosy University", value: "AU_10", url: "http://www.argosy.edu/" },
  {
    label: "Argosy University - Hawaii",
    value: "AU-H",
    url: "http://www.argosyu.edu/",
  },
  {
    label: "Arizona Christian University",
    value: "ACU_1",
    url: "http://www.arizonachristian.edu",
  },
  {
    label: "Arizona State University",
    value: "ASU_5",
    url: "http://www.asu.edu/",
  },
  {
    label: "Arizona State University, Downtown Phoenix Campus",
    value: "ASUDPC",
    url: "https://campus.asu.edu/downtown",
  },
  {
    label: "Arizona State University, Polytechnic Campus",
    value: "ASUPC",
    url: "https://campus.asu.edu/polytechnic",
  },
  {
    label: "Arizona State University, Tempe Campus",
    value: "ASUTC",
    url: "https://campus.asu.edu/tempe",
  },
  {
    label: "Arizona State University, West Campus",
    value: "ASUWC",
    url: "https://campus.asu.edu/west",
  },
  {
    label: "Arkansas State University",
    value: "ASU_6",
    url: "http://www.astate.edu/",
  },
  {
    label: "Arkansas State University, Beebe",
    value: "ASUB",
    url: "http://www.asub.edu/",
  },
  {
    label: "Arkansas State University, Mountain Home",
    value: "ASUMH",
    url: "http://www.asumh.edu/",
  },
  {
    label: "Arkansas State University, Newport",
    value: "ASUN",
    url: "http://www.asun.edu/",
  },
  {
    label: "Arkansas Tech University",
    value: "ATU",
    url: "http://www.atu.edu/",
  },
  {
    label: "Arlington Baptist College",
    value: "ABC_3",
    url: "http://www.abconline.edu/",
  },
  {
    label: "Armstrong Atlantic State University",
    value: "AASU",
    url: "http://www.armstrong.edu/",
  },
  {
    label: "Armstrong University",
    value: "AU_11",
    url: "http://www.armstrong-u.edu/",
  },
  {
    label: "Art Academy of Cincinnati",
    value: "AAC_1",
    url: "http://www.artacademy.edu/",
  },
  {
    label: "Art Center College of Design",
    value: "ACCD",
    url: "http://www.artcenter.edu/",
  },
  {
    label: "Arthur D. Little Management Education Institute",
    value: "ADLMEI",
    url: "http://www.adlsom.edu/",
  },
  {
    label: "Art Institute of Charlotte",
    value: "AIC_2",
    url: "http://www.aich.artinstitutes.edu/",
  },
  {
    label: "Art Institute of Southern California",
    value: "AISC",
    url: "http://www.aisc.edu/",
  },
  { label: "Asbury College", value: "AC_10", url: "http://www.asbury.edu/" },
  {
    label: "Ashland University",
    value: "AU_12",
    url: "http://www.ashland.edu/",
  },
  {
    label: "Assumption College",
    value: "AC_11",
    url: "http://www.assumption.edu/",
  },
  { label: "Athenaeum of Ohio", value: "AO", url: "http://www.mtsm.org/" },
  {
    label: "Athens State College",
    value: "ASC_2",
    url: "http://www.athens.edu/",
  },
  {
    label: "Atlanta Christian College",
    value: "ACC",
    url: "http://www.acc.edu/",
  },
  { label: "Atlanta College of Art", value: "ACA", url: "http://www.aca.edu/" },
  {
    label: "Atlantic International University",
    value: "AIU",
    url: "http://www.aiu.edu/",
  },
  {
    label: "Atlantic Union College",
    value: "AUC",
    url: "http://www.atlanticuc.edu/",
  },
  {
    label: "Atlantic University",
    value: "AU_13",
    url: "http://www.atlanticuniv.edu/",
  },
  { label: "Auburn University", value: "AU_14", url: "http://www.auburn.edu/" },
  {
    label: "Auburn University at Montgomery",
    value: "AUAM",
    url: "http://www.aum.edu/",
  },
  {
    label: "Audrey Cohen College",
    value: "ACC_1",
    url: "http://www.audrey-cohen.edu/",
  },
  {
    label: "Augsburg College",
    value: "AC_12",
    url: "http://www.augsburg.edu/",
  },
  {
    label: "Augustana College",
    value: "AC_13",
    url: "http://www.augustana.edu/",
  },
  { label: "Augustana College", value: "AC_14", url: "http://www.augie.edu/" },
  {
    label: "Augusta State University",
    value: "ASU_7",
    url: "http://www.aug.edu/",
  },
  { label: "Aurora University", value: "AU_15", url: "http://www.aurora.edu/" },
  { label: "Austin College", value: "AC_15", url: "http://www.austinc.edu/" },
  {
    label: "Austin Community College",
    value: "ACC_2",
    url: "http://www.austin.cc.tx.us/",
  },
  {
    label: "Austin Peay State University",
    value: "APSU",
    url: "http://www.apsu.edu/",
  },
  {
    label: "Ave Maria University",
    value: "AMU_1",
    url: "http://www.avemaria.edu/",
  },
  { label: "Averett College", value: "AC_16", url: "http://www.averett.edu/" },
  { label: "Avila College", value: "AC_17", url: "http://www.avila.edu/" },
  {
    label: "Azusa Pacific University",
    value: "APU_2",
    url: "http://www.apu.edu/",
  },
  { label: "Babson College", value: "BC", url: "http://www.babson.edu/" },
  {
    label: "Baker College of Auburn Hills",
    value: "BCAH",
    url: "http://www.baker.edu/visit/auburn.html",
  },
  {
    label: "Baker College of Cadillac",
    value: "BCC",
    url: "http://www.baker.edu/visit/cadillac.html",
  },
  {
    label: "Baker College of Flint",
    value: "BCF",
    url: "http://www.baker.edu/visit/flint.html",
  },
  {
    label: "Baker College of Mount Clemens",
    value: "BCMC",
    url: "http://www.baker.edu/visit/mtclemens.html",
  },
  {
    label: "Baker College of Muskegon",
    value: "BCM",
    url: "http://www.baker.edu/visit/muskegon.html",
  },
  {
    label: "Baker College of Owosso",
    value: "BCO",
    url: "http://www.baker.edu/visit/owosso.html",
  },
  {
    label: "Baker College of Port Huron",
    value: "BCPH",
    url: "http://www.baker.edu/visit/porthuron.html",
  },
  { label: "Baker University", value: "BU", url: "http://www.bakeru.edu/" },
  {
    label: "Baldwin-Wallace College",
    value: "BC_1",
    url: "http://www.baldwinw.edu/",
  },
  { label: "Ball State University", value: "BSU", url: "http://www.bsu.edu/" },
  {
    label: "Baltimore Hebrew University",
    value: "BHU",
    url: "http://www.bhu.edu/",
  },
  {
    label: "Baltimore International College",
    value: "BIC",
    url: "http://www.bic.edu/",
  },
  {
    label: "Bank Street College of Education",
    value: "BSCE",
    url: "http://www.bnkst.edu/",
  },
  {
    label: "Baptist Bible College of Missouri",
    value: "BBCM",
    url: "http://www.bbcnet.edu/",
  },
  {
    label: "Baptist Bible College of Pennsylvania",
    value: "BBCP",
    url: "http://www.bbc.edu/",
  },
  { label: "Barat College", value: "BC_2", url: "http://www.barat.edu/" },
  {
    label: "Barber-Scotia College",
    value: "BC_3",
    url: "http://www.b-sc.edu/",
  },
  {
    label: "Barclay College",
    value: "BC_4",
    url: "http://www.barclaycollege.edu/",
  },
  { label: "Bard College", value: "BC_5", url: "http://www.bard.edu/" },
  {
    label: "Bard Graduate Center for Studies in the Decorative Arts",
    value: "BGCFSITDA",
    url: "http://www.bard.edu/graduate/BGC/intro.html",
  },
  {
    label: "Barnard College - Columbia University",
    value: "BC-CU",
    url: "http://www.barnard.edu/",
  },
  { label: "Barry University", value: "BU_1", url: "http://www.barry.edu/" },
  {
    label: "Bartlesville Wesleyan College",
    value: "BWC",
    url: "http://www.bwc.edu/",
  },
  { label: "Barton College", value: "BC_6", url: "http://www.barton.edu/" },
  { label: "Bastyr University", value: "BU_2", url: "http://www.bastyr.edu/" },
  { label: "Bates College", value: "BC_7", url: "http://www.bates.edu/" },
  { label: "Bauder College", value: "BC_8", url: "http://www.bauder.edu/" },
  {
    label: "Baylor College of Dentistry",
    value: "BCD",
    url: "http://www.tambcd.edu/",
  },
  {
    label: "Baylor College of Medicine",
    value: "BCM_1",
    url: "http://www.bcm.tmc.edu/",
  },
  { label: "Baylor University", value: "BU_3", url: "http://www.baylor.edu/" },
  { label: "Belhaven College", value: "BC_9", url: "http://www.belhaven.edu/" },
  {
    label: "Bellarmine College",
    value: "BC_10",
    url: "http://www.bellarmine.edu/",
  },
  {
    label: "Bellevue University",
    value: "BU_4",
    url: "http://www.bellevue.edu/",
  },
  {
    label: "Bellin College of Nursing",
    value: "BCN",
    url: "http://www.bellin.org/bcn/",
  },
  { label: "Belmont Abbey College", value: "BAC", url: "http://www.bac.edu/" },
  {
    label: "Belmont University",
    value: "BU_5",
    url: "http://www.belmont.edu/",
  },
  { label: "Beloit College", value: "BC_11", url: "http://www.beloit.edu/" },
  {
    label: "Bemidji State Univeristy",
    value: "BSU_1",
    url: "http://www.bemidji.msus.edu/",
  },
  {
    label: "Benedict College",
    value: "BC_12",
    url: "http://www.benedict.edu/",
  },
  {
    label: "Benedictine College",
    value: "BC_13",
    url: "http://www.benedictine.edu/",
  },
  {
    label: "Benedictine University",
    value: "BU_6",
    url: "http://www.ben.edu/",
  },
  {
    label: "Benedictine University, Springfield College in Illinois",
    value: "BUSCII",
    url: "http://www.sci.edu/",
  },
  { label: "Bennett College", value: "BC_14", url: "http://www.bennett.edu/" },
  {
    label: "Bennington College",
    value: "BC_15",
    url: "http://www.bennington.edu/",
  },
  { label: "Bentley College", value: "BC_16", url: "http://www.bentley.edu/" },
  { label: "Berea College", value: "BC_17", url: "http://www.berea.edu/" },
  {
    label: "Berean University of the Assemblies of God",
    value: "BUTAG",
    url: "http://www.berean.edu/",
  },
  {
    label: "Berklee College of Music",
    value: "BCM_2",
    url: "http://www.berklee.edu/",
  },
  { label: "Berne University", value: "BU_7", url: "http://www.berne.edu/" },
  { label: "Berry College", value: "BC_18", url: "http://www.berry.edu/" },
  {
    label: "Bethany College California",
    value: "BCC_1",
    url: "http://www.bethany.edu/",
  },
  {
    label: "Bethany College Kansas",
    value: "BCK",
    url: "http://www.bethanylb.edu/",
  },
  {
    label: "Bethany College West Virginia",
    value: "BCWV",
    url: "http://www.bethany.wvnet.edu/",
  },
  {
    label: "Bethel College McKenzie",
    value: "BCM_3",
    url: "http://www.bethel-college.edu/",
  },
  {
    label: "Bethel College Mishawaka",
    value: "BCM_4",
    url: "http://www.bethel-in.edu/",
  },
  {
    label: "Bethel College Newton",
    value: "BCN_1",
    url: "http://www.bethelks.edu/",
  },
  {
    label: "Beth-El College of Nursing and Health Sciences",
    value: "BCNHS",
    url: "http://www.uccs.edu/~bethel/",
  },
  {
    label: "Bethel College St. Paul",
    value: "BCSP",
    url: "http://www.bethel.edu/",
  },
  {
    label: "Bethune-Cookman College",
    value: "BC_19",
    url: "http://www.bethune.cookman.edu/",
  },
  { label: "Biola University", value: "BU_8", url: "http://www.biola.edu/" },
  {
    label: "Birmingham-Southern College",
    value: "BC_20",
    url: "http://www.bsc.edu/",
  },
  {
    label: "Blackburn College",
    value: "BC_21",
    url: "http://www.blackburn.edu/",
  },
  { label: "Black Hawk College", value: "BHC", url: "http://www.bhc.edu/" },
  {
    label: "Black Hills State University",
    value: "BHSU",
    url: "http://www.bhsu.edu/",
  },
  {
    label: "Blessing-Rieman College of Nursing",
    value: "BCN_2",
    url: "http://www.brcn.edu/",
  },
  {
    label: "Bloomfield College",
    value: "BC_22",
    url: "http://www.bloomfield.edu/",
  },
  {
    label: "Bloomsburg University of Pennsylvania",
    value: "BUP",
    url: "http://www.bloomu.edu/",
  },
  {
    label: "Bluefield College",
    value: "BC_23",
    url: "http://www.bluefield.edu/",
  },
  {
    label: "Bluefield State College",
    value: "BSC",
    url: "http://www.bluefield.wvnet.edu/",
  },
  { label: "Blue Mountain College", value: "BMC", url: "http://www.bmc.edu/" },
  {
    label: "Bluffton College",
    value: "BC_24",
    url: "http://www.bluffton.edu/",
  },
  { label: "Bob Jones University", value: "BJU", url: "http://www.bju.edu/" },
  {
    label: "Boise Bible College",
    value: "BBC",
    url: "http://www.boisebible.edu/",
  },
  {
    label: "Boise State University",
    value: "BSU_2",
    url: "http://www.boisestate.edu/",
  },
  {
    label: "Boricua College",
    value: "BC_25",
    url: "http://www.boricuacollege.edu/",
  },
  {
    label: "Boston Architectural Center",
    value: "BAC_1",
    url: "http://www.the-bac.edu/",
  },
  { label: "Boston College", value: "BC_26", url: "http://www.bc.edu/" },
  { label: "Boston University", value: "BU_9", url: "http://www.bu.edu/" },
  { label: "Bowdoin College", value: "BC_27", url: "http://www.bowdoin.edu/" },
  {
    label: "Bowie State University",
    value: "BSU_3",
    url: "http://www.bowiestate.edu/",
  },
  {
    label: "Bowling Green State University",
    value: "BGSU",
    url: "http://www.bgsu.edu/",
  },
  {
    label: "Bowling Green State University, Firelands",
    value: "BGSUF",
    url: "http://www.firelands.bgsu.edu/",
  },
  {
    label: "Bradley University",
    value: "BU_10",
    url: "http://www.bradley.edu/",
  },
  {
    label: "Brandeis University",
    value: "BU_11",
    url: "http://www.brandeis.edu/",
  },
  {
    label: "Brandman University",
    value: "BU_12",
    url: "http://www.brandman.edu/",
  },
  { label: "Brenau University", value: "BU_13", url: "http://www.brenau.edu/" },
  {
    label: "Brescia University",
    value: "BU_14",
    url: "http://www.brescia.edu/",
  },
  { label: "Brevard College", value: "BC_28", url: "http://www.brevard.edu/" },
  {
    label: "Brewton-Parker College",
    value: "BC_29",
    url: "http://www.bpc.edu/",
  },
  {
    label: "Breyer State University (Virtual University)",
    value: "BSU(U",
    url: "http://www.breyerstate.com/",
  },
  {
    label: "Briar Cliff College",
    value: "BCC_2",
    url: "http://www.briar-cliff.edu/",
  },
  {
    label: "Bridgewater College",
    value: "BC_30",
    url: "http://www.bridgewater.edu/",
  },
  {
    label: "Bridgewater State College",
    value: "BSC_1",
    url: "http://www.bridgew.edu/",
  },
  {
    label: "Brigham Young University",
    value: "BYU",
    url: "http://www.byui.edu/",
  },
  {
    label: "Brigham Young University",
    value: "BYU_1",
    url: "http://www.byu.edu/",
  },
  {
    label: "Brigham Young University Hawaii",
    value: "BYUH",
    url: "http://www.byuh.edu/",
  },
  {
    label: "Brooklyn Law School",
    value: "BLS",
    url: "http://www.brooklaw.edu/",
  },
  {
    label: "Brooks Institute of Photography",
    value: "BIP",
    url: "http://www.brooks.edu/",
  },
  { label: "Brown University", value: "BU_15", url: "http://www.brown.edu/" },
  {
    label: "Brunswick Community College",
    value: "BCC_3",
    url: "http://www.brunswickcc.edu/",
  },
  { label: "Bryan College", value: "BC_31", url: "http://www.bryan.edu/" },
  {
    label: "Bryant and Stratton College",
    value: "BSC_2",
    url: "http://www.bryantstratton.edu/",
  },
  { label: "Bryant University", value: "BU_16", url: "http://www.bryant.edu/" },
  {
    label: "Bryn Athyn College of the New Church",
    value: "BACTNC",
    url: "http://www.newchurch.edu/college/",
  },
  {
    label: "Bryn Mawr College",
    value: "BMC_1",
    url: "http://www.brynmawr.edu/",
  },
  {
    label: "Bucknell University",
    value: "BU_17",
    url: "http://www.bucknell.edu/",
  },
  { label: "Buena Vista University", value: "BVU", url: "http://www.bvu.edu/" },
  {
    label: "Burlington College",
    value: "BC_32",
    url: "http://www.burlcol.edu/",
  },
  { label: "Butler University", value: "BU_18", url: "http://www.butler.edu/" },
  { label: "Cabrini College", value: "CC", url: "http://www.cabrini.edu/" },
  { label: "Caldwell College", value: "CC_1", url: "http://www.caldwell.edu/" },
  {
    label: "California Baptist College",
    value: "CBC",
    url: "http://www.calbaptist.edu/",
  },
  {
    label: "California Coast University",
    value: "CCU",
    url: "http://www.calcoast.edu/",
  },
  {
    label: "California College for Health Sciences",
    value: "CCFHS",
    url: "http://www.cchs.edu/",
  },
  {
    label: "California College of Arts and Crafts",
    value: "CCAC",
    url: "http://www.ccac-art.edu/",
  },
  {
    label: "California College of Podiatric Medicine",
    value: "CCPM",
    url: "http://www.ccpm.edu/",
  },
  {
    label: "California College San Diego",
    value: "CCSD",
    url: "http://www.cc-sd.edu/",
  },
  {
    label: "California Institute of Integral Studies",
    value: "CIIS",
    url: "http://www.ciis.edu/",
  },
  {
    label: "California Institute of Technology",
    value: "CIT",
    url: "http://www.caltech.edu/",
  },
  {
    label: "California Institute of the Arts",
    value: "CITA",
    url: "http://www.calarts.edu/",
  },
  {
    label: "California Lutheran University",
    value: "CLU",
    url: "http://www.callutheran.edu/",
  },
  {
    label: "California Maritime Academy",
    value: "CMA",
    url: "http://www.csum.edu/",
  },
  {
    label: "California Polytechnic State University - San Luis Obispo",
    value: "CPSU-SLO",
    url: "http://www.calpoly.edu/",
  },
  {
    label: "California School of Professional Psychology - Berkley/Alameda",
    value: "CSPP-B",
    url: "http://www.cspp.edu/",
  },
  {
    label: "California School of Professional Psychology - Fresno",
    value: "CSPP-F",
    url: "http://www.cspp.edu/catalog/8.htm",
  },
  {
    label: "California School of Professional Psychology - Los Angels",
    value: "CSPP-LA",
    url: "http://www.cspp.edu/catalog/9.htm",
  },
  {
    label: "California School of Professional Psychology - San Diego",
    value: "CSPP-SD",
    url: "http://www.cspp.edu/catalog/10.htm",
  },
  {
    label: "California State Polytechnic University - Pomona",
    value: "CSPU-P",
    url: "http://www.csupomona.edu/",
  },
  {
    label: "California State University, Bakersfield",
    value: "CSUB",
    url: "http://www.csubak.edu/",
  },
  {
    label: "California State University, Channel Islands",
    value: "CSUCI",
    url: "http://www.csuci.edu/",
  },
  {
    label: "California State University, Chico",
    value: "CSUC",
    url: "http://www.csuchico.edu/",
  },
  {
    label: "California State University, Dominguez Hills",
    value: "CSUDH",
    url: "http://www.csudh.edu/",
  },
  {
    label: "California State University, Fresno",
    value: "CSUF",
    url: "http://www.csufresno.edu/",
  },
  {
    label: "California State University, Fullerton",
    value: "CSUF_1",
    url: "http://www.fullerton.edu/",
  },
  {
    label: "California State University, Hayward",
    value: "CSUH",
    url: "http://www.csuhayward.edu/",
  },
  {
    label: "California State University, Long Beach",
    value: "CSULB",
    url: "http://www.csulb.edu/",
  },
  {
    label: "California State University, Los Angeles",
    value: "CSULA",
    url: "http://www.calstatela.edu/",
  },
  {
    label: "California State University, Monterey Bay",
    value: "CSUMB",
    url: "http://www.monterey.edu/",
  },
  {
    label: "California State University, Northridge",
    value: "CSUN",
    url: "http://www.csun.edu/",
  },
  {
    label: "California State University, Sacramento",
    value: "CSUS",
    url: "http://www.csus.edu/",
  },
  {
    label: "California State University, San Bernadino",
    value: "CSUSB",
    url: "http://www.csusb.edu/",
  },
  {
    label: "California State University, San Marcos",
    value: "CSUSM",
    url: "http://www.csusm.edu/",
  },
  {
    label: "California State University, Stanislaus",
    value: "CSUS_1",
    url: "http://www.csustan.edu/",
  },
  {
    label: "California University of Management and Sciences",
    value: "CUMS",
    url: "http://cauniversity.edu.cufce.org/",
  },
  {
    label: "California University of Pennsylvania",
    value: "CUP",
    url: "http://www.cup.edu/",
  },
  {
    label: "California Western School of Law",
    value: "CWSL",
    url: "http://www.cwsl.edu/",
  },
  {
    label: "Calumet College of St. Joseph",
    value: "CCSJ",
    url: "http://www.ccsj.edu/",
  },
  {
    label: "Calvary Bible College",
    value: "CBC_1",
    url: "http://www.calvary.edu/",
  },
  { label: "Calvin College", value: "CC_2", url: "http://www.calvin.edu/" },
  {
    label: "Cambridge College",
    value: "CC_3",
    url: "http://www.cambridge.edu/",
  },
  { label: "Cameron University", value: "CU", url: "http://www.cameron.edu/" },
  {
    label: "Campbellsville College",
    value: "CC_4",
    url: "http://www.campbellsvil.edu/",
  },
  {
    label: "Campbell University",
    value: "CU_1",
    url: "http://www.campbell.edu/",
  },
  { label: "Canisius College", value: "CC_5", url: "http://www.canisius.edu/" },
  {
    label: "Capella University",
    value: "CU_2",
    url: "http://www.capella.edu/",
  },
  {
    label: "Capital University",
    value: "CU_3",
    url: "http://www.capital.edu/",
  },
  {
    label: "Capital University Law School",
    value: "CULS",
    url: "http://www.law.capital.edu/",
  },
  {
    label: "Capitol College",
    value: "CC_6",
    url: "http://www.capitol-college.edu/",
  },
  {
    label: "Cardinal Stritch University",
    value: "CSU",
    url: "http://www.stritch.edu/",
  },
  { label: "Carleton College", value: "CC_7", url: "http://www.carleton.edu/" },
  {
    label: "Carlos Albizu University",
    value: "CAU",
    url: "http://www.albizu.edu/",
  },
  { label: "Carlow College", value: "CC_8", url: "http://www.carlow.edu/" },
  {
    label: "Carnegie Mellon University",
    value: "CMU",
    url: "http://www.cmu.edu/",
  },
  {
    label: "Carroll College Helena",
    value: "CCH",
    url: "http://www.carroll.edu/",
  },
  {
    label: "Carroll College Waukesha",
    value: "CCW",
    url: "http://www.cc.edu/",
  },
  { label: "Carson-Newman College", value: "CC_9", url: "http://www.cn.edu/" },
  {
    label: "Carthage College",
    value: "CC_10",
    url: "http://www.carthage.edu/",
  },
  {
    label: "Case Western Reserve University",
    value: "CWRU",
    url: "http://www.cwru.edu/",
  },
  {
    label: "Castleton State College",
    value: "CSC",
    url: "http://www.csc.vsc.edu/",
  },
  { label: "Catawba College", value: "CC_11", url: "http://www.catawba.edu/" },
  {
    label: "Catholic Theological Union",
    value: "CTU",
    url: "http://www.ctu.edu/",
  },
  {
    label: "Cedar Crest College",
    value: "CCC",
    url: "http://www.cedarcrest.edu/",
  },
  {
    label: "Cedarville College",
    value: "CC_12",
    url: "http://www.cedarville.edu/",
  },
  {
    label: "Centenary College",
    value: "CC_13",
    url: "http://www.centenarycollege.edu/",
  },
  {
    label: "Centenary College of Louisiana",
    value: "CCL",
    url: "http://www.centenary.edu/",
  },
  {
    label: "Center for Humanistic Studies",
    value: "CFHS",
    url: "http://www.humanpsych.edu/",
  },
  {
    label: "Central Baptist College",
    value: "CBC_2",
    url: "http://www.cbc.edu/",
  },
  {
    label: "Central Bible College",
    value: "CBC_3",
    url: "http://www.cbcag.edu/",
  },
  {
    label: "Central Christian College of the Bible",
    value: "CCCTB",
    url: "http://www.cccb.edu/",
  },
  { label: "Central College", value: "CC_14", url: "http://www.central.edu/" },
  {
    label: "Central Connecticut State University",
    value: "CCSU",
    url: "http://www.ccsu.edu/",
  },
  {
    label: "Central Methodist College",
    value: "CMC",
    url: "http://www.cmc.edu/",
  },
  {
    label: "Central Michigan University",
    value: "CMU_1",
    url: "http://www.cmich.edu/",
  },
  {
    label: "Central State University",
    value: "CSU_1",
    url: "http://www.centralstate.edu/",
  },
  {
    label: "Central Washington University",
    value: "CWU",
    url: "http://www.cwu.edu/",
  },
  { label: "Centre College", value: "CC_15", url: "http://www.centre.edu/" },
  {
    label: "Chadron State College",
    value: "CSC_1",
    url: "http://www.csc.edu/",
  },
  {
    label: "Chaminade University of Honolulu",
    value: "CUH",
    url: "http://www.chaminade.edu/",
  },
  {
    label: "Champlain College",
    value: "CC_16",
    url: "http://www.champlain.edu/",
  },
  {
    label: "Chapman University",
    value: "CU_4",
    url: "http://www.chapman.edu/",
  },
  {
    label: "Charles R. Drew University of Medicine and Science",
    value: "CRDUMS",
    url: "http://www.cdrewu.edu/",
  },
  {
    label: "Charleston Southern University",
    value: "CSU_2",
    url: "http://www.csuniv.edu/",
  },
  {
    label: "Charter Oak State College",
    value: "COSC",
    url: "http://www.cosc.edu/",
  },
  { label: "Chatham College", value: "CC_17", url: "http://www.chatham.edu/" },
  { label: "Chestnut Hill College", value: "CHC", url: "http://www.chc.edu/" },
  {
    label: "Cheyney University of Pennsylvania",
    value: "CUP_1",
    url: "http://www.cheyney.edu/",
  },
  {
    label: "Chicago State University",
    value: "CSU_3",
    url: "http://www.csu.edu/",
  },
  { label: "Chowan College", value: "CC_18", url: "http://www.chowan.edu/" },
  {
    label: "Christendom College",
    value: "CC_19",
    url: "http://www.christendom.edu/",
  },
  {
    label: "Christian Brothers University",
    value: "CBU",
    url: "http://www.cbu.edu/",
  },
  {
    label: "Christian Heritage College",
    value: "CHC_1",
    url: "http://www.christianheritage.edu/",
  },
  {
    label: "Christopher Newport University",
    value: "CNU",
    url: "http://www.cnu.edu/",
  },
  {
    label: "Circleville Bible College",
    value: "CBC_4",
    url: "http://www.biblecollege.edu/",
  },
  { label: "City University", value: "CU_5", url: "http://www.cityu.edu/" },
  {
    label: "City University of New York, Bernard M. Baruch College",
    value: "CUNYBMBC",
    url: "http://www.baruch.cuny.edu/",
  },
  {
    label: "City University of New York, Brooklyn College",
    value: "CUNYBC",
    url: "http://www.brooklyn.cuny.edu/",
  },
  {
    label: "City University of New York, City College",
    value: "CUNYCC",
    url: "http://www.ccny.cuny.edu/",
  },
  {
    label: "City University of New York, College of Staten Island",
    value: "CUNYCSI",
    url: "http://www.csi.cuny.edu/",
  },
  {
    label: "City University of New York (CUNY) System",
    value: "CUNY(S",
    url: "http://www.cuny.edu/",
  },
  {
    label: "City University of New York, Graduate School and University Center",
    value: "CUNYGSUC",
    url: "http://www.gc.cuny.edu/",
  },
  {
    label: "City University of New York, Hunter College",
    value: "CUNYHC",
    url: "http://www.hunter.cuny.edu/",
  },
  {
    label: "City University of New York, John Jay College of Criminal Justice",
    value: "CUNYJJCCJ",
    url: "http://www.jjay.cuny.edu/",
  },
  {
    label: "City University of New York, Lehman College",
    value: "CUNYLC",
    url: "http://www.lehman.cuny.edu/",
  },
  {
    label: "City University of New York, Medgar Evers College",
    value: "CUNYMEC",
    url: "http://www.mec.cuny.edu/",
  },
  {
    label:
      "City University of New York Medical School / Sophie Davis School of Biomedical Education",
    value: "CUNYMS/SDSBE",
    url: "http://med.cuny.edu/",
  },
  {
    label: "City University of New York, New York City Technical College",
    value: "CUNYNYCTC",
    url: "http://www.nyctc.cuny.edu/",
  },
  {
    label: "City University of New York, Queens College",
    value: "CUNYQC",
    url: "http://www.qc.edu/",
  },
  {
    label: "City University of New York, School of Law at Queens College",
    value: "CUNYSLAQC",
    url: "http://www.law.cuny.edu/",
  },
  {
    label: "City University of New York, York College",
    value: "CUNYYC",
    url: "http://www.york.cuny.edu/",
  },
  {
    label: "Claflin College",
    value: "CC_20",
    url: "http://www.scicu.org/claflin/",
  },
  {
    label: "Claremont Graduate University",
    value: "CGU",
    url: "http://www.cgu.edu/",
  },
  {
    label: "Claremont Lincoln University",
    value: "CLU_1",
    url: "http://www.claremontlincoln.org/",
  },
  {
    label: "Claremont McKenna College",
    value: "CMC_1",
    url: "http://www.mckenna.edu/",
  },
  {
    label: "Clarion University",
    value: "CU_6",
    url: "http://www.clarion.edu/",
  },
  {
    label: "Clark Atlanta University",
    value: "CAU_1",
    url: "http://www.cau.edu/",
  },
  { label: "Clarke College", value: "CC_21", url: "http://www.clarke.edu/" },
  {
    label: "Clarkson College",
    value: "CC_22",
    url: "http://www.clarksoncollege.edu/",
  },
  {
    label: "Clarkson University",
    value: "CU_7",
    url: "http://www.clarkson.edu/",
  },
  { label: "Clark University", value: "CU_8", url: "http://www.clarku.edu/" },
  {
    label: "Clayton College & State University",
    value: "CCSU_1",
    url: "http://www.clayton.edu/",
  },
  {
    label: "Clearwater Christian College",
    value: "CCC_1",
    url: "http://www.clearwater.edu/",
  },
  { label: "Cleary College", value: "CC_23", url: "http://www.cleary.edu/" },
  {
    label: "Clemson University",
    value: "CU_9",
    url: "http://www.clemson.edu/",
  },
  {
    label: "Cleveland Chiropractic College, Kansas City",
    value: "CCCKC",
    url: "http://www.clevelandchiropractic.edu/",
  },
  {
    label: "Cleveland Chiropractic College, Los Angeles",
    value: "CCCLA",
    url: "http://www.clevelandchiropractic.edu/",
  },
  {
    label: "Cleveland Institute of Art",
    value: "CIA",
    url: "http://www.cia.edu/",
  },
  {
    label: "Cleveland Institute of Music",
    value: "CIM",
    url: "http://www.cim.edu/",
  },
  {
    label: "Cleveland State University",
    value: "CSU_4",
    url: "http://www.csuohio.edu/",
  },
  {
    label: "Coastal Carolina University",
    value: "CCU_1",
    url: "http://www.coastal.edu/",
  },
  { label: "Coe College", value: "CC_24", url: "http://www.coe.edu/" },
  {
    label: "Cogswell Polytechnical College",
    value: "CPC",
    url: "http://www.cogswell.edu/",
  },
  { label: "Coker College", value: "CC_25", url: "http://www.coker.edu/" },
  { label: "Colby College", value: "CC_26", url: "http://www.colby.edu/" },
  {
    label: "Colby-Sawyer College",
    value: "CC_27",
    url: "http://www.colby-sawyer.edu/",
  },
  { label: "Coleman College", value: "CC_28", url: "http://www.coleman.edu/" },
  {
    label: "Colgate University",
    value: "CU_10",
    url: "http://www.colgate.edu/",
  },
  {
    label: "CollegeAmerica, Denver",
    value: "CD",
    url: "http://www.collegeamerica.edu/",
  },
  {
    label: "CollegeAmerica, Phoenix",
    value: "CP",
    url: "http://www.collegeamerica.edu/",
  },
  {
    label: "College for Creative Studies",
    value: "CFCS",
    url: "http://www.ccscad.edu/",
  },
  {
    label: "College for Financial Planning",
    value: "CFFP",
    url: "http://www.fp.edu/",
  },
  {
    label: "College for Lifelong Learning",
    value: "CFLL",
    url: "http://www.cll.edu/",
  },
  { label: "College Misericordia", value: "CM", url: "http://www.miseri.edu/" },
  { label: "College of Aeronautics", value: "CA", url: "http://www.aero.edu/" },
  {
    label: "College of Charleston",
    value: "CC_29",
    url: "http://www.cofc.edu/",
  },
  { label: "College of DuPage", value: "CD_1", url: "http://www.cod.edu/" },
  {
    label: "College of Idaho",
    value: "CI",
    url: "http://www.collegeofidaho.edu/",
  },
  {
    label: "College of Mount St. Joseph",
    value: "CMSJ",
    url: "http://www.msj.edu/",
  },
  {
    label: "College of Mount St. Vincent",
    value: "CMSV",
    url: "http://www.cmsv.edu/",
  },
  {
    label: "College of New Rochelle",
    value: "CNR",
    url: "http://www.cnr.edu/",
  },
  { label: "College of Notre Dame", value: "CND", url: "http://www.cnd.edu/" },
  {
    label: "College of Notre Dame of Maryland",
    value: "CNDM",
    url: "http://www.ndm.edu/",
  },
  {
    label:
      "College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)",
    value: "COMTP(UHS",
    url: "http://www.westernu.edu/comp.html",
  },
  {
    label: "College of St. Benedict and St. John's University",
    value: "CSBSJU",
    url: "http://www.csbsju.edu/",
  },
  {
    label: "College of St. Catherine",
    value: "CSC_2",
    url: "http://www.stkate.edu/",
  },
  {
    label: "College of St. Elizabeth",
    value: "CSE",
    url: "http://www.st-elizabeth.edu/",
  },
  { label: "College of St. Joseph", value: "CSJ", url: "http://www.csj.edu/" },
  { label: "College of St. Mary", value: "CSM", url: "http://www.csm.edu/" },
  { label: "College of St. Rose", value: "CSR", url: "http://www.strose.edu/" },
  {
    label: "College of St. Scholastica",
    value: "CSS",
    url: "http://www.css.edu/",
  },
  {
    label: "College of the Atlantic",
    value: "CTA",
    url: "http://www.coa.edu/",
  },
  {
    label: "College of the Holy Cross",
    value: "CTHC",
    url: "http://www.holycross.edu/",
  },
  { label: "College of the Ozarks", value: "CTO", url: "http://www.cofo.edu/" },
  { label: "College of Visual Arts", value: "CVA", url: "http://www.cva.edu/" },
  {
    label: "College of William and Mary",
    value: "CWM",
    url: "http://www.wm.edu/",
  },
  {
    label: "Colorado Christian University",
    value: "CCU_2",
    url: "http://www.ccu.edu/",
  },
  {
    label: "Colorado College",
    value: "CC_30",
    url: "http://www.cc.colorado.edu/",
  },
  {
    label: "Colorado School of Mines",
    value: "CSM_1",
    url: "http://www.mines.edu/",
  },
  {
    label: "Colorado State University",
    value: "CSU_5",
    url: "http://www.colostate.edu/",
  },
  {
    label: "Colorado State University-Pueblo",
    value: "CSU_6",
    url: "http://www.colostate-pueblo.edu/",
  },
  {
    label: "Colorado Technical University",
    value: "CTU_1",
    url: "http://www.colotechu.edu/",
  },
  {
    label: "Columbia College Chicago",
    value: "CCC_2",
    url: "http://www.colum.edu/",
  },
  {
    label: "Columbia College Hollywood",
    value: "CCH_1",
    url: "http://www.columbiacollege.edu/",
  },
  {
    label: "Columbia College of Missouri",
    value: "CCM",
    url: "http://www.ccis.edu/",
  },
  {
    label: "Columbia College South Carolina",
    value: "CCSC",
    url: "http://www.colacoll.edu/",
  },
  {
    label: "Columbia Commonwealth University",
    value: "CCU_3",
    url: "http://www.ccwu.edu/",
  },
  {
    label: "Columbia International University",
    value: "CIU",
    url: "http://www.ciu.edu/",
  },
  {
    label: "Columbia Southern University",
    value: "CSU_7",
    url: "http://www.columbiasouthern.edu/",
  },
  { label: "Columbia Union College", value: "CUC", url: "http://www.cuc.edu/" },
  {
    label: "Columbia University",
    value: "CU_11",
    url: "http://www.columbia.edu/",
  },
  {
    label: "Columbus College of Art and Design",
    value: "CCAD",
    url: "http://www.ccad.edu/",
  },
  {
    label: "Columbus State University",
    value: "CSU_8",
    url: "http://www.colstate.edu/",
  },
  {
    label: "Columbus University",
    value: "CU_12",
    url: "http://www.columbusu.com/",
  },
  {
    label: "Community College of Denver",
    value: "CCD",
    url: "http://ccd.rightchoice.org/",
  },
  { label: "Concord College", value: "CC_31", url: "http://www.concord.edu/" },
  {
    label: "Concordia College, Ann Arbor",
    value: "CCAA",
    url: "http://www.ccaa.edu/",
  },
  {
    label: "Concordia College, Bronxville",
    value: "CCB",
    url: "http://www.concordia-ny.edu/",
  },
  {
    label: "Concordia College, Moorhead",
    value: "CCM_1",
    url: "http://www.cord.edu/",
  },
  {
    label: "Concordia College, Selma",
    value: "CCS",
    url: "http://higher-ed.lcms.org/selma.htm",
  },
  {
    label: "Concordia College, Seward",
    value: "CCS_1",
    url: "http://www.cune.edu/",
  },
  {
    label: "Concordia College, St. Paul",
    value: "CCSP",
    url: "http://www.csp.edu/",
  },
  {
    label: "Concordia University, Austin",
    value: "CUA",
    url: "http://www.concordia.edu/",
  },
  {
    label: "Concordia University, Irvine",
    value: "CUI",
    url: "http://www.cui.edu/",
  },
  {
    label: "Concordia University, Mequon",
    value: "CUM",
    url: "http://www.cuw.edu/",
  },
  {
    label: "Concordia University, Portland",
    value: "CUP_2",
    url: "http://www.cu-portland.edu/",
  },
  {
    label: "Concordia University, River Forest",
    value: "CURF",
    url: "http://www.curf.edu/",
  },
  {
    label: "Connecticut College",
    value: "CC_32",
    url: "http://www.conncoll.edu/",
  },
  {
    label: "Converse College",
    value: "CC_33",
    url: "http://www.converse.edu/",
  },
  {
    label: "Conway School of Landscape Design",
    value: "CSLD",
    url: "http://www.csld.edu/",
  },
  {
    label: "Coppin State College",
    value: "CSC_3",
    url: "http://www.coppin.edu/",
  },
  {
    label: "Cornell College",
    value: "CC_34",
    url: "http://www.cornell-iowa.edu/",
  },
  {
    label: "Cornell University",
    value: "CU_13",
    url: "http://www.cornell.edu/",
  },
  {
    label: "Cornish College of the Arts",
    value: "CCTA",
    url: "http://www.cornish.edu/",
  },
  {
    label: "Cosmopolitan University",
    value: "CU_14",
    url: "http://www.cosmoedu.net/",
  },
  {
    label: "Covenant College",
    value: "CC_35",
    url: "http://www.covenant.edu/",
  },
  {
    label: "Cranbrook Academy of Art",
    value: "CAA",
    url: "http://www.cranbrookart.edu/",
  },
  {
    label: "Creighton University",
    value: "CU_15",
    url: "http://www.creighton.edu/",
  },
  {
    label: "Crichton College",
    value: "CC_36",
    url: "http://www.crichton.edu/",
  },
  { label: "Crown College", value: "CC_37", url: "http://www.crown.edu/" },
  {
    label: "Culver-Stockton College",
    value: "CC_38",
    url: "http://www.culver.edu/",
  },
  {
    label: "Cumberland College",
    value: "CC_39",
    url: "http://www.cumber.edu/",
  },
  {
    label: "Cumberland University",
    value: "CU_16",
    url: "http://www.cumberland.edu/",
  },
  { label: "Curry College", value: "CC_40", url: "http://www.curry.edu:8080/" },
  { label: "Daemen College", value: "DC", url: "http://www.daemen.edu/" },
  {
    label: "Dakota State University",
    value: "DSU",
    url: "http://www.dsu.edu/",
  },
  {
    label: "Dakota Wesleyan University",
    value: "DWU",
    url: "http://www.dwu.edu/",
  },
  {
    label: "Dallas Baptist University",
    value: "DBU",
    url: "http://www.dbu.edu/",
  },
  {
    label: "Dallas Christian College",
    value: "DCC",
    url: "http://www.dallas.edu/",
  },
  { label: "Dana College", value: "DC_1", url: "http://www.dana.edu/" },
  { label: "Daniel Webster College", value: "DWC", url: "http://www.dwc.edu/" },
  {
    label: "Danville Area Community College",
    value: "DACC",
    url: "http://www.dacc.cc.il.us/",
  },
  {
    label: "Dartmouth College",
    value: "DC_2",
    url: "http://www.dartmouth.edu/",
  },
  { label: "Darton College", value: "DC_3", url: "http://www.darton.edu/" },
  {
    label: "Davenport College of Business, Grand Rapids",
    value: "DCBGR",
    url: "http://www.davenport.edu/grandrapids/",
  },
  {
    label: "Davenport College of Business, Kalamazoo",
    value: "DCBK",
    url: "http://www.davenport.edu/kalamazoo/",
  },
  {
    label: "Davenport College of Business, Lansing",
    value: "DCBL",
    url: "http://www.davenport.edu/lansing/",
  },
  { label: "Davidson College", value: "DC_4", url: "http://www.davidson.edu/" },
  {
    label: "Davis and Elkins College",
    value: "DEC",
    url: "http://www.dne.edu/",
  },
  {
    label: "Deaconess College of Nursing",
    value: "DCN",
    url: "http://www.deaconess.edu/",
  },
  {
    label: "Delaware State University",
    value: "DSU_1",
    url: "http://www.dsc.edu/",
  },
  {
    label: "Delaware Valley College",
    value: "DVC",
    url: "http://www.devalcol.edu/",
  },
  {
    label: "Delta International University",
    value: "DIU",
    url: "http://www.delta-university.org/",
  },
  {
    label: "Delta State University",
    value: "DSU_2",
    url: "http://www.deltast.edu/",
  },
  { label: "Denison University", value: "DU", url: "http://www.denison.edu/" },
  {
    label: "Denver Paralegal Institute",
    value: "DPI",
    url: "http://www.paralegal-education.com/campuses/cosprings/",
  },
  {
    label: "Denver Technical College",
    value: "DTC",
    url: "http://www.dtc.edu/",
  },
  { label: "DePaul University", value: "DU_1", url: "http://www.depaul.edu/" },
  { label: "DePauw University", value: "DU_2", url: "http://www.depauw.edu/" },
  {
    label: "DeSales University",
    value: "DU_3",
    url: "http://www.desales.edu/",
  },
  {
    label: "Design Institute of San Diego",
    value: "DISD",
    url: "http://www.disd.edu/",
  },
  {
    label: "Detroit College of Business",
    value: "DCB",
    url: "http://www.dcb.edu/",
  },
  {
    label: "Detroit College of Business - Flint",
    value: "DCB-F",
    url: "http://www.davenport.edu/e3front.dll?durki=1283",
  },
  {
    label: "Detroit College of Business - Warren",
    value: "DCB-W",
    url: "http://www.davenport.edu/e3front.dll?durki=108",
  },
  { label: "Detroit College of Law", value: "DCL", url: "http://www.dcl.edu/" },
  {
    label: "DeVry Institute of Technology, Chicago",
    value: "DITC",
    url: "http://www.chi.devry.edu/",
  },
  {
    label: "DeVry Institute of Technology, Columbus",
    value: "DITC_1",
    url: "http://www.devrycols.edu/",
  },
  {
    label: "DeVry Institute of Technology, Decatur",
    value: "DITD",
    url: "http://www.atl.devry.edu/",
  },
  {
    label: "DeVry Institute of Technology, DuPage",
    value: "DITD_1",
    url: "http://www.dpg.devry.edu/",
  },
  {
    label: "DeVry Institute of Technology, Irving",
    value: "DITI",
    url: "http://www.dal.devry.edu/",
  },
  {
    label: "DeVry Institute of Technology, Kansas City",
    value: "DITKC",
    url: "http://www.kc.devry.edu/",
  },
  {
    label: "DeVry Institute of Technology, Phoenix",
    value: "DITP",
    url: "http://www.phx.devry.edu/",
  },
  {
    label: "DeVry Institute of Technology, Pomona",
    value: "DITP_1",
    url: "http://www.pom.devry.edu/",
  },
  {
    label: "Dickinson College",
    value: "DC_5",
    url: "http://www.dickinson.edu/",
  },
  {
    label: "Dickinson State University",
    value: "DSU_3",
    url: "http://www.dsu.nodak.edu/",
  },
  {
    label: "Dillard University",
    value: "DU_4",
    url: "http://www.dillard.edu/",
  },
  { label: "Divine Word College", value: "DWC_1", url: "http://www.svd.org" },
  { label: "Dixie College", value: "DC_6", url: "http://www.dixie.edu/" },
  { label: "Doane College", value: "DC_7", url: "http://www.doane.edu/" },
  { label: "Dominican College", value: "DC_8", url: "http://www.dc.edu/" },
  {
    label: "Dominican College of San Rafael",
    value: "DCSR",
    url: "http://www.dominican.edu/",
  },
  {
    label: "Dominican School of Philosophy and Theology",
    value: "DSPT",
    url: "http://www.dspt.edu/",
  },
  { label: "Dominican University", value: "DU_5", url: "http://www.dom.edu/" },
  { label: "Dominion College", value: "DC_9", url: "http://www.dominion.edu/" },
  { label: "Dordt College", value: "DC_10", url: "http://www.dordt.edu/" },
  { label: "Dowling College", value: "DC_11", url: "http://www.dowling.edu/" },
  { label: "Drake University", value: "DU_6", url: "http://www.drake.edu/" },
  { label: "Drew University", value: "DU_7", url: "http://www.drew.edu/" },
  { label: "Drexel University", value: "DU_8", url: "http://www.drexel.edu/" },
  { label: "Drury College", value: "DC_12", url: "http://www.drury.edu/" },
  { label: "Duke University", value: "DU_9", url: "http://www.duke.edu/" },
  {
    label: "Duluth Business University",
    value: "DBU_1",
    url: "http://www.dbumn.edu/",
  },
  { label: "Duquesne University", value: "DU_10", url: "http://www.duq.edu/" },
  { label: "D'Youville College", value: "DC_13", url: "http://www.dyc.edu/" },
  { label: "Earlham College", value: "EC", url: "http://www.earlham.edu/" },
  { label: "EarthNet Institute", value: "EI", url: "http://www.eni.edu/" },
  {
    label: "East Carolina University",
    value: "ECU",
    url: "http://www.ecu.edu/",
  },
  {
    label: "East Central University",
    value: "ECU_1",
    url: "http://www.ecok.edu/",
  },
  { label: "Eastern College", value: "EC_1", url: "http://www.eastern.edu/" },
  {
    label: "Eastern Connecticut State University",
    value: "ECSU",
    url: "http://www.ecsu.ctstateu.edu/",
  },
  {
    label: "Eastern Conservatory of Music",
    value: "ECM",
    url: "http://easternconservatory-music.org/",
  },
  {
    label: "Eastern Illinois University",
    value: "EIU",
    url: "http://www.eiu.edu/",
  },
  {
    label: "Eastern Kentucky University",
    value: "EKU",
    url: "http://www.eku.edu/",
  },
  {
    label: "Eastern Mennonite University",
    value: "EMU",
    url: "http://www.emu.edu/",
  },
  {
    label: "Eastern Michigan University",
    value: "EMU_1",
    url: "http://www.emich.edu/",
  },
  {
    label: "Eastern Nazarene College",
    value: "ENC",
    url: "http://www.enc.edu/",
  },
  {
    label: "Eastern New Mexico University",
    value: "ENMU",
    url: "http://www.enmu.edu/",
  },
  {
    label: "Eastern Oregon University",
    value: "EOU",
    url: "http://www.eou.edu/",
  },
  {
    label: "Eastern Virginia Medical School",
    value: "EVMS",
    url: "http://www.evms.edu/",
  },
  {
    label: "Eastern Washington University",
    value: "EWU",
    url: "http://www.ewu.edu/",
  },
  {
    label: "East Stroudsburg State University",
    value: "ESSU",
    url: "http://www.esu.edu/",
  },
  {
    label: "East Tennessee State University",
    value: "ETSU",
    url: "http://www.etsu.edu/",
  },
  {
    label: "East Texas Baptist University",
    value: "ETBU",
    url: "http://www.etbu.edu/",
  },
  {
    label: "East-West University",
    value: "EU",
    url: "http://www.eastwest.edu/",
  },
  { label: "Eckerd College", value: "EC_2", url: "http://www.eckerd.edu/" },
  { label: "Edgewood College", value: "EC_3", url: "http://www.edgewood.edu/" },
  {
    label: "Edinboro University of Pennsylvania",
    value: "EUP",
    url: "http://www.edinboro.edu/",
  },
  {
    label: "Edison Community College",
    value: "ECC",
    url: "http://www.edison.edu/",
  },
  { label: "Edward Waters College", value: "EWC", url: "http://www.ewc.edu/" },
  {
    label: "Elizabeth City State University",
    value: "ECSU_1",
    url: "http://www.ecsu.edu/",
  },
  {
    label: "Elizabethtown College",
    value: "EC_4",
    url: "http://www.etown.edu/",
  },
  { label: "Elmhurst College", value: "EC_5", url: "http://www.elmhurst.edu/" },
  { label: "Elmira College", value: "EC_6", url: "http://www.elmira.edu/" },
  { label: "Elms College", value: "EC_7", url: "http://www.elms.edu/" },
  { label: "Elon College", value: "EC_8", url: "http://www.elon.edu/" },
  {
    label: "Embry-Riddle Aeronautical University",
    value: "EAU",
    url: "http://www.embryriddle.edu/",
  },
  {
    label: "Embry Riddle Aeronautical University, Prescott",
    value: "ERAUP",
    url: "http://www.prescott.erau.edu/",
  },
  { label: "Emerson College", value: "EC_9", url: "http://www.emerson.edu/" },
  {
    label: "Emmanuel College",
    value: "EC_10",
    url: "http://www.emmanuel.edu/",
  },
  {
    label: "Emmanuel College Georgia",
    value: "ECG",
    url: "http://www.emmanuel-college.edu/",
  },
  {
    label: "Emmaus Bible College",
    value: "EBC",
    url: "http://www.emmaus.edu/",
  },
  { label: "Emory & Henry College", value: "EHC", url: "http://www.ehc.edu/" },
  { label: "Emory University", value: "EU_1", url: "http://www.emory.edu/" },
  {
    label: "Emporia State University",
    value: "ESU",
    url: "http://www.emporia.edu/",
  },
  { label: "Erskine College", value: "EC_11", url: "http://www.erskine.edu/" },
  { label: "Eugene Bible College", value: "EBC_1", url: "http://www.ebc.edu/" },
  { label: "Eureka College", value: "EC_12", url: "http://www.eureka.edu/" },
  {
    label: "Evangel University",
    value: "EU_2",
    url: "http://www.evangel.edu/",
  },
  {
    label: "Evergreen State College",
    value: "ESC",
    url: "http://www.evergreen.edu/",
  },
  {
    label: "Excel College",
    value: "EC_13",
    url: "http://www.excelcollege.org/",
  },
  {
    label: "Excelsior College",
    value: "EC_14",
    url: "http://www.regents.edu/",
  },
  {
    label: "Fairfield University",
    value: "FU",
    url: "http://www.fairfield.edu/",
  },
  {
    label: "Fairleigh Dickinson University, Florham-Madison Campus",
    value: "FDUFC",
    url: "http://www.fdu.edu/visitorcenter/fmwelcome.html",
  },
  {
    label: "Fairleigh Dickinson University, Teaneck-Hackensack Campus",
    value: "FDUTC",
    url: "http://www.fdu.edu/visitorcenter/thwelcome.html",
  },
  {
    label: "Fairmont State College",
    value: "FSC",
    url: "http://www.fscwv.edu/",
  },
  {
    label: "Fashion Institute of New York",
    value: "FINY",
    url: "http://www.fitnyc.suny.edu/",
  },
  {
    label: "Faulkner University",
    value: "FU_1",
    url: "http://www.faulkner.edu/",
  },
  {
    label: "Fayetteville State University",
    value: "FSU",
    url: "http://www.uncfsu.edu/",
  },
  { label: "Felician College", value: "FC", url: "http://www.felician.edu/" },
  {
    label: "Ferris State University",
    value: "FSU_1",
    url: "http://www.ferris.edu/",
  },
  { label: "Ferrum College", value: "FC_1", url: "http://www.ferrum.edu/" },
  { label: "Fielding Institute", value: "FI", url: "http://www.fielding.edu/" },
  {
    label: "Finch University of Health Sciences - The Chicago Medical School",
    value: "FUHS-TCMS",
    url: "http://www.finchcms.edu/",
  },
  {
    label: "Finlandia University",
    value: "FU_2",
    url: "http://www.finlandia.edu/",
  },
  { label: "Fisher College", value: "FC_2", url: "http://www.fisher.edu/" },
  { label: "Fisk University", value: "FU_3", url: "http://www.fisk.edu/" },
  {
    label: "Fitchburg State College",
    value: "FSC_1",
    url: "http://www.fsc.edu/",
  },
  { label: "Flagler College", value: "FC_3", url: "http://www.flagler.edu/" },
  {
    label: "Florida Agricultural and Mechanical University",
    value: "FAMU",
    url: "http://www.famu.edu/",
  },
  {
    label: "Florida Atlantic University",
    value: "FAU",
    url: "http://www.fau.edu/",
  },
  {
    label: "Florida Christian College",
    value: "FCC",
    url: "http://www.fcc.edu/",
  },
  {
    label: "Florida Community College at Jacksonville",
    value: "FCCAJ",
    url: "http://www.fccj.org/",
  },
  {
    label: "Florida Gulf Coast University",
    value: "FGCU",
    url: "http://www.fgcu.edu/",
  },
  {
    label: "Florida Institute of Technology",
    value: "FIT",
    url: "http://www.fit.edu/",
  },
  {
    label: "Florida International University",
    value: "FIU",
    url: "http://www.fiu.edu/",
  },
  {
    label: "Florida Memorial College",
    value: "FMC",
    url: "http://www.fmc.edu/",
  },
  {
    label: "Florida Metropolitan University, Fort Lauderdale College",
    value: "FMUFLC",
    url: "http://www.fmu.edu/784/f-784.htm",
  },
  {
    label: "Florida Metropolitan University, Tampa College",
    value: "FMUTC",
    url: "http://www.fmu.edu/762/f-762.htm",
  },
  {
    label: "Florida Metropolitan University, Tampa College Brandon",
    value: "FMUTCB",
    url: "http://www.fmu.edu/764/f-764.htm",
  },
  {
    label: "Florida Metropolitan University, Tampa College Lakeland",
    value: "FMUTCL",
    url: "http://www.fmu.edu/765/f-765.htm",
  },
  {
    label: "Florida Southern College",
    value: "FSC_2",
    url: "http://www.flsouthern.edu/",
  },
  {
    label: "Florida State University",
    value: "FSU_2",
    url: "http://www.fsu.edu/",
  },
  {
    label: "Florida University of Medicine",
    value: "FUM",
    url: "http://www.fuom.us/",
  },
  {
    label: "Fontbonne College",
    value: "FC_4",
    url: "http://www.fontbonne.edu/",
  },
  {
    label: "Foothill-De Anza Community College District",
    value: "FACCD",
    url: "http://www.fhda.edu/",
  },
  {
    label: "Fordham University",
    value: "FU_4",
    url: "http://www.fordham.edu/",
  },
  {
    label: "Forest Institute of Professional Psychology",
    value: "FIPP",
    url: "http://www.forestinstitute.org/",
  },
  {
    label: "Fort Hays State University",
    value: "FHSU",
    url: "http://www.fhsu.edu/",
  },
  {
    label: "Fort Lewis College",
    value: "FLC",
    url: "http://www.fortlewis.edu/",
  },
  {
    label: "Fort Valley State College",
    value: "FVSC",
    url: "http://www.fvsc.peachnet.edu/",
  },
  {
    label: "Framingham State College",
    value: "FSC_3",
    url: "http://www.framingham.edu/",
  },
  {
    label: "Franciscan School of Theology",
    value: "FST",
    url: "http://www.fst.edu/",
  },
  {
    label: "Franciscan University of Steubenville",
    value: "FUS",
    url: "http://www.franuniv.edu/",
  },
  {
    label: "Francis Marion University",
    value: "FMU",
    url: "http://www.fmarion.edu/",
  },
  {
    label: "Franklin and Marshall College",
    value: "FMC_1",
    url: "http://www.fandm.edu/",
  },
  {
    label: "Franklin College",
    value: "FC_5",
    url: "http://www.franklincoll.edu/",
  },
  {
    label: "Franklin Pierce College",
    value: "FPC",
    url: "http://www.fpc.edu/",
  },
  {
    label: "Franklin Pierce Law Center",
    value: "FPLC",
    url: "http://www.fplc.edu/",
  },
  {
    label: "Franklin University",
    value: "FU_5",
    url: "http://www.franklin.edu/",
  },
  {
    label: "Franklin W. Olin College of Engineering",
    value: "FWOCE",
    url: "http://www.olin.edu/",
  },
  {
    label: "Frank Lloyd Wright School of Architecture",
    value: "FLWSA",
    url: "http://www.taliesin.edu/",
  },
  {
    label: "Fred Hutchinson Cancer Research Center",
    value: "FHCRC",
    url: "http://www.fhcrc.org/",
  },
  {
    label: "Freed-Hardeman University",
    value: "FU_6",
    url: "http://www.fhu.edu/",
  },
  {
    label: "Free University (Virginia Theological University)",
    value: "FU(TU",
    url: "http://www.vtu.edu/",
  },
  {
    label: "Free Will Baptist Bible College",
    value: "FWBBC",
    url: "http://www.fwbbc.edu/",
  },
  {
    label: "Fresno City College",
    value: "FCC_1",
    url: "http://www.fresnocitycollege.edu/",
  },
  {
    label: "Fresno Pacific University",
    value: "FPU",
    url: "http://www.fresno.edu/",
  },
  {
    label: "Friends University",
    value: "FU_7",
    url: "http://www.friends.edu/",
  },
  {
    label: "Frostburg State University",
    value: "FSU_3",
    url: "http://www.fsu.umd.edu/",
  },
  {
    label: "Full Sail University",
    value: "FSU_4",
    url: "http://www.fullsail.com/",
  },
  { label: "Furman University", value: "FU_8", url: "http://www.furman.edu/" },
  {
    label: "Gallaudet University",
    value: "GU",
    url: "http://www.gallaudet.edu/",
  },
  { label: "Gannon University", value: "GU_1", url: "http://www.gannon.edu/" },
  {
    label: "Gardner Webb University",
    value: "GWU",
    url: "http://www.gardner-webb.edu/",
  },
  {
    label: "Gemological Institute of America",
    value: "GIA",
    url: "http://www.gia.edu/",
  },
  { label: "Geneva College", value: "GC", url: "http://www.geneva.edu/" },
  {
    label: "George Fox University",
    value: "GFU",
    url: "http://www.georgefox.edu/",
  },
  {
    label: "George Mason University",
    value: "GMU",
    url: "http://www.gmu.edu/",
  },
  {
    label: "Georgetown College",
    value: "GC_1",
    url: "http://www.georgetowncollege.edu/",
  },
  {
    label: "Georgetown University",
    value: "GU_2",
    url: "http://www.georgetown.edu/",
  },
  {
    label: "George Washington University",
    value: "GWU_1",
    url: "http://www.gwu.edu/",
  },
  { label: "George Wythe College", value: "GWC", url: "http://www.gwc.edu/" },
  {
    label: "Georgia Baptist College of Nursing",
    value: "GBCN",
    url: "http://www.gbcn.edu/",
  },
  {
    label: "Georgia College & State University",
    value: "GCSU",
    url: "http://www.gcsu.edu/",
  },
  {
    label: "Georgia Health Sciences University",
    value: "GHSU",
    url: "http://www.georgiahealth.edu/",
  },
  {
    label: "Georgia Institute of Technology",
    value: "GIT",
    url: "http://www.gatech.edu/",
  },
  {
    label: "Georgian Court College",
    value: "GCC",
    url: "http://www.georgian.edu/",
  },
  {
    label: "Georgia School of Professional Psychology",
    value: "GSPP",
    url: "http://www.aspp.edu/ga.html",
  },
  {
    label: "Georgia Southern University",
    value: "GSU",
    url: "http://www.georgiasouthern.edu/",
  },
  {
    label: "Georgia Southwestern University",
    value: "GSU_1",
    url: "http://www.gsw.edu/",
  },
  {
    label: "Georgia State University",
    value: "GSU_2",
    url: "http://www.gsu.edu/",
  },
  {
    label: "Gettysburg College",
    value: "GC_2",
    url: "http://www.gettysburg.edu/",
  },
  {
    label: "Glendale University College of Law",
    value: "GUCL",
    url: "http://www.glendalelaw.edu/",
  },
  {
    label: "Glenville State College",
    value: "GSC",
    url: "http://www.glenville.wvnet.edu/",
  },
  { label: "Goddard College", value: "GC_3", url: "http://www.goddard.edu/" },
  {
    label: "God's Bible School and College",
    value: "GBSC",
    url: "http://www.gbs.edu/",
  },
  { label: "Golden Gate University", value: "GGU", url: "http://www.ggu.edu/" },
  {
    label: "Goldey-Beacom College",
    value: "GC_4",
    url: "http://goldey.gbc.edu/",
  },
  {
    label: "Gonzaga University",
    value: "GU_3",
    url: "http://www.gonzaga.edu/",
  },
  { label: "Gordon College", value: "GC_5", url: "http://www.gordon.edu/" },
  {
    label: "Gordon Conwell Theological Seminary",
    value: "GCTS",
    url: "http://www.gcts.edu/",
  },
  { label: "Goshen College", value: "GC_6", url: "http://www.goshen.edu/" },
  { label: "Goucher College", value: "GC_7", url: "http://www.goucher.edu/" },
  {
    label: "Governors State University",
    value: "GSU_3",
    url: "http://www.govst.edu/",
  },
  { label: "Grace Bible College", value: "GBC", url: "http://www.gbcol.edu/" },
  { label: "Grace College", value: "GC_8", url: "http://www.grace.edu/" },
  {
    label: "Graceland College",
    value: "GC_9",
    url: "http://www.graceland.edu/",
  },
  { label: "Grace University", value: "GU_4", url: "http://www.graceu.edu/" },
  {
    label: "Graduate Theological Union",
    value: "GTU",
    url: "http://www.gtu.edu/",
  },
  {
    label: "Grambling State University",
    value: "GSU_4",
    url: "http://www.gram.edu/",
  },
  {
    label: "Grand Canyon University",
    value: "GCU",
    url: "http://www.gcu.edu/",
  },
  {
    label: "Grand Valley State University",
    value: "GVSU",
    url: "http://www.gvsu.edu/",
  },
  { label: "Grand View College", value: "GVC", url: "http://www.gvc.edu/" },
  {
    label: "Grantham University",
    value: "GU_5",
    url: "http://www.grantham.edu/",
  },
  {
    label: "Gratz College",
    value: "GC_10",
    url: "http://www.gratzcollege.edu/",
  },
  {
    label: "Great Lakes Christian College",
    value: "GLCC",
    url: "http://www.glcc.edu/",
  },
  {
    label: "Green Mountain College",
    value: "GMC",
    url: "http://www.greenmtn.edu/",
  },
  {
    label: "Greensboro College",
    value: "GC_11",
    url: "http://www.gborocollege.edu/",
  },
  {
    label: "Greenville College",
    value: "GC_12",
    url: "http://www.greenville.edu/",
  },
  {
    label: "Grinnell College",
    value: "GC_13",
    url: "http://www.grinnell.edu/",
  },
  { label: "Grove City College", value: "GCC_1", url: "http://www.gcc.edu/" },
  {
    label: "Guilford College",
    value: "GC_14",
    url: "http://www.guilford.edu/",
  },
  {
    label: "Gustavus Adolphus College",
    value: "GAC",
    url: "http://www.gac.edu/",
  },
  {
    label: "Gwynedd-Mercy College",
    value: "GC_15",
    url: "http://www.gmc.edu/",
  },
  {
    label: "Hagerstown Community College",
    value: "HCC",
    url: "http://www.hcc.cc.md.us/",
  },
  { label: "Hamilton College", value: "HC", url: "http://www.hamilton.edu/" },
  {
    label: "Hamilton Technical College",
    value: "HTC",
    url: "http://www.vca1.com/hamiltontech/",
  },
  { label: "Hamline University", value: "HU", url: "http://www.hamline.edu/" },
  {
    label: "Hampden-Sydney College",
    value: "HC_1",
    url: "http://www.hsc.edu/",
  },
  {
    label: "Hampshire College",
    value: "HC_2",
    url: "http://www.hampshire.edu/",
  },
  {
    label: "Hampton College",
    value: "HC_3",
    url: "http://www.hamptoncollege.org/",
  },
  {
    label: "Hampton University",
    value: "HU_1",
    url: "http://www.hamptonu.edu/",
  },
  {
    label: "Hannibal-Lagrange College",
    value: "HC_4",
    url: "http://www.hlg.edu/",
  },
  { label: "Hanover College", value: "HC_5", url: "http://www.hanover.edu/" },
  {
    label: "Harding University",
    value: "HU_2",
    url: "http://www.harding.edu/",
  },
  {
    label: "Harding University Graduate School of Religion",
    value: "HUGSR",
    url: "http://www.hugsr.edu/",
  },
  {
    label: "Hardin-Simmons University",
    value: "HU_3",
    url: "http://www.hsutx.edu/",
  },
  {
    label: "Harrington Institute of Interior Design",
    value: "HIID",
    url: "http://www.interiordesign.edu/",
  },
  {
    label: "Harris-Stowe State University",
    value: "HSU",
    url: "http://www.hssu.edu/",
  },
  {
    label: "Hartford College for Women",
    value: "HCFW",
    url: "http://www.hartford.edu/SchoolsColleges/HCW/HCW.html",
  },
  {
    label: "Hartford Graduate Center (Rensselaer at Hartford)",
    value: "HGC(AH",
    url: "http://www.hgc.edu/",
  },
  { label: "Hartwick College", value: "HC_6", url: "http://www.hartwick.edu/" },
  {
    label: "Harvard University",
    value: "HU_4",
    url: "http://www.harvard.edu/",
  },
  { label: "Harvey Mudd College", value: "HMC", url: "http://www.hmc.edu/" },
  { label: "Hastings College", value: "HC_7", url: "http://www.hastings.edu/" },
  {
    label: "Haverford College",
    value: "HC_8",
    url: "http://www.haverford.edu/",
  },
  {
    label: "Hawaii Pacific University",
    value: "HPU",
    url: "http://www.hpu.edu/",
  },
  {
    label: "Heartland Baptist Bible College",
    value: "HBBC",
    url: "http://www.hbbc.edu/",
  },
  {
    label: "Hebrew College",
    value: "HC_9",
    url: "http://www.hebrewcollege.edu/",
  },
  {
    label: "Heidelberg College",
    value: "HC_10",
    url: "http://www.heidelberg.edu/",
  },
  {
    label: "Henderson State Univerisity",
    value: "HSU_1",
    url: "http://www.hsu.edu/",
  },
  { label: "Hendrix College", value: "HC_11", url: "http://www.hendrix.edu/" },
  {
    label: "Heritage College",
    value: "HC_12",
    url: "http://www.heritage.edu/",
  },
  { label: "Hesser College", value: "HC_13", url: "http://www.hesser.edu/" },
  {
    label: "High Point University",
    value: "HPU_1",
    url: "http://www.highpoint.edu/",
  },
  { label: "Hilbert College", value: "HC_14", url: "http://www.hilbert.edu/" },
  {
    label: "Hillsdale College",
    value: "HC_15",
    url: "http://www.hillsdale.edu/",
  },
  { label: "Hiram College", value: "HC_16", url: "http://www.hiram.edu/" },
  {
    label: "Hobart and William Smith Colleges",
    value: "HWSC",
    url: "http://www.hws.edu/",
  },
  {
    label: "Hobe Sound Bible College",
    value: "HSBC",
    url: "http://www.hsbc.edu/",
  },
  { label: "Hodges University", value: "HU_5", url: "http://www.hodges.edu/" },
  {
    label: "Hofstra University",
    value: "HU_6",
    url: "http://www.hofstra.edu/",
  },
  {
    label: "Hollins University",
    value: "HU_7",
    url: "http://www.hollins.edu/",
  },
  {
    label: "Holy Apostles College",
    value: "HAC",
    url: "http://www.holy-apostles.org/",
  },
  {
    label: "Holy Cross College",
    value: "HCC_1",
    url: "http://www.hcc-nd.edu/",
  },
  { label: "Holy Family College", value: "HFC", url: "http://www.hfc.edu/" },
  { label: "Holy Names College", value: "HNC", url: "http://www.hnc.edu/" },
  { label: "Hood College", value: "HC_17", url: "http://www.hood.edu/" },
  { label: "Hope College", value: "HC_18", url: "http://www.hope.edu/" },
  {
    label: "Hope International University",
    value: "HIU",
    url: "http://www.hiu.edu/",
  },
  {
    label: "Houghton College",
    value: "HC_19",
    url: "http://www.houghton.edu/",
  },
  {
    label: "Houston Baptist University",
    value: "HBU",
    url: "http://www.hbu.edu/",
  },
  {
    label: "Howard Payne University",
    value: "HPU_2",
    url: "http://www.hputx.edu/",
  },
  { label: "Howard University", value: "HU_8", url: "http://www.howard.edu/" },
  {
    label: "Humboldt State University",
    value: "HSU_2",
    url: "http://www.humboldt.edu/",
  },
  {
    label: "Humphreys College",
    value: "HC_20",
    url: "http://www.humphreys.edu/",
  },
  {
    label: "Huntington College",
    value: "HC_21",
    url: "http://www.huntcol.edu/",
  },
  { label: "Huron University", value: "HU_9", url: "http://www.huron.edu/" },
  { label: "Husson College", value: "HC_22", url: "http://www.husson.edu/" },
  {
    label: "Huston-Tillotson College",
    value: "HC_23",
    url: "http://www.htc.edu/",
  },
  { label: "ICI University", value: "IU", url: "http://www.ici.edu/" },
  { label: "ICT College", value: "IC", url: "http://www.ictcollege.edu/" },
  { label: "Idaho State University", value: "ISU", url: "http://www.isu.edu/" },
  {
    label: "Iglobal University",
    value: "IU_1",
    url: "http://www.iglobal.edu/",
  },
  {
    label: "Illinois Benedictine University",
    value: "IBU",
    url: "http://www.ben.edu/",
  },
  { label: "Illinois College", value: "IC_1", url: "http://www.ic.edu/" },
  {
    label: "Illinois College of Optometry",
    value: "ICO",
    url: "http://www.ico.edu/",
  },
  {
    label: "Illinois Institute of Technology",
    value: "IIT",
    url: "http://www.iit.edu/",
  },
  {
    label: "Illinois School of Professional Psychology - Chicago Campus",
    value: "ISPP-CC",
    url: "http://www.aspp.edu/ilch.html",
  },
  {
    label: "Illinois School of Professional Psychology - Meadows Campus",
    value: "ISPP-MC",
    url: "http://www.aspp.edu/ilrm.html",
  },
  {
    label: "Illinois State University",
    value: "ISU_1",
    url: "http://www.ilstu.edu/",
  },
  {
    label: "Illinois Valley Community College",
    value: "IVCC",
    url: "http://www.ivcc.edu/",
  },
  {
    label: "Illinois Wesleyan University",
    value: "IWU",
    url: "http://www.iwu.edu/",
  },
  {
    label: "Immaculata University",
    value: "IU_2",
    url: "http://www.immaculata.edu/",
  },
  { label: "IMPAC University", value: "IU_3", url: "http://www.impacu.edu/" },
  {
    label: "Indiana Institute of Technologyy",
    value: "IIT_1",
    url: "http://www.indtech.edu/",
  },
  {
    label: "Indiana State University",
    value: "ISU_2",
    url: "http://www.indstate.edu/",
  },
  {
    label: "Indiana University at Bloomington",
    value: "IUAB",
    url: "http://www.iub.edu/",
  },
  {
    label: "Indiana University at Kokomo",
    value: "IUAK",
    url: "http://www.iuk.edu/",
  },
  {
    label: "Indiana University at South Bend",
    value: "IUASB",
    url: "http://www.iusb.edu/",
  },
  {
    label: "Indiana University - East",
    value: "IU-E",
    url: "http://www.iue.indiana.edu/",
  },
  {
    label: "Indiana University - Northwest",
    value: "IU-N",
    url: "http://www.iun.indiana.edu/",
  },
  {
    label: "Indiana University of Pennsylvania",
    value: "IUP",
    url: "http://www.iup.edu/",
  },
  {
    label: "Indiana University-Purdue University at Columbus",
    value: "IUUAC",
    url: "http://www.columbus.iupui.edu/",
  },
  {
    label: "Indiana University-Purdue University at Fort Wayne",
    value: "IUUAFW",
    url: "http://www.ipfw.edu/",
  },
  {
    label: "Indiana University-Purdue University at Indianapolis",
    value: "IUUAI",
    url: "http://www.iupui.edu/",
  },
  {
    label: "Indiana University - Southeast",
    value: "IU-S",
    url: "http://www.ius.indiana.edu/",
  },
  {
    label: "Indiana University (System)",
    value: "IU(",
    url: "http://www.indiana.edu/",
  },
  {
    label: "Indiana Wesleyan University",
    value: "IWU_1",
    url: "http://www.indwes.edu/",
  },
  {
    label: "Institute of Clinical Social Work",
    value: "ICSW",
    url: "http://www.icsw.com/",
  },
  {
    label: "Institute of Paper Science and Technology",
    value: "IPST",
    url: "http://www.ipst.edu/",
  },
  {
    label: "Institute of Textile Technology",
    value: "ITT",
    url: "http://www.itt.edu/",
  },
  {
    label: "Institute of Transpersonal Psychology",
    value: "ITP",
    url: "http://www.itp.edu/",
  },
  {
    label: "IntelliTec College - Grand Junction",
    value: "IC-GJ",
    url: "http://www.intelliteccollege.edu/",
  },
  {
    label: "International Academy of  Merchandising and Design Chicago",
    value: "IAMDC",
    url: "http://www.iamd.edu/",
  },
  {
    label: "International Academy of  Merchandising and Design Tampa",
    value: "IAMDT",
    url: "http://www.academy.edu/",
  },
  {
    label: "International Bible College",
    value: "IBC",
    url: "http://www.i-b-c.edu/",
  },
  {
    label: "International College",
    value: "IC_2",
    url: "http://www.internationalcollege.edu/",
  },
  { label: "Iona College", value: "IC_3", url: "http://www.iona.edu/" },
  {
    label: "Iowa State University of Science and Technology",
    value: "ISUST",
    url: "http://www.iastate.edu/",
  },
  { label: "Iowa Wesleyan College", value: "IWC", url: "http://www.iwc.edu/" },
  { label: "Ithaca College", value: "IC_4", url: "http://www.ithaca.edu/" },
  {
    label: "ITT Technical Institute Fort Wayne",
    value: "ITIFW",
    url: "http://www.itttech.edu/campus/",
  },
  {
    label: "ITT Technical Institute Indianapolis",
    value: "ITII",
    url: "http://www.itttech.edu/campus/",
  },
  {
    label: "ITT Technical Institute Maitland",
    value: "ITIM",
    url: "http://www.itttech.edu/campus/",
  },
  {
    label: "ITT Technical Institute Portland",
    value: "ITIP",
    url: "http://www.itttech.edu/campus/",
  },
  {
    label: "ITT Technical Institute West Covina",
    value: "ITIWC",
    url: "http://www.itttech.edu/campus/",
  },
  {
    label: "Jackson State University",
    value: "JSU",
    url: "http://www.jsums.edu/",
  },
  {
    label: "Jacksonville State University",
    value: "JSU_1",
    url: "http://www.jsu.edu/",
  },
  { label: "Jacksonville University", value: "JU", url: "http://www.ju.edu/" },
  {
    label: "James Madison University",
    value: "JMU",
    url: "http://www.jmu.edu/",
  },
  { label: "Jamestown College", value: "JC", url: "http://www.jc.edu/" },
  {
    label: "Jarvis Christian College",
    value: "JCC",
    url: "http://www.jarvis.edu/",
  },
  { label: "John Brown University", value: "JBU", url: "http://www.jbu.edu/" },
  {
    label: "John Carroll University",
    value: "JCU",
    url: "http://www.jcu.edu/",
  },
  {
    label: "John F. Kennedy University",
    value: "JFKU",
    url: "http://www.jfku.edu/",
  },
  {
    label: "John Marshall Law School",
    value: "JMLS",
    url: "http://www.jmls.edu/",
  },
  {
    label: "John Paul the Great Catholic University",
    value: "JPTGCU",
    url: "http://www.jpcatholic.com/",
  },
  {
    label: "Johns Hopkins University",
    value: "JHU",
    url: "http://www.jhu.edu/",
  },
  { label: "Johnson Bible College", value: "JBC", url: "http://www.jbc.edu/" },
  {
    label: "Johnson County Community College",
    value: "JCCC",
    url: "http://www.jccc.edu/",
  },
  {
    label: "Johnson C. Smith University",
    value: "JCSU",
    url: "http://www.jcsu.edu/",
  },
  {
    label: "Johnson State College",
    value: "JSC",
    url: "http://www.jsc.vsc.edu/",
  },
  {
    label: "Johnson & Wales University",
    value: "JWU",
    url: "http://www.jwu.edu/",
  },
  {
    label: "Johnson & Wales University, Charleston",
    value: "JWUC",
    url: "http://www.jwu.edu/charles/camp_charles.htm",
  },
  {
    label: "John Wesley College",
    value: "JWC",
    url: "http://www.johnwesley.edu/",
  },
  { label: "Jones College", value: "JC_1", url: "http://www.jones.edu/" },
  {
    label: "Jones International University",
    value: "JIU",
    url: "http://www.jonesinternational.edu/",
  },
  {
    label: "Judson College Elgin",
    value: "JCE",
    url: "http://www.judson-il.edu/",
  },
  {
    label: "Judson College Marion",
    value: "JCM",
    url: "http://home.judson.edu/",
  },
  { label: "Juniata College", value: "JC_2", url: "http://www.juniata.edu/" },
  { label: "Kalamazoo College", value: "KC", url: "http://www.kzoo.edu/" },
  {
    label: "Kankakee Community College",
    value: "KCC",
    url: "http://www.kankakeecc.org/",
  },
  {
    label: "Kansas City Art Institute",
    value: "KCAI",
    url: "http://www.kcai.edu/",
  },
  {
    label: "Kansas State University",
    value: "KSU",
    url: "http://www.ksu.edu/",
  },
  {
    label: "Kansas Wesleyan University",
    value: "KWU",
    url: "http://www.kwu.edu/",
  },
  { label: "Kaplan University", value: "KU", url: "http://www.kaplan.edu/" },
  {
    label: "Katharine Gibbs School",
    value: "KGS",
    url: "http://www.kgibbs.com/",
  },
  {
    label: "Kean University of New Jersey",
    value: "KUNJ",
    url: "http://www.kean.edu/",
  },
  {
    label: "Keck Graduate Institute of Applied Life Sciences",
    value: "KGIALS",
    url: "http://www.kgi.edu/",
  },
  { label: "Keene State College", value: "KSC", url: "http://www.keene.edu/" },
  {
    label: "Keller Graduate School of Management",
    value: "KGSM",
    url: "http://www.keller.edu/",
  },
  { label: "Kendall College", value: "KC_1", url: "http://www.kendall.edu/" },
  {
    label: "Kendall College of Art and Design",
    value: "KCAD",
    url: "http://www.kcad.edu/",
  },
  {
    label: "Kennesaw State University",
    value: "KSU_1",
    url: "http://www.kennesaw.edu/",
  },
  {
    label: "Kent State University",
    value: "KSU_2",
    url: "http://www.kent.edu/",
  },
  {
    label: "Kent State University - Ashtabula",
    value: "KSU-A",
    url: "http://www.ashtabula.kent.edu/",
  },
  {
    label: "Kent State University - East Liverpool",
    value: "KSU-EL",
    url: "http://www.kenteliv.kent.edu/",
  },
  {
    label: "Kent State University - Salem",
    value: "KSU-S",
    url: "http://www.salem.kent.edu/",
  },
  {
    label: "Kent State University - Stark",
    value: "KSU-S_1",
    url: "http://www.stark.kent.edu/",
  },
  {
    label: "Kent State University - Trumbull",
    value: "KSU-T",
    url: "http://www.trumbull.kent.edu/",
  },
  {
    label: "Kent State University - Tuscarawas",
    value: "KSU-T_1",
    url: "http://www.tusc.kent.edu/",
  },
  {
    label: "Kentucky Christian College",
    value: "KCC_1",
    url: "http://www.kcc.edu/",
  },
  {
    label: "Kentucky State University",
    value: "KSU_3",
    url: "http://www.kysu.edu/",
  },
  {
    label: "Kentucky Wesleyan College",
    value: "KWC",
    url: "http://www.kwc.edu/",
  },
  { label: "Kenyon College", value: "KC_2", url: "http://www.kenyon.edu/" },
  {
    label: "Kettering University (GMI)",
    value: "KU(",
    url: "http://www.kettering.edu/",
  },
  { label: "Keuka College", value: "KC_3", url: "http://www.keuka.edu/" },
  { label: "King College", value: "KC_4", url: "http://www.king.edu/" },
  { label: "King's College", value: "KC_5", url: "http://www.kings.edu/" },
  {
    label: "Kirksville College of Osteopathic Medicine",
    value: "KCOM",
    url: "http://www.kcom.edu/",
  },
  {
    label: "Kirkwood Community College",
    value: "KCC_2",
    url: "http://www.kirkwood.edu/",
  },
  { label: "Knox College", value: "KC_6", url: "http://www.knox.edu/" },
  {
    label: "Knoxville College",
    value: "KC_7",
    url: "http://www.knoxvillecollege.edu/",
  },
  {
    label: "Kutztown University of Pennsylvania",
    value: "KUP",
    url: "http://www.kutztown.edu/",
  },
  {
    label: "Laboratory Institute of Merchandising",
    value: "LIM",
    url: "http://www.limcollege.edu/",
  },
  { label: "Lafayette College", value: "LC", url: "http://www.lafayette.edu/" },
  { label: "Lagrange College", value: "LC_1", url: "http://www.lgc.edu/" },
  { label: "Lake Erie College", value: "LEC", url: "http://www.lakeerie.edu/" },
  { label: "Lake Forest College", value: "LFC", url: "http://www.lfc.edu/" },
  {
    label: "Lake Forest Graduate School of Management",
    value: "LFGSM",
    url: "http://www.lfgsm.edu/",
  },
  { label: "Lakeland College", value: "LC_2", url: "http://www.lakeland.edu/" },
  {
    label: "Lake Superior State University",
    value: "LSSU",
    url: "http://www.lssu.edu/",
  },
  {
    label: "Lakeview College of Nursing",
    value: "LCN",
    url: "http://www.lakeviewcol.edu/",
  },
  { label: "Lamar University", value: "LU", url: "http://www.lamar.edu/" },
  {
    label: "Lamar University - Port Arthur",
    value: "LU-PA",
    url: "http://www.pa.lamar.edu/",
  },
  {
    label: "Lambuth University",
    value: "LU_1",
    url: "http://www.lambuth.edu/",
  },
  {
    label: "Lancaster Bible College",
    value: "LBC",
    url: "http://www.lbc.edu/",
  },
  { label: "Lander University", value: "LU_2", url: "http://www.lander.edu/" },
  { label: "Lane College", value: "LC_3", url: "http://www.lanecollege.edu/" },
  { label: "Langston University", value: "LU_3", url: "http://www.lunet.edu/" },
  { label: "La Roche College", value: "LRC", url: "http://www.laroche.edu/" },
  {
    label: "La Salle University",
    value: "LSU",
    url: "http://www.lasalle.edu/",
  },
  { label: "Lasell College", value: "LC_4", url: "http://www.lasell.edu/" },
  {
    label: "La Sierra University",
    value: "LSU_1",
    url: "http://www.lasierra.edu/",
  },
  {
    label: "Laurus Technical Institute",
    value: "LTI",
    url: "http://www.laurus.edu/",
  },
  {
    label: "Lawrence Technological University",
    value: "LTU",
    url: "http://www.ltu.edu/",
  },
  {
    label: "Lawrence University",
    value: "LU_4",
    url: "http://www.lawrence.edu/",
  },
  { label: "Lebanon Valley College", value: "LVC", url: "http://www.lvc.edu/" },
  { label: "Lees-McRae College", value: "LC_5", url: "http://www.lmc.edu/" },
  {
    label: "Lee University",
    value: "LU_5",
    url: "http://www.leeuniversity.edu/",
  },
  { label: "Lehigh Univervsity", value: "LU_6", url: "http://www.lehigh.edu/" },
  { label: "Le Moyne College", value: "LMC", url: "http://www.lemoyne.edu/" },
  {
    label: "Le Moyne-Owen College",
    value: "LMC_1",
    url: "http://www.lemoyne-owen.edu/",
  },
  { label: "Lenoir-Rhyne College", value: "LC_6", url: "http://www.lrc.edu/" },
  { label: "Lesley University", value: "LU_7", url: "http://www.lesley.edu/" },
  {
    label: "Letourneau University",
    value: "LU_8",
    url: "http://www.letu.edu/",
  },
  {
    label: "Lewis and Clark College",
    value: "LCC",
    url: "http://www.lclark.edu/",
  },
  {
    label: "Lewis & Clark Community College",
    value: "LCCC",
    url: "http://www.lc.cc.il.us/",
  },
  {
    label: "Lewis-Clark State College",
    value: "LSC",
    url: "http://www.lcsc.edu/",
  },
  { label: "Lewis University", value: "LU_9", url: "http://www.lewisu.edu/" },
  {
    label: "Liberty University",
    value: "LU_10",
    url: "http://www.liberty.edu/",
  },
  {
    label: "Life Chiropractic College West",
    value: "LCCW",
    url: "http://www.lifewest.edu/",
  },
  { label: "Life University", value: "LU_11", url: "http://www.life.edu/" },
  {
    label: "Limestone College",
    value: "LC_7",
    url: "http://www.limestone.edu/",
  },
  {
    label: "Lincoln Memorial University",
    value: "LMU",
    url: "http://www.lmunet.edu/",
  },
  {
    label: "Lincoln University Missouri",
    value: "LUM",
    url: "http://www.lincolnu.edu/",
  },
  {
    label: "Lincoln University Pennsylvania",
    value: "LUP",
    url: "http://www.lincoln.edu/",
  },
  {
    label: "Lincoln University San Francisco",
    value: "LUSF",
    url: "http://www.lincolnuca.edu/",
  },
  {
    label: "Lindenwood University",
    value: "LU_12",
    url: "http://www.lindenwood.edu/",
  },
  {
    label: "Lindsey Wilson College",
    value: "LWC",
    url: "http://www.lindsey.edu/",
  },
  { label: "Linfield College", value: "LC_8", url: "http://www.linfield.edu/" },
  {
    label: "Lipscomb University",
    value: "LU_13",
    url: "http://www.lipscomb.edu/",
  },
  {
    label: "Livingstone College",
    value: "LC_9",
    url: "http://www.livingstone.edu/",
  },
  {
    label: "Lock Haven University of Pennsylvania",
    value: "LHUP",
    url: "http://www.lhup.edu/",
  },
  {
    label: "Logan College of Chiropractic",
    value: "LCC_1",
    url: "http://www.logan.edu/",
  },
  { label: "Loma Linda University", value: "LLU", url: "http://www.llu.edu/" },
  {
    label: "Long Island University",
    value: "LIU",
    url: "http://www.liunet.edu/",
  },
  {
    label: "Long Island University, C.W. Post Campus",
    value: "LIUCPC",
    url: "http://www.cwpost.liunet.edu/cwis/cwp/post.html",
  },
  {
    label: "Long Island University, Southampton College",
    value: "LIUSC",
    url: "http://www.southampton.liunet.edu/",
  },
  { label: "Longwood College", value: "LC_10", url: "http://www.lwc.edu/" },
  { label: "Loras College", value: "LC_11", url: "http://www.loras.edu/" },
  {
    label: "Los Angeles College of Chiropractic",
    value: "LACC",
    url: "http://www.lacc.edu/",
  },
  {
    label: "Louisiana Baptist University",
    value: "LBU",
    url: "http://www.lbu.edu/",
  },
  {
    label: "Louisiana College",
    value: "LC_12",
    url: "http://www.lacollege.edu/",
  },
  {
    label: "Louisiana State University and Agricultural and Mechanical College",
    value: "LSUAMC",
    url: "http://www.lsu.edu/",
  },
  {
    label: "Louisiana State University at Alexandria",
    value: "LSUAA",
    url: "http://www.lsua.edu/",
  },
  {
    label: "Louisiana State University at Eunice",
    value: "LSUAE",
    url: "http://www.lsue.edu/",
  },
  {
    label: "Louisiana State University Health Sciences Center New Orleans",
    value: "LSUHSCNO",
    url: "http://www.lsuhsc.edu/",
  },
  {
    label: "Louisiana State University in Shreveport",
    value: "LSUIS",
    url: "http://www.lsus.edu/",
  },
  {
    label: "Louisiana Tech University",
    value: "LTU_1",
    url: "http://www.latech.edu/",
  },
  { label: "Lourdes College", value: "LC_13", url: "http://www.lourdes.edu/" },
  {
    label: "Loyola College in Maryland",
    value: "LCIM",
    url: "http://www.loyola.edu/",
  },
  {
    label: "Loyola Marymount University",
    value: "LMU_1",
    url: "http://www.lmu.edu/",
  },
  {
    label: "Loyola University New Orleans",
    value: "LUNO",
    url: "http://www.loyno.edu/",
  },
  {
    label: "Loyola University of Chicago",
    value: "LUC",
    url: "http://www.luc.edu/",
  },
  {
    label: "Lubbock Christian University",
    value: "LCU",
    url: "http://www.lcu.edu/",
  },
  {
    label: "Lutheran Bible Institute of Seattle",
    value: "LBIS",
    url: "http://www.lbi.edu/",
  },
  { label: "Luther College", value: "LC_14", url: "http://www.luther.edu/" },
  {
    label: "Lycoming College",
    value: "LC_15",
    url: "http://www.lycoming.edu/",
  },
  {
    label: "Lynchburg College",
    value: "LC_16",
    url: "http://www.lynchburg.edu/",
  },
  {
    label: "Lyndon State College",
    value: "LSC_1",
    url: "http://www.lsc.vsc.edu/",
  },
  { label: "Lynn University", value: "LU_14", url: "http://www.lynn.edu/" },
  { label: "Lyon College", value: "LC_17", url: "http://www.lyon.edu/" },
  { label: "Macalester College", value: "MC", url: "http://www.macalstr.edu/" },
  { label: "MacMurray College", value: "MC_1", url: "http://www.mac.edu/" },
  {
    label: "Macon State College",
    value: "MSC",
    url: "http://www.maconstate.edu/",
  },
  {
    label: "Madison University (Distance Education)",
    value: "MU(E",
    url: "http://www.madisonu.com/",
  },
  {
    label: "Madonna University",
    value: "MU",
    url: "http://madonna2.siteobjects.com/pages/index.cfm",
  },
  {
    label: "Maharishi University of Management",
    value: "MUM",
    url: "http://www.mum.edu/",
  },
  { label: "Maine College of Art", value: "MCA", url: "http://www.meca.edu/" },
  {
    label: "Maine Maritime Academy",
    value: "MMA",
    url: "http://www.mainemaritime.edu/",
  },
  { label: "Malone College", value: "MC_2", url: "http://www.malone.edu/" },
  {
    label: "Manchester College",
    value: "MC_3",
    url: "http://www.manchester.edu/",
  },
  {
    label: "Manhattan Christian College",
    value: "MCC",
    url: "http://www.mccks.edu/",
  },
  { label: "Manhattan College", value: "MC_4", url: "http://www.mancol.edu/" },
  {
    label: "Manhattan School of Music",
    value: "MSM",
    url: "http://www.msmnyc.edu/",
  },
  {
    label: "Manhattanville College",
    value: "MC_5",
    url: "http://www.mville.edu/",
  },
  {
    label: "Mankato State University",
    value: "MSU",
    url: "http://www.mankato.msus.edu/",
  },
  {
    label: "Mansfield University of Pennsylvania",
    value: "MUP",
    url: "http://www.mnsfld.edu/",
  },
  {
    label: "Maranatha Baptist Bible College",
    value: "MBBC",
    url: "http://www.mbbc.edu/",
  },
  { label: "Marian College", value: "MC_6", url: "http://www.marian.edu/" },
  {
    label: "Marian College of Fond Du Lac",
    value: "MCFDL",
    url: "http://www.mariancoll.edu/",
  },
  { label: "Marietta College", value: "MC_7", url: "http://www.marietta.edu/" },
  { label: "Marist College", value: "MC_8", url: "http://www.marist.edu/" },
  { label: "Marlboro College", value: "MC_9", url: "http://www.marlboro.edu/" },
  { label: "Marquette University", value: "MU_1", url: "http://www.mu.edu/" },
  {
    label: "Marshall University",
    value: "MU_2",
    url: "http://www.marshall.edu/",
  },
  { label: "Mars Hill College", value: "MHC", url: "http://www.mhc.edu/" },
  {
    label: "Martin Luther College",
    value: "MLC",
    url: "http://www.mlc-wels.edu/",
  },
  {
    label: "Martin Methodist College",
    value: "MMC",
    url: "http://www.martinmethodist.edu/",
  },
  { label: "Martin University", value: "MU_3", url: "http://www.martin.edu/" },
  { label: "Mary Baldwin College", value: "MBC", url: "http://www.mbc.edu/" },
  {
    label: "Marycrest International University",
    value: "MIU",
    url: "http://www.mcrest.edu/",
  },
  {
    label: "Marygrove College",
    value: "MC_10",
    url: "http://www.marygrove.edu/",
  },
  {
    label: "Marylhurst University",
    value: "MU_4",
    url: "http://www.marylhurst.edu/",
  },
  {
    label: "Marymount College New York",
    value: "MCNY",
    url: "http://www.marymt.edu/",
  },
  {
    label: "Marymount Manhattan College",
    value: "MMC_1",
    url: "http://marymount.mmm.edu/",
  },
  {
    label: "Marymount University",
    value: "MU_5",
    url: "http://www.marymount.edu/",
  },
  {
    label: "Maryville College",
    value: "MC_11",
    url: "http://www.maryvillecollege.edu/",
  },
  {
    label: "Maryville University of St. Louis",
    value: "MUSL",
    url: "http://www.maryvillestl.edu/",
  },
  {
    label: "Mary Washington College",
    value: "MWC",
    url: "http://www.mwc.edu/",
  },
  {
    label: "Marywood University",
    value: "MU_6",
    url: "http://www.marywood.edu/",
  },
  {
    label: "Massachusetts College of Art",
    value: "MCA_1",
    url: "http://www.massart.edu/",
  },
  {
    label: "Massachusetts College of Liberal Arts",
    value: "MCLA",
    url: "http://www.mcla.mass.edu/",
  },
  {
    label: "Massachusetts College of Pharmacy and Allied Health Sciences",
    value: "MCPAHS",
    url: "http://www.mcp.edu/",
  },
  {
    label: "Massachusetts Institute of Technology",
    value: "MIT",
    url: "http://www.mit.edu/",
  },
  {
    label: "Massachusetts Maritime Academy",
    value: "MMA_1",
    url: "http://www.mma.mass.edu/",
  },
  {
    label: "Massachusetts School of Professional Psychology",
    value: "MSPP",
    url: "http://www.mspp.edu/",
  },
  {
    label: "Mayo Graduate School",
    value: "MGS",
    url: "http://www.mayo.edu/mgs/gs.html",
  },
  {
    label: "Mayo Medical School",
    value: "MMS",
    url: "http://www.mayo.edu/education/mms/",
  },
  {
    label: "Mayville State University",
    value: "MSU_1",
    url: "http://www.masu.nodak.edu/",
  },
  {
    label: "McKendree College",
    value: "MC_12",
    url: "http://www.mckendree.edu/",
  },
  { label: "McMurry University", value: "MU_7", url: "http://www.mcm.edu/" },
  {
    label: "McNeese State University",
    value: "MSU_2",
    url: "http://www.mcneese.edu/",
  },
  {
    label: "McPherson College",
    value: "MC_13",
    url: "http://www.mcpherson.edu/",
  },
  {
    label: "Medaille College",
    value: "MC_14",
    url: "http://www.medaille.edu/",
  },
  {
    label: "Medcenter One College of Nursing",
    value: "MOCN",
    url: "http://www.medcenterone.com/nursing/nursing.htm",
  },
  {
    label: "Medical College of Georgia",
    value: "MCG",
    url: "http://www.mcg.edu/",
  },
  {
    label: "Medical College of Ohio",
    value: "MCO",
    url: "http://www.mco.edu/",
  },
  {
    label: "Medical College of Pennsylvania and Hahnemann University",
    value: "MCPHU",
    url: "http://www.mcphu.edu/",
  },
  {
    label: "Medical College of Wisconsin",
    value: "MCW",
    url: "http://www.mcw.edu/",
  },
  {
    label: "Medical University of South Carolina",
    value: "MUSC",
    url: "http://www.musc.edu/",
  },
  {
    label: "Meharry Medical College",
    value: "MMC_2",
    url: "http://www.mmc.edu/",
  },
  {
    label: "Memphis College of Art",
    value: "MCA_2",
    url: "http://www.mca.edu/",
  },
  { label: "Menlo College", value: "MC_15", url: "http://www.menlo.edu/" },
  {
    label: "Mennonite College of Nursing",
    value: "MCN",
    url: "http://www.mcn.ilstu.edu/",
  },
  { label: "Mercer University", value: "MU_8", url: "http://www.mercer.edu/" },
  {
    label: "Mercer University, Cecil B. Day Campus",
    value: "MUCBDC",
    url: "http://www.mercer.edu/cbd/",
  },
  { label: "Mercy College", value: "MC_16", url: "http://www.mercynet.edu/" },
  {
    label: "Mercyhurst College",
    value: "MC_17",
    url: "http://www.mercyhurst.edu/",
  },
  {
    label: "Meredith College",
    value: "MC_18",
    url: "http://www.meredith.edu/",
  },
  {
    label: "Merrimack College",
    value: "MC_19",
    url: "http://www.merrimack.edu/",
  },
  {
    label: "Mesa State College",
    value: "MSC_1",
    url: "http://www.mesastate.edu/",
  },
  { label: "Messiah College", value: "MC_20", url: "http://www.messiah.edu/" },
  {
    label: "Methodist College",
    value: "MC_21",
    url: "http://www.methodist.edu/",
  },
  {
    label: "Metropolitan State College of Denver",
    value: "MSCD",
    url: "http://www.mscd.edu/",
  },
  {
    label: "Metropolitan State University",
    value: "MSU_3",
    url: "http://www.metro.msus.edu/",
  },
  {
    label: "MGH Institute of Health Professions",
    value: "MIHP",
    url: "http://www.mghihp.edu/",
  },
  { label: "Miami Dade College", value: "MDC", url: "http://www.mdc.edu/" },
  {
    label: "Miami University of Ohio",
    value: "MUO",
    url: "http://www.muohio.edu/",
  },
  {
    label: "Miami University of Ohio - Hamilton",
    value: "MUO-H",
    url: "http://www.ham.muohio.edu/",
  },
  {
    label: "Miami University of Ohio - Middletown",
    value: "MUO-M",
    url: "http://www.mid.muohio.edu/",
  },
  {
    label: "Michigan School of Professional Psychology",
    value: "MSPP_1",
    url: "http://www.mispp.edu/",
  },
  {
    label: "Michigan State University",
    value: "MSU_4",
    url: "http://www.msu.edu/",
  },
  {
    label: "Michigan Technological University",
    value: "MTU",
    url: "http://www.mtu.edu/",
  },
  {
    label: "Mid-America Nazarene University",
    value: "MNU",
    url: "http://www.mnu.edu/",
  },
  {
    label: "Mid-American Bible College",
    value: "MBC_1",
    url: "http://www.concentric.net/~rkriesel/MBC/MBC.shtml",
  },
  {
    label: "Mid-Continent Baptist Bible College",
    value: "MBBC_1",
    url: "http://www.mcbc.edu/",
  },
  {
    label: "Middlebury College",
    value: "MC_22",
    url: "http://www.middlebury.edu/",
  },
  {
    label: "Middle Tennessee State University",
    value: "MTSU",
    url: "http://www.mtsu.edu/",
  },
  {
    label: "Midland Lutheran College",
    value: "MLC_1",
    url: "http://www.mlc.edu/",
  },
  { label: "Midway College", value: "MC_23", url: "http://www.midway.edu/" },
  {
    label: "Midwestern State University",
    value: "MSU_5",
    url: "http://www.mwsu.edu/",
  },
  {
    label: "Midwestern University",
    value: "MU_9",
    url: "http://www.midwestern.edu/",
  },
  { label: "Miles College", value: "MC_24", url: "http://www.miles.edu/" },
  {
    label: "Millennia Atlantic University",
    value: "MAU",
    url: "http://www.maufl.edu/",
  },
  {
    label: "Millersville University of Pennsylvania",
    value: "MUP_1",
    url: "http://www.millersv.edu/",
  },
  {
    label: "Milligan College",
    value: "MC_25",
    url: "http://www.milligan.edu/",
  },
  {
    label: "Millikin University",
    value: "MU_10",
    url: "http://www.millikin.edu/",
  },
  {
    label: "Millsaps College",
    value: "MC_26",
    url: "http://www.millsaps.edu/",
  },
  { label: "Mills College", value: "MC_27", url: "http://www.mills.edu/" },
  { label: "Mills Grae University", value: "MGU", url: "http://www.mgu.edu/" },
  {
    label: "Milwaukee Institute of Art and Design",
    value: "MIAD",
    url: "http://www.miad.edu/",
  },
  {
    label: "Milwaukee School of Engineering",
    value: "MSE",
    url: "http://www.msoe.edu/",
  },
  {
    label: "Minneapolis College of Art and Design",
    value: "MCAD",
    url: "http://www.mcad.edu/",
  },
  {
    label: "Minnesota Bible College",
    value: "MBC_2",
    url: "http://www.mnbc.edu/",
  },
  {
    label: "Minnesota School of Professional Psychology",
    value: "MSPP_2",
    url: "http://www.aspp.edu/mn.html",
  },
  {
    label: "Minot State University",
    value: "MSU_6",
    url: "http://www.misu.nodak.edu/",
  },
  { label: "Mississippi College", value: "MC_28", url: "http://www.mc.edu/" },
  {
    label: "Mississippi State University",
    value: "MSU_7",
    url: "http://www.msstate.edu/",
  },
  {
    label: "Mississippi University for Women",
    value: "MUFW",
    url: "http://www.muw.edu/",
  },
  {
    label: "Mississippi Valley State University",
    value: "MVSU",
    url: "http://www.mvsu.edu/",
  },
  {
    label: "Missouri Baptist College",
    value: "MBC_3",
    url: "http://www.mobap.edu/",
  },
  {
    label: "Missouri Southern State College",
    value: "MSSC",
    url: "http://www.mssc.edu/",
  },
  { label: "Missouri Tech", value: "MT", url: "http://www.motech.edu/" },
  {
    label: "Missouri University of Science and Technology",
    value: "MUST",
    url: "http://www.mst.edu/",
  },
  {
    label: "Missouri Valley College",
    value: "MVC",
    url: "http://www.murlin.com/~webfx/mvc/",
  },
  {
    label: "Missouri Western State College",
    value: "MWSC",
    url: "http://www.mwsc.edu/",
  },
  { label: "Molloy College", value: "MC_29", url: "http://www.molloy.edu/" },
  {
    label: "Monmouth University",
    value: "MU_11",
    url: "http://www.monmouth.edu/",
  },
  {
    label: "Montana State University",
    value: "MSU_8",
    url: "http://www.montana.edu/",
  },
  {
    label: "Montana State University - Billings",
    value: "MSU-B",
    url: "http://www.msubillings.edu/",
  },
  {
    label: "Montana State University - Northern",
    value: "MSU-N",
    url: "http://www.msun.edu/",
  },
  { label: "Montana Tech", value: "MT_1", url: "http://www.mtech.edu/" },
  {
    label: "Montclair State University",
    value: "MSU_9",
    url: "http://www.montclair.edu/",
  },
  {
    label: "Monterey Institute of International Studies",
    value: "MIIS",
    url: "http://www.miis.edu/",
  },
  {
    label: "Montreat College",
    value: "MC_30",
    url: "http://www.montreat.edu/",
  },
  {
    label: "Montserrat College of Art",
    value: "MCA_3",
    url: "http://www.montserrat.edu/",
  },
  {
    label: "Moody Bible Institute",
    value: "MBI",
    url: "http://www.moody.edu/",
  },
  {
    label: "Moore College of Art and Design",
    value: "MCAD_1",
    url: "http://www.moore.edu/",
  },
  {
    label: "Moorhead State University",
    value: "MSU_10",
    url: "http://www.moorhead.msus.edu/",
  },
  {
    label: "Moraine Valley Community College",
    value: "MVCC",
    url: "http://www.morainevalley.edu/",
  },
  {
    label: "Moravian College",
    value: "MC_31",
    url: "http://www.moravian.edu/",
  },
  {
    label: "Morehead State University",
    value: "MSU_11",
    url: "http://www.morehead-st.edu/",
  },
  {
    label: "Morehouse College",
    value: "MC_32",
    url: "http://www.morehouse.edu/",
  },
  {
    label: "Morehouse School of Medicine",
    value: "MSM_1",
    url: "http://www.msm.edu/",
  },
  {
    label: "Morgan State University",
    value: "MSU_12",
    url: "http://www.morgan.edu/",
  },
  {
    label: "Morningside College",
    value: "MC_33",
    url: "http://www.morningside.edu/",
  },
  {
    label: "Morris Brown College",
    value: "MBC_4",
    url: "http://www.morrisbrown.edu/",
  },
  {
    label: "Morris College",
    value: "MC_34",
    url: "http://www.scicu.org/morris/",
  },
  {
    label: "Morrison College",
    value: "MC_35",
    url: "http://www.morrison.neumont.edu/",
  },
  {
    label: "Mountain State University",
    value: "MSU_13",
    url: "http://www.mountainstate.edu/",
  },
  {
    label: "Mount Aloysius College",
    value: "MAC",
    url: "http://www.mtaloy.edu/",
  },
  {
    label: "Mount Carmel College of Nursing",
    value: "MCCN",
    url: "http://www.mccn.edu/",
  },
  {
    label: "Mount Holyoke College",
    value: "MHC_1",
    url: "http://www.mtholyoke.edu/",
  },
  { label: "Mount Ida College", value: "MIC", url: "http://www.mountida.edu/" },
  { label: "Mount Marty College", value: "MMC_3", url: "http://www.mtmc.edu/" },
  {
    label: "Mount Mary College",
    value: "MMC_4",
    url: "http://www.mtmary.edu/",
  },
  {
    label: "Mount Mercy College",
    value: "MMC_5",
    url: "http://www.mtmercy.edu/",
  },
  {
    label: "Mount Olive College",
    value: "MOC",
    url: "http://www.mountolivecollege.edu/",
  },
  {
    label: "Mount Senario College",
    value: "MSC_2",
    url: "http://www.mscfs.edu/",
  },
  {
    label: "Mount Sinai School of Medicine",
    value: "MSSM",
    url: "http://www.mssm.edu/",
  },
  {
    label: "Mount St. Clare College",
    value: "MSCC",
    url: "http://www.clare.edu/",
  },
  {
    label: "Mount St. Mary College",
    value: "MSMC",
    url: "http://www.msmc.edu/",
  },
  {
    label: "Mount St. Mary's College California",
    value: "MSMCC",
    url: "http://www.msmc.la.edu/",
  },
  {
    label: "Mount St. Mary's College Maryland",
    value: "MSMCM",
    url: "http://www.msmary.edu/",
  },
  {
    label: "Mount Union College",
    value: "MUC",
    url: "http://www.mountunion.edu/",
  },
  {
    label: "Mount Vernon College",
    value: "MVC_1",
    url: "http://www.gwu.edu/~mvcgw/",
  },
  {
    label: "Mount Vernon Nazarene College",
    value: "MVNC",
    url: "http://www.mvnc.edu/",
  },
  {
    label: "Muhlenberg College",
    value: "MC_36",
    url: "http://www.muhlberg.edu/",
  },
  {
    label: "Multnomah Bible College",
    value: "MBC_5",
    url: "http://www.multnomah.edu/",
  },
  {
    label: "Murray State University",
    value: "MSU_14",
    url: "http://www.mursuky.edu/",
  },
  {
    label: "Muskingum College",
    value: "MC_37",
    url: "http://www.muskingum.edu/",
  },
  { label: "NAES College", value: "NC", url: "http://www.naes.indian.com/" },
  {
    label: "National American University",
    value: "NAU",
    url: "http://www.national.edu/",
  },
  {
    label: "National American University, Albuquerque",
    value: "NAUA",
    url: "http://www.national.edu/albuquerque.html",
  },
  {
    label: "National American University, Colorado Springs",
    value: "NAUCS",
    url: "http://www.national.edu/col_springs.html",
  },
  {
    label: "National American University, Denver",
    value: "NAUD",
    url: "http://www.national.edu/denver.html",
  },
  {
    label: "National American University, Kansas City",
    value: "NAUKC",
    url: "http://www.national.edu/kansas_city.html",
  },
  {
    label: "National American University, Roseville",
    value: "NAUR",
    url: "http://www.national.edu/roseville.html",
  },
  {
    label: "National American University, Sioux Falls",
    value: "NAUSF",
    url: "http://www.national.edu/sioux_falls.html",
  },
  {
    label: "National College of Chiropractic",
    value: "NCC",
    url: "http://www.national.chiropractic.edu/",
  },
  {
    label: "National College of Naturopathic Medicine",
    value: "NCNM",
    url: "http://www.ncnm.edu/",
  },
  {
    label: "National Defense University",
    value: "NDU",
    url: "http://www.ndu.edu/",
  },
  {
    label: "National Hispanic University",
    value: "NHU",
    url: "http://www.nhu.edu/",
  },
  {
    label: "National-Louis University",
    value: "NU",
    url: "http://www.nl.edu/",
  },
  {
    label: "National Technological University",
    value: "NTU",
    url: "http://www.ntu.edu/",
  },
  {
    label: "National Theatre Conservatory",
    value: "NTC",
    url: "http://www.denvercenter.org/education/ed_ntc.htm",
  },
  { label: "National University", value: "NU_1", url: "http://www.nu.edu/" },
  {
    label: "Naval Postgraduate School",
    value: "NPS",
    url: "http://www.nps.navy.mil/",
  },
  { label: "Nazarene Bible College", value: "NBC", url: "http://www.nbc.edu/" },
  { label: "Nazareth College", value: "NC_1", url: "http://www.naz.edu/" },
  {
    label: "Nebraska Christian College",
    value: "NCC_1",
    url: "http://www.nechristian.edu/",
  },
  {
    label: "Nebraska Methodist College of Nursing and Allied Health",
    value: "NMCNAH",
    url: "http://www.methodistcollege.edu/nurseover.html",
  },
  {
    label: "Nebraska Wesleyan University",
    value: "NWU",
    url: "http://www.nebrwesleyan.edu/",
  },
  { label: "Neumann College", value: "NC_2", url: "http://www.neumann.edu/" },
  { label: "Newberry College", value: "NC_3", url: "http://www.newberry.edu/" },
  {
    label: "New College of California",
    value: "NCC_2",
    url: "http://www.newcollege.edu/",
  },
  { label: "New College of Florida", value: "NCF", url: "http://www.ncf.edu/" },
  { label: "New England College", value: "NEC", url: "http://www.nec.edu/" },
  {
    label: "New England College of Optometry",
    value: "NECO",
    url: "http://www.ne-optometry.edu/",
  },
  {
    label: "New England Conservatory of Music",
    value: "NECM",
    url: "http://www.newenglandconservatory.edu/",
  },
  {
    label: "New England Institute of Technology",
    value: "NEIT",
    url: "http://www.neit.edu/",
  },
  {
    label: "New England School of Art and Design",
    value: "NESAD",
    url: "http://www.suffolk.edu/nesad/",
  },
  {
    label: "New England School of Communications",
    value: "NESC",
    url: "http://www.nescom.edu/",
  },
  {
    label: "New England School of Law",
    value: "NESL",
    url: "http://www.nesl.edu/",
  },
  { label: "New Hampshire College", value: "NHC", url: "http://www.nhc.edu/" },
  {
    label: "New Jersey City University",
    value: "NJCU",
    url: "http://www.njcu.edu/",
  },
  {
    label: "New Jersey Institute of Technology",
    value: "NJIT",
    url: "http://www.njit.edu/",
  },
  {
    label: "Newman University",
    value: "NU_2",
    url: "http://www.ksnewman.edu/",
  },
  {
    label: "New Mexico Highlands University",
    value: "NMHU",
    url: "http://www.nmhu.edu/",
  },
  {
    label: "New Mexico Institute of Mining and Technology",
    value: "NMIMT",
    url: "http://www.nmt.edu/",
  },
  {
    label: "New Mexico State University",
    value: "NMSU",
    url: "http://www.nmsu.edu/",
  },
  {
    label: "Newschool of Architecture and Design",
    value: "NAD",
    url: "http://www.newschoolarch.edu/",
  },
  {
    label: "New York Academy of Art, Graduate School of Figurative Art",
    value: "NYAAGSFA",
    url: "http://www.nyaa.edu/",
  },
  {
    label: "New York Chiropractic College",
    value: "NYCC",
    url: "http://www.nycc.edu/",
  },
  {
    label: "New York College of Podiatric Medicine",
    value: "NYCPM",
    url: "http://www.nycpm.edu/",
  },
  {
    label: "New York Film Academy",
    value: "NYFA",
    url: "http://www.nyfa.edu/",
  },
  {
    label: "New York Institute of Technology",
    value: "NYIT",
    url: "http://www.nyit.edu/",
  },
  { label: "New York Law School", value: "NYLS", url: "http://www.nyls.edu/" },
  {
    label: "New York Medical College",
    value: "NYMC",
    url: "http://www.nymc.edu/",
  },
  {
    label: "New York School of Interior Design",
    value: "NYSID",
    url: "http://www.nysid.edu/",
  },
  { label: "New York University", value: "NYU", url: "http://www.nyu.edu/" },
  {
    label: "Niagara University",
    value: "NU_3",
    url: "http://www.niagara.edu/",
  },
  {
    label: "Nicholls State University",
    value: "NSU",
    url: "http://www.nicholls.edu/",
  },
  { label: "Nichols College", value: "NC_4", url: "http://www.nichols.edu/" },
  {
    label: "Norfolk State University",
    value: "NSU_1",
    url: "http://www.nsu.edu/",
  },
  {
    label: "North Carolina Agricultural and Technical State University",
    value: "NCATSU",
    url: "http://www.ncat.edu/",
  },
  {
    label: "North Carolina Central University",
    value: "NCCU",
    url: "http://www.nccu.edu/",
  },
  {
    label: "North Carolina School of the Arts",
    value: "NCSTA",
    url: "http://www.ncarts.edu/",
  },
  {
    label: "North Carolina State University",
    value: "NCSU",
    url: "http://www.ncsu.edu/",
  },
  {
    label: "North Carolina Wesleyan College",
    value: "NCWC",
    url: "http://www.ncwc.edu/",
  },
  {
    label: "North Central Bible College",
    value: "NCBC",
    url: "http://www.ncbc.edu/",
  },
  {
    label: "North Central College",
    value: "NCC_3",
    url: "http://www.noctrl.edu/",
  },
  {
    label: "Northcentral University",
    value: "NU_4",
    url: "http://www.ncu.edu/",
  },
  {
    label: "North Dakota State University",
    value: "NDSU",
    url: "http://www.ndsu.nodak.edu/",
  },
  {
    label: "Northeastern Illinois University",
    value: "NIU",
    url: "http://www.neiu.edu/",
  },
  {
    label: "Northeastern Ohio University College of Medicine",
    value: "NOUCM",
    url: "http://www.neoucom.edu/",
  },
  {
    label: "Northeastern State University",
    value: "NSU_2",
    url: "http://www.nsuok.edu/",
  },
  {
    label: "Northeastern University",
    value: "NU_5",
    url: "http://www.neu.edu/",
  },
  {
    label: "Northern Arizona University",
    value: "NAU_1",
    url: "http://www.nau.edu/",
  },
  {
    label: "Northern Illinois University",
    value: "NIU_1",
    url: "http://www.niu.edu/",
  },
  {
    label: "Northern Kentucky University",
    value: "NKU",
    url: "http://www.nku.edu/",
  },
  {
    label: "Northern Michigan University",
    value: "NMU",
    url: "http://www.nmu.edu/",
  },
  {
    label: "Northern State University",
    value: "NSU_3",
    url: "http://www.northern.edu/",
  },
  {
    label: "Northern Virginia Community College",
    value: "NVCC",
    url: "http://www.nv.cc.va.us/",
  },
  {
    label: "Northface University",
    value: "NU_6",
    url: "http://www.northface.edu/",
  },
  {
    label: "North Georgia College",
    value: "NGC",
    url: "http://www.ngc.peachnet.edu/",
  },
  {
    label: "North Greenville College",
    value: "NGC_1",
    url: "http://www.ngc.edu/",
  },
  {
    label: "Northland College",
    value: "NC_5",
    url: "http://www.northland.edu/",
  },
  {
    label: "North Park University",
    value: "NPU",
    url: "http://www.northpark.edu/",
  },
  {
    label: "Northwest Christian College",
    value: "NCC_4",
    url: "http://www.nwcc.edu/",
  },
  {
    label: "Northwest College of Art",
    value: "NCA",
    url: "http://www.nca.edu/",
  },
  {
    label: "Northwestern College Iowa",
    value: "NCI",
    url: "http://www.nwciowa.edu/",
  },
  {
    label: "Northwestern College of Chiropractic",
    value: "NCC_5",
    url: "http://www.nwchiro.edu/",
  },
  {
    label: "Northwestern College St. Paul",
    value: "NCSP",
    url: "http://www.nwc.edu/",
  },
  {
    label: "Northwestern Oklahoma State University",
    value: "NOSU",
    url: "http://www.nwalva.edu/",
  },
  {
    label: "Northwestern State University of Louisiana",
    value: "NSUL",
    url: "http://www.nsula.edu/",
  },
  {
    label: "Northwestern University",
    value: "NU_7",
    url: "http://www.northwestern.edu/",
  },
  {
    label: "Northwest Missouri State University",
    value: "NMSU_1",
    url: "http://www.nwmissouri.edu/",
  },
  {
    label: "Northwest Nazarene University",
    value: "NNU",
    url: "http://www.nnu.edu/",
  },
  {
    label: "Northwest University",
    value: "NU_8",
    url: "http://www.northwestu.edu/",
  },
  {
    label: "Northwood University",
    value: "NU_9",
    url: "http://www.northwood.edu/",
  },
  {
    label: "Northwood University, Florida Campus",
    value: "NUFC",
    url: "http://www.northwood.edu/campuses/florida/",
  },
  {
    label: "Northwood University, Texas Campus",
    value: "NUTC",
    url: "http://www.northwood.edu/campuses/texas/",
  },
  {
    label: "Norwich University",
    value: "NU_10",
    url: "http://www.norwich.edu/",
  },
  {
    label: "Notre Dame College",
    value: "NDC",
    url: "http://www.notredame.edu/",
  },
  {
    label: "Notre Dame College of Ohio",
    value: "NDCO",
    url: "http://www.ndc.edu/",
  },
  {
    label: "Notre Dame de Namur University",
    value: "NDDNU",
    url: "http://www.ndnu.edu/",
  },
  {
    label: "Nova Southeastern University",
    value: "NSU_4",
    url: "http://www.nova.edu/",
  },
  {
    label: "Nyack College",
    value: "NC_6",
    url: "http://www.nyackcollege.edu/",
  },
  {
    label: "Oakland City University",
    value: "OCU",
    url: "http://www.oak.edu/",
  },
  { label: "Oakland University", value: "OU", url: "http://www.oakland.edu/" },
  {
    label: "Oakton Community College",
    value: "OCC",
    url: "http://www.oakton.edu/",
  },
  { label: "Oakwood College", value: "OC", url: "http://www.oakwood.edu/" },
  { label: "Oberlin College", value: "OC_1", url: "http://www.oberlin.edu/" },
  { label: "Occidental College", value: "OC_2", url: "http://www.oxy.edu/" },
  { label: "Oglala Lakota College", value: "OLC", url: "http://www.olc.edu/" },
  {
    label: "Oglethorpe University",
    value: "OU_1",
    url: "http://www.oglethorpe.edu/",
  },
  {
    label: "Ohio College of Podiatric Medicine",
    value: "OCPM",
    url: "http://www.ocpm.edu/",
  },
  { label: "Ohio Dominican College", value: "ODC", url: "http://www.odc.edu/" },
  {
    label: "Ohio Northern University",
    value: "ONU",
    url: "http://www.onu.edu/",
  },
  {
    label: "Ohio State University",
    value: "OSU",
    url: "http://www.ohio-state.edu/",
  },
  {
    label: "Ohio State University - Lima",
    value: "OSU-L",
    url: "http://www.lima.ohio-state.edu/",
  },
  {
    label: "Ohio State University - Mansfield",
    value: "OSU-M",
    url: "http://www.mansfield.ohio-state.edu/",
  },
  {
    label: "Ohio State University - Marion",
    value: "OSU-M_1",
    url: "http://www.marion.ohio-state.edu/",
  },
  {
    label: "Ohio State University - Newark",
    value: "OSU-N",
    url: "http://www.newark.ohio-state.edu/",
  },
  { label: "Ohio University", value: "OU_2", url: "http://www.ohiou.edu/" },
  {
    label: "Ohio University - Chillicothe",
    value: "OU-C",
    url: "http://www.ohiou.edu/chillicothe/",
  },
  {
    label: "Ohio University - Eastern",
    value: "OU-E",
    url: "http://www.eastern.ohiou.edu/",
  },
  {
    label: "Ohio University - Lancaster",
    value: "OU-L",
    url: "http://www.lancaster.ohiou.edu/",
  },
  {
    label: "Ohio University - Southern",
    value: "OU-S",
    url: "http://www.ohiou.edu/southern/",
  },
  {
    label: "Ohio University - Zanesville",
    value: "OU-Z",
    url: "http://www.zanesville.ohiou.edu/",
  },
  { label: "Ohio Valley College", value: "OVC", url: "http://www.ovc.edu/" },
  {
    label: "Ohio Wesleyan University",
    value: "OWU",
    url: "http://www.owu.edu/",
  },
  {
    label: "Oklahoma Baptist University",
    value: "OBU",
    url: "http://www.okbu.edu/",
  },
  {
    label: "Oklahoma Christian University",
    value: "OCU_1",
    url: "http://www.oc.edu/",
  },
  {
    label: "Oklahoma City University",
    value: "OCU_2",
    url: "http://www.okcu.edu/",
  },
  {
    label: "Oklahoma Panhandle State University",
    value: "OPSU",
    url: "http://www.opsu.edu/",
  },
  {
    label: "Oklahoma State University",
    value: "OSU_1",
    url: "http://www.okstate.edu/",
  },
  {
    label: "Oklahoma State University Center for Health Sciences",
    value: "OSUCFHS",
    url: "http://osu.com.okstate.edu/",
  },
  {
    label: "Oklahoma State University - Institute of Technology",
    value: "OSU-IT",
    url: "http://www.osuit.edu/",
  },
  {
    label: "Oklahoma State University - Oklahoma City",
    value: "OSU-OC",
    url: "http://www.osuokc.edu/",
  },
  {
    label: "Oklahoma State University - Tulsa",
    value: "OSU-T",
    url: "http://www.osu-tulsa.okstate.edu/",
  },
  {
    label: "Old Dominion University",
    value: "ODU",
    url: "http://www.odu.edu/",
  },
  {
    label: "Olive-Harvey College",
    value: "OC_3",
    url: "http://www.ccc.edu/oliveharvey/",
  },
  {
    label: "Olivet College",
    value: "OC_4",
    url: "http://www.olivetcollege.edu/",
  },
  {
    label: "Olivet Nazarene University",
    value: "ONU_1",
    url: "http://www.olivet.edu/",
  },
  {
    label: "O'More College of Design",
    value: "OCD",
    url: "http://www.omorecollege.edu/",
  },
  {
    label: "Oral Roberts University",
    value: "ORU",
    url: "http://www.oru.edu/",
  },
  {
    label: "Oregon College of Arts and Crafts",
    value: "OCAC",
    url: "http://www.ocac.edu/",
  },
  {
    label: "Oregon Graduate Institute of Science and Technology",
    value: "OGIST",
    url: "http://www.ogi.edu/",
  },
  {
    label: "Oregon Health Sciences University",
    value: "OHSU",
    url: "http://www.ohsu.edu/",
  },
  {
    label: "Oregon Institute of Technology",
    value: "OIT",
    url: "http://www.oit.edu/",
  },
  {
    label: "Oregon State University",
    value: "OSU_2",
    url: "http://www.orst.edu/",
  },
  {
    label: "Otis College of Art & Design",
    value: "OCAD",
    url: "http://www.otisart.edu/",
  },
  { label: "Ottawa University", value: "OU_3", url: "http://www.ottawa.edu/" },
  {
    label: "Otterbein College",
    value: "OC_5",
    url: "http://www.otterbein.edu/",
  },
  {
    label: "Ouachita Baptist University",
    value: "OBU_1",
    url: "http://www.obu.edu/",
  },
  {
    label: "Our Lady of Holy Cross College",
    value: "OLHCC",
    url: "http://www.olhcc.edu/",
  },
  {
    label: "Our Lady of the Lake University",
    value: "OLTLU",
    url: "http://www.ollusa.edu/",
  },
  {
    label: "Ozark Christian College",
    value: "OCC_1",
    url: "http://www.occ.edu/",
  },
  { label: "Pace University", value: "PU", url: "http://www.pace.edu/" },
  {
    label: "Pace University  Pleasantville/Briarcliff",
    value: "PUP",
    url: "http://www.pace.edu/campus/pville.html",
  },
  {
    label: "Pacifica Graduate Institute",
    value: "PGI",
    url: "http://www.pacifica.edu/",
  },
  {
    label: "Pacific College of Oriental Medicine",
    value: "PCOM",
    url: "http://www.ormed.edu/",
  },
  {
    label: "Pacific Graduate School of Psychology",
    value: "PGSP",
    url: "http://www.pgsp.edu/",
  },
  {
    label: "Pacific Lutheran University",
    value: "PLU",
    url: "http://www.plu.edu/",
  },
  {
    label: "Pacific Northwest College of Art",
    value: "PNCA",
    url: "http://www.pnca.edu/",
  },
  {
    label: "Pacific Oaks College",
    value: "POC",
    url: "http://www.pacificoaks.edu/",
  },
  { label: "Pacific Union College", value: "PUC", url: "http://www.puc.edu/" },
  {
    label: "Pacific University",
    value: "PU_1",
    url: "http://www.pacificu.edu/",
  },
  {
    label: "Paier College of Art",
    value: "PCA",
    url: "http://www.paierart.com/",
  },
  { label: "Paine College", value: "PC", url: "http://www.paine.edu/" },
  {
    label: "Palm Beach Atlantic University",
    value: "PBAU",
    url: "http://www.pba.edu/",
  },
  {
    label: "Palm Beach State College",
    value: "PBSC",
    url: "http://www.palmbeachstate.edu/",
  },
  {
    label: "Palmer College of Chiropractic",
    value: "PCC",
    url: "http://www.palmer.edu/",
  },
  {
    label: "Palmer College of Chiropractic West",
    value: "PCCW",
    url: "http://www.palmer.edu/PCCW/pcwmain.htm",
  },
  { label: "Park College", value: "PC_1", url: "http://www.park.edu/" },
  {
    label: "Parsons School of Design",
    value: "PSD",
    url: "http://www.parsons.edu/",
  },
  { label: "Paul Quinn College", value: "PQC", url: "http://www.pqc.edu/" },
  { label: "Peace College", value: "PC_2", url: "http://www.peace.edu/" },
  {
    label: "Pebble Hills University",
    value: "PHU",
    url: "http://www.pebblehills.edu/",
  },
  {
    label: "Pennsylvania Academy of the Fine Arts",
    value: "PATFA",
    url: "http://www.pafa.org/",
  },
  {
    label: "Pennsylvania College of Optometry",
    value: "PCO",
    url: "http://www.pco.edu/",
  },
  {
    label: "Pennsylvania Institute of Technology",
    value: "PIT",
    url: "http://www.pit.edu/",
  },
  {
    label: "Pennsylvania State University",
    value: "PSU",
    url: "http://www.psu.edu/",
  },
  {
    label: "Pennsylvania State University - Abington",
    value: "PSU-A",
    url: "http://www.abington.psu.edu/",
  },
  {
    label: "Pennsylvania State University - Altoona",
    value: "PSU-A_1",
    url: "http://www.aa.psu.edu/",
  },
  {
    label: "Pennsylvania State University at Erie - Behrend College",
    value: "PSUAE-BC",
    url: "http://www.pserie.psu.edu/",
  },
  {
    label: "Pennsylvania State University at Harrisburg - The Capital College",
    value: "PSUAH-TCC",
    url: "http://www.hbg.psu.edu/",
  },
  {
    label: "Pennsylvania State University - Berks-Lehigh Valley College",
    value: "PSU-BVC",
    url: "http://www.bk.psu.edu/",
  },
  {
    label: "Pennsylvania State University Delaware County",
    value: "PSUDC",
    url: "http://www.de.psu.edu/",
  },
  {
    label: "Pennsylvania State University Great Valley",
    value: "PSUGV",
    url: "http://www.gv.psu.edu/",
  },
  {
    label: "Pennsylvania State University - Lehigh Valley",
    value: "PSU-LV",
    url: "http://www.an.psu.edu/",
  },
  {
    label: "Pennsylvania State University - Milton S.Hershey Medical Center",
    value: "PSU-MSMC",
    url: "http://www.collmed.psu.edu/",
  },
  {
    label: "Pennsylvania State University - Schuylkill",
    value: "PSU-S",
    url: "http://www.sl.psu.edu/",
  },
  {
    label: "Pepperdine University",
    value: "PU_2",
    url: "http://www.pepperdine.edu/",
  },
  { label: "Peru State College", value: "PSC", url: "http://www.peru.edu/" },
  {
    label: "Pfeiffer University",
    value: "PU_3",
    url: "http://www.pfeiffer.edu/",
  },
  {
    label: "Philadelphia College of Bible",
    value: "PCB",
    url: "http://www.pcb.edu/",
  },
  {
    label: "Philadelphia College of Osteopathic Medicine",
    value: "PCOM_1",
    url: "http://www.pcom.edu/",
  },
  {
    label: "Philadelphia University",
    value: "PU_4",
    url: "http://www.philau.edu/",
  },
  {
    label: "Philander Smith College",
    value: "PSC_1",
    url: "http://www.philander.edu/",
  },
  {
    label: "Phillips Graduate Institute",
    value: "PGI_1",
    url: "http://www.pgi.edu/",
  },
  {
    label: "Phillips University",
    value: "PU_5",
    url: "http://www.phillips.edu/",
  },
  {
    label: "Piedmont Baptist College",
    value: "PBC",
    url: "http://www.pbc.edu/",
  },
  { label: "Piedmont College", value: "PC_3", url: "http://www.piedmont.edu/" },
  { label: "Pikeville College", value: "PC_4", url: "http://www.pc.edu/" },
  {
    label: "Pillsbury Baptist Bible College",
    value: "PBBC",
    url: "http://www.pillsbury.edu/",
  },
  {
    label: "Pittsburg State University",
    value: "PSU_1",
    url: "http://www.pittstate.edu/",
  },
  { label: "Pitzer College", value: "PC_5", url: "http://www.pitzer.edu/" },
  {
    label: "Plymouth State College",
    value: "PSC_2",
    url: "http://www.plymouth.edu/",
  },
  {
    label: "Point Loma Nazarene College",
    value: "PLNC",
    url: "http://www.ptloma.edu/",
  },
  { label: "Point Park College", value: "PPC", url: "http://www.ppc.edu/" },
  {
    label: "Polytechnic University",
    value: "PU_6",
    url: "http://www.poly.edu/",
  },
  {
    label: "Polytechnic University, Long Island Campus",
    value: "PULIC",
    url: "http://www.poly.edu/li/",
  },
  {
    label: "Polytechnic University, Westchester Graduate Center",
    value: "PUWGC",
    url: "http://www.poly.edu/west/",
  },
  { label: "Pomona College", value: "PC_6", url: "http://www.pomona.edu/" },
  {
    label: "Portland Community College",
    value: "PCC_1",
    url: "http://www.pcc.edu/",
  },
  {
    label: "Portland State University",
    value: "PSU_2",
    url: "http://www.pdx.edu/",
  },
  {
    label: "Post University of Waterbury",
    value: "PUW",
    url: "http://www.post.edu/",
  },
  {
    label: "Prairie View Agricultural and Mechanical University",
    value: "PVAMU",
    url: "http://www.pvamu.edu/",
  },
  { label: "Pratt Institute", value: "PI", url: "http://www.pratt.edu/" },
  {
    label: "Presbyterian College",
    value: "PC_7",
    url: "http://www.presby.edu/",
  },
  { label: "Prescott College", value: "PC_8", url: "http://www.prescott.edu/" },
  {
    label: "Preston University",
    value: "PU_7",
    url: "http://www.preston.edu/",
  },
  {
    label: "Princeton University",
    value: "PU_8",
    url: "http://www.princeton.edu/",
  },
  { label: "Principia College", value: "PC_9", url: "http://www.prin.edu/" },
  {
    label: "Providence College",
    value: "PC_10",
    url: "http://www.providence.edu/",
  },
  {
    label: "Puget Sound Christian College",
    value: "PSCC",
    url: "http://members.aa.net/~bluvase/pscchome.html",
  },
  { label: "Purdue University", value: "PU_9", url: "http://www.purdue.edu/" },
  {
    label: "Purdue University Calumet",
    value: "PUC_1",
    url: "http://www.calumet.purdue.edu/",
  },
  {
    label: "Purdue University North Central",
    value: "PUNC",
    url: "http://www.pnc.edu/",
  },
  {
    label: "Quantum-Veritas International University",
    value: "QIU",
    url: "http://www.qvius.edu/",
  },
  { label: "Queens College", value: "QC", url: "http://www.queens.edu/" },
  { label: "Quincy University", value: "QU", url: "http://www.quincy.edu/" },
  {
    label: "Quinnipiac College",
    value: "QC_1",
    url: "http://www.quinnipiac.edu/",
  },
  { label: "Radford University", value: "RU", url: "http://www.runet.edu/" },
  {
    label: "Ramapo College of New Jersey",
    value: "RCNJ",
    url: "http://www.ramapo.edu/",
  },
  {
    label: "Rand Graduate School of Policy Studies",
    value: "RGSPS",
    url: "http://www.rgs.edu/",
  },
  { label: "Randolph-Macon College", value: "RC", url: "http://www.rmc.edu/" },
  {
    label: "Randolph-Macon Woman's College",
    value: "RWC",
    url: "http://www.rmwc.edu/",
  },
  {
    label: "Rasmussen College",
    value: "RC_1",
    url: "http://www.rasmussen.edu/",
  },
  {
    label: "Rasmussen College, Florida Campuses",
    value: "RCFC",
    url: "http://www.rasmussen.edu/locations/florida/",
  },
  {
    label: "Rasmussen College, Illinois Campuses",
    value: "RCIC",
    url: "http://www.rasmussen.edu/locations/illinois/",
  },
  {
    label: "Rasmussen College, Minnesota Campuses",
    value: "RCMC",
    url: "http://www.rasmussen.edu/locations/minnesota/",
  },
  {
    label: "Rasmussen College, North Dakota Campuses",
    value: "RCNDC",
    url: "http://www.rasmussen.edu/locations/north-dakota/",
  },
  {
    label: "Rasmussen College, Wisconsin Campuses",
    value: "RCWC",
    url: "http://www.rasmussen.edu/locations/wisconsin/",
  },
  { label: "Reed College", value: "RC_2", url: "http://www.reed.edu/" },
  {
    label: "Reformed Bible College",
    value: "RBC",
    url: "http://www.reformed.edu/",
  },
  {
    label: "Regent International University",
    value: "RIU",
    url: "http://www.regentinternational.net/",
  },
  { label: "Regent University", value: "RU_1", url: "http://www.regent.edu/" },
  {
    label: "Regis College",
    value: "RC_3",
    url: "http://www.regiscollege.edu/",
  },
  { label: "Regis University", value: "RU_2", url: "http://www.regis.edu/" },
  {
    label: "Reinhardt College",
    value: "RC_4",
    url: "http://www.reinhardt.edu/",
  },
  {
    label: "Rensselaer Polytechnic Institute",
    value: "RPI",
    url: "http://www.rpi.edu/",
  },
  {
    label: "Research College of Nursing - Rockhurst University",
    value: "RCN-RU",
    url: "http://www.rockhurst.edu/3.0/academic_programs/nursing/admis3.html",
  },
  { label: "Rhode Island College", value: "RIC", url: "http://www.ric.edu/" },
  {
    label: "Rhode Island School of Design",
    value: "RISD",
    url: "http://www.risd.edu/",
  },
  { label: "Rhodes College", value: "RC_5", url: "http://www.rhodes.edu/" },
  { label: "Rice University", value: "RU_3", url: "http://www.rice.edu/" },
  {
    label: "Richard Stockton College of New Jersey",
    value: "RSCNJ",
    url: "http://www.stockton.edu/",
  },
  { label: "Rider University", value: "RU_4", url: "http://www.rider.edu/" },
  {
    label: "Ringling College of Art and Design",
    value: "RCAD",
    url: "http://www.ringling.edu/",
  },
  { label: "Ripon College", value: "RC_6", url: "http://www.ripon.edu/" },
  { label: "Rivier College", value: "RC_7", url: "http://www.rivier.edu/" },
  {
    label: "Roanoke Bible College",
    value: "RBC_1",
    url: "http://www.roanokebible.edu/",
  },
  { label: "Roanoke College", value: "RC_8", url: "http://www.roanoke.edu/" },
  {
    label: "Robert Morris College",
    value: "RMC",
    url: "http://www.robert-morris.edu/",
  },
  {
    label: "Robert Morris College of Chicago",
    value: "RMCC",
    url: "http://www.rmcil.edu/",
  },
  {
    label: "Roberts Wesleyan College",
    value: "RWC_1",
    url: "http://www.rwc.edu/",
  },
  { label: "Rochester College", value: "RC_9", url: "http://www.rc.edu/" },
  {
    label: "Rochester Institute of Technology",
    value: "RIT",
    url: "http://www.rit.edu/",
  },
  {
    label: "Rockford College",
    value: "RC_10",
    url: "http://www.rockford.edu/",
  },
  {
    label: "Rockhurst College",
    value: "RC_11",
    url: "http://www.rockhurst.edu/",
  },
  {
    label: "Rock Valley College",
    value: "RVC",
    url: "http://www.rvc.cc.il.us/",
  },
  {
    label: "Rocky Mountain College",
    value: "RMC_1",
    url: "http://www.rocky.edu/",
  },
  {
    label: "Rocky Mountain College of Art and Design",
    value: "RMCAD",
    url: "http://www.rmcad.edu/",
  },
  {
    label: "Rogers State University",
    value: "RSU",
    url: "http://www.rsu.edu/",
  },
  {
    label: "Roger Williams University",
    value: "RWU",
    url: "http://www.rwu.edu/",
  },
  { label: "Rollins College", value: "RC_12", url: "http://www.rollins.edu/" },
  {
    label: "Roosevelt University",
    value: "RU_5",
    url: "http://www.roosevelt.edu/",
  },
  {
    label: "Rose-Hulman Institute of Technology",
    value: "RIT_1",
    url: "http://www.rose-hulman.edu/",
  },
  {
    label: "Rosemont College",
    value: "RC_13",
    url: "http://www.rosemont.edu/",
  },
  {
    label: "Ross University, School of Medicine",
    value: "RUSM",
    url: "http://www.rossmed.edu/",
  },
  { label: "Rowan University", value: "RU_6", url: "http://www.rowan.edu/" },
  {
    label: "Rush University",
    value: "RU_7",
    url: "http://www.rushu.rush.edu/",
  },
  {
    label: "Russel Sage College",
    value: "RSC",
    url: "http://www.sage.edu/RSC/",
  },
  { label: "Rust College", value: "RC_14", url: "http://www.rustcollege.edu/" },
  {
    label: "Rutgers, The State University of New Jersey",
    value: "RTSUNJ",
    url: "http://www.rutgers.edu/",
  },
  {
    label: "Rutgers, The State University of New Jersey - Camden",
    value: "RTSUNJ-C",
    url: "http://camden-www.rutgers.edu/",
  },
  {
    label: "Rutgers, The State University of New Jersey - Newark",
    value: "RTSUNJ-N",
    url: "http://rutgers-newark.rutgers.edu/",
  },
  {
    label: "Sacred Heart University",
    value: "SHU",
    url: "http://www.sacredheart.edu/",
  },
  {
    label: "Sage Graduate School",
    value: "SGS",
    url: "http://www.sage.edu/SGS/",
  },
  {
    label: "Saginaw Valley State University",
    value: "SVSU",
    url: "http://www.svsu.edu/",
  },
  { label: "Salem College", value: "SC", url: "http://www.salem.edu/" },
  {
    label: "Salem International University",
    value: "SIU",
    url: "http://www.salemiu.edu/",
  },
  {
    label: "Salem State College",
    value: "SSC",
    url: "http://www.salem.mass.edu/",
  },
  {
    label: "Salem Teikyo University",
    value: "STU",
    url: "http://www.salem-teikyo.wvnet.edu/",
  },
  {
    label: "Salisbury State University",
    value: "SSU",
    url: "http://www.ssu.edu/",
  },
  {
    label: "Salve Regina University",
    value: "SRU",
    url: "http://www.salve.edu/",
  },
  { label: "Samford University", value: "SU", url: "http://www.samford.edu/" },
  {
    label: "Sam Houston State University",
    value: "SHSU",
    url: "http://www.shsu.edu/",
  },
  {
    label: "Samuel Merritt College",
    value: "SMC",
    url: "http://www.samuelmerritt.edu/",
  },
  {
    label: "San Diego State University",
    value: "SDSU",
    url: "http://www.sdsu.edu/",
  },
  {
    label: "San Diego University for Integrative Studies",
    value: "SDUFIS",
    url: "http://www.sduis.edu/",
  },
  {
    label: "Sanford-Brown Institute",
    value: "SI",
    url: "http://www.sbjacksonville.com/",
  },
  {
    label: "San Francisco Art Institute",
    value: "SFAI",
    url: "http://www.sfai.edu/",
  },
  {
    label: "San Francisco Conservatory of Music",
    value: "SFCM",
    url: "http://www.sfcm.edu/",
  },
  {
    label: "San Francisco State University",
    value: "SFSU",
    url: "http://www.sfsu.edu/",
  },
  {
    label: "San Joaquin College of Law",
    value: "SJCL",
    url: "http://www.sjcl.org/",
  },
  {
    label: "San Jose Christian College",
    value: "SJCC",
    url: "http://www.sjchristiancol.edu/",
  },
  {
    label: "San Jose State University",
    value: "SJSU",
    url: "http://www.sjsu.edu/",
  },
  { label: "Santa Clara University", value: "SCU", url: "http://www.scu.edu/" },
  { label: "Sarah Lawrence College", value: "SLC", url: "http://www.slc.edu/" },
  {
    label: "Savannah College of Art and Design",
    value: "SCAD",
    url: "http://www.scad.edu/",
  },
  {
    label: "Savannah State University",
    value: "SSU_1",
    url: "http://www.savstate.edu/",
  },
  {
    label: "Saybrook Institute",
    value: "SI_1",
    url: "http://www.saybrook.org/",
  },
  {
    label: "Schiller International University",
    value: "SIU_1",
    url: "http://www.schiller.edu/",
  },
  {
    label: "Scholl College of Podiatric Medicine",
    value: "SCPM",
    url: "http://www.scholl.edu/",
  },
  {
    label: "School for International Training",
    value: "SFIT",
    url: "http://www.sit.edu/",
  },
  {
    label: "School of the Museum of Fine Arts",
    value: "STMFA",
    url: "http://www.smfa.edu/",
  },
  {
    label: "School of the Visual Arts",
    value: "STVA",
    url: "http://www.schoolofvisualarts.edu/",
  },
  {
    label: "Schreiner College",
    value: "SC_1",
    url: "http://www.schreiner.edu/",
  },
  {
    label: "Scripps College",
    value: "SC_2",
    url: "http://www.scrippscol.edu/",
  },
  {
    label: "Seattle Pacific University",
    value: "SPU",
    url: "http://www.spu.edu/",
  },
  {
    label: "Seattle University",
    value: "SU_1",
    url: "http://www.seattleu.edu/",
  },
  {
    label: "Seton Hall University",
    value: "SHU_1",
    url: "http://www.shu.edu/",
  },
  {
    label: "Seton Hill College",
    value: "SHC",
    url: "http://www.setonhill.edu/",
  },
  {
    label: "Shawnee Community College",
    value: "SCC",
    url: "http://www.shawnee.cc.il.us/",
  },
  {
    label: "Shawnee State University",
    value: "SSU_2",
    url: "http://www.shawnee.edu/",
  },
  {
    label: "Shaw University",
    value: "SU_2",
    url: "http://www.shawuniversity.edu/",
  },
  {
    label: "Sheldon Jackson College",
    value: "SJC",
    url: "http://www.sheldonjackson.edu/",
  },
  { label: "Shenandoah University", value: "SU_3", url: "http://www.su.edu/" },
  { label: "Shepherd College", value: "SC_3", url: "http://www.shepherd.edu/" },
  {
    label: "Sherman College of Straight Chiropractic",
    value: "SCSC",
    url: "http://www.sherman.edu/",
  },
  { label: "Shimer College", value: "SC_4", url: "http://www.shimer.edu/" },
  {
    label: "Shippensburg University of Pennsylvania",
    value: "SUP",
    url: "http://www.ship.edu/",
  },
  {
    label: "Shoreline Community College",
    value: "SCC_1",
    url: "http://www.shoreline.edu/",
  },
  { label: "Shorter College", value: "SC_5", url: "http://www.shorter.edu/" },
  { label: "Siena College", value: "SC_6", url: "http://www.siena.edu/" },
  {
    label: "Siena Heights University",
    value: "SHU_2",
    url: "http://www.sienahts.edu/",
  },
  {
    label: "Sierra Nevada College",
    value: "SNC",
    url: "http://www.sierranevada.edu/",
  },
  { label: "Silver Lake College", value: "SLC_1", url: "http://www.sl.edu/" },
  { label: "Simmons College", value: "SC_7", url: "http://www.simmons.edu/" },
  {
    label: "Simon's Rock College",
    value: "SRC",
    url: "http://www.simons-rock.edu/",
  },
  { label: "Simpson College", value: "SC_8", url: "http://www.simpsonca.edu/" },
  {
    label: "Simpson College Iowa",
    value: "SCI",
    url: "http://www.simpson.edu/",
  },
  {
    label: "Sinte Gleska University",
    value: "SGU",
    url: "http://sinte.indian.com/",
  },
  { label: "Skadron College", value: "SC_9", url: "http://skadron.com/" },
  {
    label: "Skidmore College",
    value: "SC_10",
    url: "http://www.skidmore.edu/",
  },
  {
    label: "Slippery Rock University",
    value: "SRU_1",
    url: "http://www.sru.edu/",
  },
  { label: "Smith College", value: "SC_11", url: "http://www.smith.edu/" },
  {
    label: "Sojourner-Douglass College",
    value: "SC_12",
    url: "http://www.sdc.edu/",
  },
  {
    label: "Soka University of America",
    value: "SUA",
    url: "http://www.soka.edu",
  },
  {
    label: "Sonoma State University",
    value: "SSU_3",
    url: "http://www.sonoma.edu/",
  },
  {
    label: "South Carolina State University",
    value: "SCSU",
    url: "http://www.scsu.edu/",
  },
  {
    label: "South Dakota School of Mines and Technology",
    value: "SDSMT",
    url: "http://www.sdsmt.edu/",
  },
  {
    label: "South Dakota State University",
    value: "SDSU_1",
    url: "http://www.sdstate.edu/",
  },
  {
    label: "Southeastern Baptist College",
    value: "SBC",
    url: "http://www.southeasternbaptist.edu/",
  },
  {
    label: "Southeastern Bible College",
    value: "SBC_1",
    url: "http://www.sebc.edu/",
  },
  {
    label: "Southeastern College of the Assemblies of God",
    value: "SCTAG",
    url: "http://www.secollege.edu/",
  },
  {
    label: "Southeastern Louisiana University",
    value: "SLU",
    url: "http://www.southeastern.edu/",
  },
  {
    label: "Southeastern Oklahoma State University",
    value: "SOSU",
    url: "http://www.sosu.edu/",
  },
  {
    label: "Southeastern University",
    value: "SU_4",
    url: "http://www.seu.edu/",
  },
  {
    label: "Southeast Missouri State University",
    value: "SMSU",
    url: "http://www.semo.edu/",
  },
  {
    label: "Southern Adventist University",
    value: "SAU",
    url: "http://www.southern.edu/",
  },
  {
    label: "Southern Arkansas University",
    value: "SAU_1",
    url: "http://www.saumag.edu/",
  },
  {
    label: "Southern California College",
    value: "SCC_2",
    url: "http://www.sccu.edu/",
  },
  {
    label: "Southern California College of Optometry",
    value: "SCCO",
    url: "http://www.scco.edu/",
  },
  {
    label: "Southern California Institute of Architecture",
    value: "SCIA",
    url: "http://www.sciarc.edu/",
  },
  {
    label: "Southern College of Optometry",
    value: "SCO",
    url: "http://www.sco.edu/",
  },
  {
    label: "Southern Connecticut State University",
    value: "SCSU_1",
    url: "http://www.scsu.ctstateu.edu/",
  },
  {
    label: "Southern Illinois University at Carbondale",
    value: "SIUAC",
    url: "http://www.siu.edu/",
  },
  {
    label: "Southern Illinois University at Edwardsville",
    value: "SIUAE",
    url: "http://www.siue.edu/",
  },
  {
    label: "Southern Methodist University",
    value: "SMU",
    url: "http://www.smu.edu/",
  },
  {
    label: "Southern Nazarene University",
    value: "SNU",
    url: "http://www.snu.edu/",
  },
  {
    label: "Southern New Hampshire University",
    value: "SNHU",
    url: "http://www.snhu.edu/",
  },
  {
    label: "Southern Oregon University",
    value: "SOU",
    url: "http://www.sou.edu/",
  },
  {
    label: "Southern Polytechnic State Univerisity",
    value: "SPSU",
    url: "http://www.spsu.edu/",
  },
  {
    label: "Southern University - Baton Rouge",
    value: "SU-BR",
    url: "http://www.subr.edu/",
  },
  {
    label: "Southern University - New Orleans",
    value: "SU-NO",
    url: "http://www.suno.edu/",
  },
  {
    label: "Southern University - Shreveport",
    value: "SU-S",
    url: "http://www.susla.edu/",
  },
  {
    label: "Southern Utah University",
    value: "SUU",
    url: "http://www.suu.edu/",
  },
  {
    label: "Southern Vermont College",
    value: "SVC",
    url: "http://www.svc.edu/",
  },
  {
    label: "Southern Wesleyan University",
    value: "SWU",
    url: "http://www.swu.edu/",
  },
  {
    label: "South Florida Bible College & Theological Seminary",
    value: "SFBCTS",
    url: "http://www.sfbc.edu/",
  },
  {
    label: "South Texas College of Law",
    value: "STCL",
    url: "http://www.stcl.edu/",
  },
  {
    label: "Southwest Baptist University",
    value: "SBU",
    url: "http://www.sbuniv.edu/",
  },
  {
    label: "Southwestern Adventist University",
    value: "SAU_2",
    url: "http://www.swau.edu/",
  },
  {
    label: "Southwestern Assemblies of God University",
    value: "SAGU",
    url: "http://www.sagu.edu/",
  },
  {
    label: "Southwestern Baptist Theological Seminary",
    value: "SBTS",
    url: "http://www.swbts.edu/",
  },
  {
    label: "Southwestern Christian College",
    value: "SCC_3",
    url: "http://www.soulsociety.com/swcc.html",
  },
  {
    label: "Southwestern Christian University",
    value: "SCU_1",
    url: "http://www.swcu.edu/",
  },
  {
    label: "Southwestern College Kansas",
    value: "SCK",
    url: "http://www.sckans.edu/",
  },
  {
    label: "Southwestern College Santa Fe",
    value: "SCSF",
    url: "http://www.swc.edu/",
  },
  {
    label: "Southwestern Oklahoma State University",
    value: "SOSU_1",
    url: "http://www.swosu.edu/",
  },
  {
    label: "Southwestern University",
    value: "SU_5",
    url: "http://www.southwestern.edu/",
  },
  {
    label: "Southwestern University School of Law",
    value: "SUSL",
    url: "http://www.swlaw.edu/",
  },
  {
    label: "Southwest Missouri State University",
    value: "SMSU_1",
    url: "http://www.smsu.edu/",
  },
  {
    label: "Southwest Missouri State University - West Plains",
    value: "SMSU-WP",
    url: "http://www.wp.smsu.edu/",
  },
  {
    label: "Southwest State University",
    value: "SSU_4",
    url: "http://www.southwest.msus.edu/",
  },
  {
    label: "Southwest Texas State University",
    value: "STSU",
    url: "http://www.swt.edu/",
  },
  {
    label: "Southwest University",
    value: "SU_6",
    url: "http://www.southwest.edu/",
  },
  {
    label: "Spalding University",
    value: "SU_7",
    url: "http://www.spalding.edu/",
  },
  { label: "Spelman College", value: "SC_13", url: "http://www.spelman.edu/" },
  {
    label: "Spertus Institute of Jewish Studies",
    value: "SIJS",
    url: "http://www.spertus.edu/",
  },
  { label: "Spring Arbor College", value: "SAC", url: "http://www.arbor.edu/" },
  {
    label: "Springfield College",
    value: "SC_14",
    url: "http://www.spfldcol.edu/",
  },
  { label: "Spring Hill College", value: "SHC_1", url: "http://www.shc.edu/" },
  {
    label: "St. Ambrose University",
    value: "SAU_3",
    url: "http://www.sau.edu/",
  },
  {
    label: "Standford Online University",
    value: "SOU_1",
    url: "http://www.standford-university.cjb.net/",
  },
  {
    label: "Standford University",
    value: "SU_8",
    url: "http://standford-university.edu.tf/",
  },
  {
    label: "St. Andrews Presbyterian College",
    value: "SAPC",
    url: "http://www.sapc.edu/",
  },
  {
    label: "Stanford University",
    value: "SU_9",
    url: "http://www.stanford.edu/",
  },
  {
    label: "St. Anselm College",
    value: "SAC_1",
    url: "http://www.anselm.edu/",
  },
  {
    label: "St. Anthony College of Nursing",
    value: "SACN",
    url: "http://www.sacn.edu/",
  },
  {
    label: "State University of New York at Albany",
    value: "SUNYAA",
    url: "http://www.albany.edu/",
  },
  {
    label: "State University of New York at Binghamton",
    value: "SUNYAB",
    url: "http://www.binghamton.edu/",
  },
  {
    label: "State University of New York at Buffalo",
    value: "SUNYAB_1",
    url: "http://www.buffalo.edu/",
  },
  {
    label: "State University of New York at New Paltz",
    value: "SUNYANP",
    url: "http://www.newpaltz.edu/",
  },
  {
    label: "State University of New York at Oswego",
    value: "SUNYAO",
    url: "http://www.oswego.edu/",
  },
  {
    label: "State University of New York at Stony Brook",
    value: "SUNYASB",
    url: "http://www.sunysb.edu/",
  },
  {
    label: "State University of New York College at Brockport",
    value: "SUNYCAB",
    url: "http://www.brockport.edu/",
  },
  {
    label: "State University of New York College at Cortland",
    value: "SUNYCAC",
    url: "http://www.cortland.edu/",
  },
  {
    label: "State University of New York College at Fredonia",
    value: "SUNYCAF",
    url: "http://www.fredonia.edu/",
  },
  {
    label: "State University of New York College at Geneseo",
    value: "SUNYCAG",
    url: "http://www.geneseo.edu/",
  },
  {
    label: "State University of New York College at Old Westbury",
    value: "SUNYCAOW",
    url: "http://www.oldwestbury.edu/",
  },
  {
    label: "State University of New York College at Oneonta",
    value: "SUNYCAO",
    url: "http://www.oneonta.edu/",
  },
  {
    label: "State University of New York College at Plattsburgh",
    value: "SUNYCAP",
    url: "http://www.plattsburgh.edu/",
  },
  {
    label: "State University of New York College at Potsdam",
    value: "SUNYCAP_1",
    url: "http://www.potsdam.edu/",
  },
  {
    label: "State University of New York College at Purchase",
    value: "SUNYCAP_2",
    url: "http://www.purchase.edu/",
  },
  {
    label:
      "State University of New York College of Agriculture and Technology at Cobleskill",
    value: "SUNYCATAC",
    url: "http://www.cobleskill.edu/",
  },
  {
    label:
      "State University of New York College of Environmental Science and Forestry",
    value: "SUNYCESF",
    url: "http://www.esf.edu/",
  },
  {
    label: "State University of New York College of Optometry",
    value: "SUNYCO",
    url: "http://www.sunyopt.edu/",
  },
  {
    label: "State University of New York College of Technology at Alfred",
    value: "SUNYCTAA",
    url: "http://www.alfredtech.edu/",
  },
  {
    label: "State University of New York College of Technology at Farmingdale",
    value: "SUNYCTAF",
    url: "http://www.farmingdale.edu/",
  },
  {
    label: "State University of New York Downstate Medical Center",
    value: "SUNYDMC",
    url: "http://www.hscbklyn.edu/",
  },
  {
    label: "State University of New York Empire State College",
    value: "SUNYESC",
    url: "http://www.esc.edu/",
  },
  {
    label: "State University of New York Health Sience Centre Syracuse",
    value: "SUNYHSCS",
    url: "http://www.hscsyr.edu/",
  },
  {
    label: "State University of New York Institute of Technology at Utica/Rome",
    value: "SUNYITAU",
    url: "http://www.sunyit.edu/",
  },
  {
    label: "State University of New York Maritime College",
    value: "SUNYMC",
    url: "http://www.sunymaritime.edu/",
  },
  {
    label:
      "State University of New York School of Engineering and Applied Sciences",
    value: "SUNYSEAS",
    url: "http://www.eng.buffalo.edu/",
  },
  {
    label: "State University of New York (SUNY)",
    value: "SUNY(",
    url: "http://www.sunycentral.edu/",
  },
  {
    label: "State University of New York Upstate Medical University",
    value: "SUNYUMU",
    url: "http://www.upstate.edu/",
  },
  {
    label: "State University of West Georgia",
    value: "SUWG",
    url: "http://www.westga.edu/",
  },
  {
    label: "St. Augustine's College North Carolina",
    value: "SACNC",
    url: "http://www.st-aug.edu/",
  },
  {
    label: "St. Bernard's Institute",
    value: "SBI",
    url: "http://www.sbi.edu/",
  },
  {
    label: "St. Bonaventure University",
    value: "SBU_1",
    url: "http://www.sbu.edu/",
  },
  {
    label: "St. Cloud State University",
    value: "SCSU_2",
    url: "http://www.stcloudstate.edu/",
  },
  {
    label: "St. Edwards University",
    value: "SEU",
    url: "http://www.stedwards.edu/",
  },
  {
    label: "Stefan University",
    value: "SU_10",
    url: "http://www.stefan-university.edu/",
  },
  {
    label: "Stephen F. Austin State University",
    value: "SFASU",
    url: "http://www.sfasu.edu/",
  },
  {
    label: "Stephens College",
    value: "SC_15",
    url: "http://www.stephens.edu/",
  },
  {
    label: "Sterling College",
    value: "SC_16",
    url: "http://www.sterling.edu/",
  },
  {
    label: "Stetson University",
    value: "SU_11",
    url: "http://www.stetson.edu/",
  },
  {
    label: "Stevens Institute of Technology",
    value: "SIT",
    url: "http://www.stevens-tech.edu/",
  },
  {
    label: "St. Francis College, Brooklyn Heights",
    value: "SFCBH",
    url: "http://www.stfranciscollege.edu/",
  },
  {
    label: "St. Francis College, Fort Wayne",
    value: "SFCFW",
    url: "http://www.sfc.edu/",
  },
  {
    label: "St. Francis College, Loretto",
    value: "SFCL",
    url: "http://www.sfcpa.edu/",
  },
  {
    label: "St. Francis Medical Center College of Nursing",
    value: "SFMCCN",
    url: "http://www.osfsaintfrancis.org/",
  },
  {
    label: "St. George's University",
    value: "SGU_1",
    url: "http://www.sgu.edu/",
  },
  {
    label: "Stillman College",
    value: "SC_17",
    url: "http://www.stillman.edu/",
  },
  {
    label: "St. John Fisher College",
    value: "SJFC",
    url: "http://www.sjfc.edu/",
  },
  {
    label: "St. John's College Maryland",
    value: "SJCM",
    url: "http://www.sjca.edu/",
  },
  {
    label: "St. John's College New Mexico",
    value: "SJCNM",
    url: "http://www.sjcsf.edu/",
  },
  {
    label: "St. John's Seminary",
    value: "SJS",
    url: "http://www.stjohnsem.edu/",
  },
  {
    label: "St. John's University",
    value: "SJU",
    url: "http://www.stjohns.edu/",
  },
  { label: "St. Joseph College", value: "SJC_1", url: "http://www.sjc.edu/" },
  {
    label: "St. Joseph College of Nursing",
    value: "SJCN",
    url: "http://www.stfrancis.edu/sjcn/sjcnhome.htm",
  },
  {
    label: "St. Joseph's College",
    value: "SJC_2",
    url: "http://www.saintjoe.edu/",
  },
  {
    label: "St. Joseph's College New York",
    value: "SJCNY",
    url: "http://www.sjcny.edu/",
  },
  {
    label: "St. Joseph's College New York, Suffolk Campus",
    value: "SJCNYSC",
    url: "http://www.sjcny.edu/patchogue/",
  },
  {
    label: "St. Joseph's College of Maine",
    value: "SJCM_1",
    url: "http://www.sjcme.edu/",
  },
  {
    label: "St. Joseph's University",
    value: "SJU_1",
    url: "http://www.sju.edu/",
  },
  {
    label: "St. Lawrence University",
    value: "SLU_1",
    url: "http://www.stlawu.edu/",
  },
  { label: "St. Leo College", value: "SLC_2", url: "http://www.saintleo.edu/" },
  {
    label: "St. Louis Christian College",
    value: "SLCC",
    url: "http://www.slcc4ministry.edu/",
  },
  {
    label: "St. Louis College of Pharmacy",
    value: "SLCP",
    url: "http://www.stlcop.edu/",
  },
  { label: "St. Louis University", value: "SLU_2", url: "http://www.slu.edu/" },
  {
    label: "St. Luke's College",
    value: "SLC_3",
    url: "http://www.saint-lukes.org/about/slc/",
  },
  {
    label: "St. Martin's College",
    value: "SMC_1",
    url: "http://www.stmartin.edu/",
  },
  { label: "St. Mary College", value: "SMC_2", url: "http://www.smcks.edu/" },
  {
    label: "St. Mary-of-the-Woods College",
    value: "SMC_3",
    url: "http://www.smwc.edu/",
  },
  {
    label: "St. Mary's College Indiana",
    value: "SMCI",
    url: "http://www.saintmarys.edu/",
  },
  {
    label: "St. Mary's College of California",
    value: "SMCC",
    url: "http://www.stmarys-ca.edu/",
  },
  {
    label: "St. Mary's College of Maryland",
    value: "SMCM",
    url: "http://www.smcm.edu/",
  },
  {
    label: "St. Mary's University of Minnesota",
    value: "SMUM",
    url: "http://www.smumn.edu/",
  },
  {
    label: "St. Mary's University of San Antonio",
    value: "SMUSA",
    url: "http://www.stmarytx.edu/",
  },
  {
    label: "St. Meinrad College",
    value: "SMC_4",
    url: "http://www.saintmeinrad.edu/",
  },
  {
    label: "St. Michael's College",
    value: "SMC_5",
    url: "http://www.smcvt.edu/",
  },
  { label: "St. Norbert College", value: "SNC_1", url: "http://www.snc.edu/" },
  { label: "St. Olaf College", value: "SOC", url: "http://www.stolaf.edu/" },
  {
    label: "Stonehill College",
    value: "SC_18",
    url: "http://www.stonehill.edu/",
  },
  {
    label: "St. Paul's College",
    value: "SPC",
    url: "http://www.saintpauls.edu/",
  },
  {
    label: "St. Petersburg College",
    value: "SPC_1",
    url: "http://www.spcollege.edu/",
  },
  { label: "St. Peter's College", value: "SPC_2", url: "http://www.spc.edu/" },
  {
    label: "Strayer University",
    value: "SU_12",
    url: "http://www.strayer.edu/",
  },
  {
    label: "St. Thomas Aquinas College",
    value: "STAC",
    url: "http://www.stac.edu/",
  },
  {
    label: "St. Thomas University",
    value: "STU_1",
    url: "http://www.stu.edu/",
  },
  {
    label: "St. Vincent College",
    value: "SVC_1",
    url: "http://www.stvincent.edu/",
  },
  { label: "St. Xavier University", value: "SXU", url: "http://www.sxu.edu/" },
  {
    label: "Suffolk University",
    value: "SU_13",
    url: "http://www.suffolk.edu/",
  },
  {
    label: "Sullivan College",
    value: "SC_19",
    url: "http://www.sullivan.edu/",
  },
  {
    label: "Sul Ross State University",
    value: "SRSU",
    url: "http://www.sulross.edu/",
  },
  {
    label: "Susquehanna University",
    value: "SU_14",
    url: "http://www.susqu.edu/",
  },
  {
    label: "Swarthmore College",
    value: "SC_20",
    url: "http://www.swarthmore.edu/",
  },
  { label: "Sweet Briar College", value: "SBC_2", url: "http://www.sbc.edu/" },
  { label: "Syracuse University", value: "SU_15", url: "http://www.syr.edu/" },
  { label: "Tabor College", value: "TC", url: "http://www.tabor.edu/" },
  {
    label: "Talladega College",
    value: "TC_1",
    url: "http://www.talladega.edu/",
  },
  {
    label: "Tarleton State University",
    value: "TSU",
    url: "http://www.tarleton.edu/",
  },
  { label: "Taylor University", value: "TU", url: "http://www.tayloru.edu/" },
  {
    label: "Taylor University, Fort Wayne Campus",
    value: "TUFWC",
    url: "http://www.tayloru.edu/fw/",
  },
  {
    label: "Teachers College, Columbia University",
    value: "TCCU",
    url: "http://www.tc.columbia.edu/",
  },
  { label: "Temple University", value: "TU_1", url: "http://www.temple.edu/" },
  {
    label: "Temple University School of Podiatric Medicine",
    value: "TUSPM",
    url: "http://www.pcpm.edu/",
  },
  {
    label: "Tennessee State University",
    value: "TSU_1",
    url: "http://www.tnstate.edu/",
  },
  {
    label: "Tennessee Technological University",
    value: "TTU",
    url: "http://www.tntech.edu/",
  },
  {
    label: "Tennessee Temple University",
    value: "TTU_1",
    url: "http://www.tntemple.edu/",
  },
  {
    label: "Tennessee Wesleyan College",
    value: "TWC",
    url: "http://www.twcnet.edu/",
  },
  {
    label: "Texas A&M International University",
    value: "TAIU",
    url: "http://www.tamiu.edu/",
  },
  { label: "Texas A&M University", value: "TAU", url: "http://www.tamu.edu/" },
  {
    label: "Texas A&M University - Commerce",
    value: "TAU-C",
    url: "http://www.tamu-commerce.edu/",
  },
  {
    label: "Texas A&M University - Corpus Christi",
    value: "TAU-CC",
    url: "http://www.tamucc.edu/",
  },
  {
    label: "Texas A&M University - Galveston",
    value: "TAU-G",
    url: "http://www.tamug.tamu.edu/",
  },
  {
    label: "Texas A&M University - Kingsville",
    value: "TAU-K",
    url: "http://www.tamuk.edu/",
  },
  {
    label: "Texas A&M University - Texarkana",
    value: "TAU-T",
    url: "http://www.tamut.edu/",
  },
  {
    label: "Texas Chiropractic College",
    value: "TCC",
    url: "http://www.txchiro.edu/",
  },
  {
    label: "Texas Christian University",
    value: "TCU",
    url: "http://www.tcu.edu/",
  },
  {
    label: "Texas College",
    value: "TC_2",
    url: "http://www.texascollege.edu/",
  },
  {
    label: "Texas College of Osteopathic Medicine",
    value: "TCOM",
    url: "http://www.hsc.unt.edu/education/tcom/",
  },
  {
    label: "Texas Lutheran University",
    value: "TLU",
    url: "http://www.txlutheran.edu/",
  },
  {
    label: "Texas Southern University",
    value: "TSU_2",
    url: "http://www.tsu.edu/",
  },
  {
    label: "Texas Tech University",
    value: "TTU_2",
    url: "http://www.ttu.edu/",
  },
  {
    label: "Texas Tech University Health Science Center",
    value: "TTUHSC",
    url: "http://www.ttuhsc.edu/",
  },
  {
    label: "Texas Wesleyan University",
    value: "TWU",
    url: "http://www.txwesleyan.edu/",
  },
  {
    label: "Texas Woman's University",
    value: "TWU_1",
    url: "http://www.twu.edu/",
  },
  {
    label: "The American College",
    value: "TAC",
    url: "http://www.amercoll.edu/",
  },
  {
    label: "The Art Institute of Boston",
    value: "TAIB",
    url: "http://www.aiboston.edu/",
  },
  {
    label: "The Art Institutes International Portland",
    value: "TAIIP",
    url: "http://www.aipd.aii.edu/",
  },
  {
    label: "The Art Institutes International San Francisco",
    value: "TAIISF",
    url: "http://www.aisf.aii.edu/",
  },
  {
    label: "The Boston Conservatory",
    value: "TBC",
    url: "http://www.bostonconservatory.edu/",
  },
  {
    label: "The Catholic University of America",
    value: "TCUA",
    url: "http://www.cua.edu/",
  },
  {
    label: "The Chicago School of Professional Psychology",
    value: "TCSPP",
    url: "http://www.thechicagoschool.edu/",
  },
  {
    label: "The College of Insurance",
    value: "TCI",
    url: "http://www.tci.edu/",
  },
  {
    label: "The College of New Jersey",
    value: "TCNJ",
    url: "http://www.tcnj.edu/",
  },
  {
    label: "The College of Santa Fe",
    value: "TCSF",
    url: "http://www.csf.edu/",
  },
  {
    label: "The College of St. Scholastica",
    value: "TCSS",
    url: "http://www.css.edu/",
  },
  {
    label: "The College of Westchester",
    value: "TCW",
    url: "http://www.cw.edu/",
  },
  {
    label: "The College of Wooster",
    value: "TCW_1",
    url: "http://www.wooster.edu/",
  },
  {
    label: "The Cooper Union for the Advancement of Science and Art",
    value: "TCUFTASA",
    url: "http://www.cooper.edu/",
  },
  {
    label: "The Corcoran College of Art",
    value: "TCCA",
    url: "http://www.corcoran.edu/college/",
  },
  {
    label: "The Curtis Institute of Music",
    value: "TCIM",
    url: "http://www.curtis.edu/",
  },
  {
    label: "The Defiance College",
    value: "TDC",
    url: "http://www.defiance.edu/",
  },
  {
    label: "The Dickinson School of Law",
    value: "TDSL",
    url: "http://www.dsl.edu/",
  },
  {
    label: "The Illinois Institute of Art-Chicago",
    value: "TIIA",
    url: "http://www.ilic.artinstitutes.edu/",
  },
  {
    label: "The Johns Hopkins University",
    value: "TJHU",
    url: "http://www.jhu.edu/",
  },
  {
    label: "The Juilliard School",
    value: "TJS",
    url: "http://www.juilliard.edu/",
  },
  {
    label: "The Leadership Institute of Seattle",
    value: "TLIS",
    url: "http://www.lios.org/",
  },
  {
    label: "The Maryland Institute, College of Art",
    value: "TMICA",
    url: "http://www.mica.edu/",
  },
  {
    label: "The Master's College",
    value: "TMC",
    url: "http://www.masters.edu/",
  },
  {
    label: "The McGregor School of Antioch University",
    value: "TMSAU",
    url: "http://www.mcgregor.edu/",
  },
  {
    label: "The Naropa Institute",
    value: "TNI",
    url: "http://www.naropa.edu/",
  },
  { label: "The New School", value: "TNS", url: "http://www.newschool.edu/" },
  {
    label: "The Rockefeller University",
    value: "TRU",
    url: "http://www.rockefeller.edu/",
  },
  {
    label: "The School of the Art Institute of Chicago",
    value: "TSTAIC",
    url: "http://www.artic.edu/",
  },
  {
    label: "The Scripps Research Institute",
    value: "TSRI",
    url: "http://www.scripps.edu/",
  },
  {
    label: "The Southern Christian University",
    value: "TSCU",
    url: "http://www.southernchristian.edu/",
  },
  {
    label: "The Tulane University of New Orleans",
    value: "TTUNO",
    url: "http://www.tulane.edu/",
  },
  { label: "The Union Institute", value: "TUI", url: "http://www.tui.edu/" },
  { label: "Thiel College", value: "TC_3", url: "http://www.thiel.edu/" },
  {
    label: "Thomas A. Edison State College",
    value: "TAESC",
    url: "http://www.tesc.edu/",
  },
  {
    label: "Thomas Aquinas College",
    value: "TAC_1",
    url: "http://www.thomasaquinas.edu/",
  },
  {
    label: "Thomas College Maine",
    value: "TCM",
    url: "http://www.thomas.edu/",
  },
  {
    label: "Thomas Jefferson University",
    value: "TJU",
    url: "http://www.tju.edu/",
  },
  {
    label: "Thomas More College",
    value: "TMC_1",
    url: "http://www.thomasmore.edu/",
  },
  {
    label: "Thomas More College of Liberal Arts",
    value: "TMCLA",
    url: "http://www.thomasmorecollege.edu/",
  },
  { label: "Thomas University", value: "TU_2", url: "http://www.thomasu.edu/" },
  {
    label: "Thunderbird School of Global Management",
    value: "TSGM",
    url: "http://www.thunderbird.edu/",
  },
  { label: "Tiffin University", value: "TU_3", url: "http://www.tiffin.edu/" },
  {
    label: "Toccoa Falls College",
    value: "TFC",
    url: "http://www.toccoafalls.edu/",
  },
  { label: "Tomball College", value: "TC_4", url: "http://wwwtc.nhmccd.edu/" },
  { label: "Tougaloo College", value: "TC_5", url: "http://www.tougaloo.edu/" },
  { label: "Touro College", value: "TC_6", url: "http://www.touro.edu/" },
  { label: "Touro University", value: "TU_4", url: "http://www.tu.edu/" },
  { label: "Towson University", value: "TU_5", url: "http://www.towson.edu/" },
  {
    label: "Transylvania University",
    value: "TU_6",
    url: "http://www.transy.edu/",
  },
  {
    label: "Trevecca Nazarene University",
    value: "TNU",
    url: "http://www.trevecca.edu/",
  },
  {
    label: "Tri-College University",
    value: "TU_7",
    url: "http://www.ndsu.nodak.edu/tricollege/",
  },
  {
    label: "Trident University",
    value: "TU_8",
    url: "http://www.trident.edu/",
  },
  {
    label: "Trinity Bible College",
    value: "TBC_1",
    url: "http://www.tbc2day.edu/",
  },
  {
    label: "Trinity Christian College",
    value: "TCC_1",
    url: "http://www.trnty.edu/",
  },
  {
    label: "Trinity College Connecticut",
    value: "TCC_2",
    url: "http://www.trincoll.edu/",
  },
  {
    label: "Trinity College of Florida",
    value: "TCF",
    url: "http://www.trinitycollege.edu/",
  },
  {
    label: "Trinity College of Vermont",
    value: "TCV",
    url: "http://www.trinityvt.edu/",
  },
  {
    label: "Trinity International University",
    value: "TIU",
    url: "http://www.trin.edu/",
  },
  {
    label: "Trinity International University (Excel), Miami",
    value: "TIU(M",
    url: "http://www.tiu.edu/excel/index.html",
  },
  {
    label: "Trinity University",
    value: "TU_9",
    url: "http://www.trinitydc.edu/",
  },
  {
    label: "Trinity University",
    value: "TU_10",
    url: "http://www.trinity.edu/",
  },
  {
    label: "Tri-State University",
    value: "TU_11",
    url: "http://www.tristate.edu/",
  },
  {
    label: "Triton College",
    value: "TC_7",
    url: "http://www.triton.cc.il.us/",
  },
  { label: "Troy University", value: "TU_12", url: "http://www.troy.edu/" },
  {
    label: "Troy University, Dothan",
    value: "TUD",
    url: "http://dothan.troy.edu/",
  },
  {
    label: "Troy University, Montgomery",
    value: "TUM",
    url: "http://montgomery.troy.edu/",
  },
  {
    label: "Troy University, Phenix City",
    value: "TUPC",
    url: "http://phenix.troy.edu/",
  },
  {
    label: "Troy University, Troy",
    value: "TUT",
    url: "http://troy.troy.edu/",
  },
  {
    label: "Truman College",
    value: "TC_8",
    url: "http://www.trumancollege.net/",
  },
  {
    label: "Truman State University",
    value: "TSU_3",
    url: "http://www.truman.edu/",
  },
  { label: "Tufts University", value: "TU_13", url: "http://www.tufts.edu/" },
  { label: "Tui Online University", value: "TOU", url: "http://www.tuiu.edu/" },
  { label: "Tusculum College", value: "TC_9", url: "http://www.tusculum.edu/" },
  { label: "Tuskegee University", value: "TU_14", url: "http://www.tusk.edu/" },
  {
    label: "Uniformed Services Universty of the Health Sciences",
    value: "USUTHS",
    url: "http://www.usuhs.mil/",
  },
  { label: "Union College", value: "UC", url: "http://www.union.edu/" },
  {
    label: "Union College Kentucky",
    value: "UCK",
    url: "http://www.unionky.edu/",
  },
  {
    label: "Union College Nebraska",
    value: "UCN",
    url: "http://www.ucollege.edu/",
  },
  {
    label: "Union Theological Seminary (UTS)",
    value: "UTS(",
    url: "http://www.union-psce.edu/",
  },
  { label: "Union University", value: "UU", url: "http://www.uu.edu/" },
  {
    label: "United States Air Force Academy",
    value: "USAFA",
    url: "http://www.usafa.af.mil/",
  },
  {
    label: "United States Coast Guard Academy",
    value: "USCGA",
    url: "http://www.cga.edu/",
  },
  {
    label: "United States International University",
    value: "USIU",
    url: "http://www.usiu.edu/",
  },
  {
    label: "United States Merchant Marine Academy",
    value: "USMMA",
    url: "http://www.usmma.edu/",
  },
  {
    label: "United States Military Academy",
    value: "USMA",
    url: "http://www.usma.edu/",
  },
  {
    label: "United States Naval Academy",
    value: "USNA",
    url: "http://www.usna.edu/",
  },
  {
    label: "United States Sports Academy",
    value: "USSA",
    url: "http://www.sport.ussa.edu/",
  },
  { label: "Unity College", value: "UC_1", url: "http://www.unity.edu/" },
  {
    label: "University of Advancing Technology (UAT)",
    value: "UAT(",
    url: "http://www.uat.edu/",
  },
  { label: "University of Akron", value: "UA", url: "http://www.uakron.edu/" },
  {
    label: "University of Alabama - Birmingham",
    value: "UA-B",
    url: "http://www.uab.edu/",
  },
  {
    label: "University of Alabama - Huntsville",
    value: "UA-H",
    url: "http://www.uah.edu/",
  },
  {
    label: "University of Alabama - Tuscaloosa",
    value: "UA-T",
    url: "http://www.ua.edu/",
  },
  { label: "University of Alanta", value: "UA_1", url: "http://www.uofa.edu/" },
  {
    label: "University of Alaska - Anchorage",
    value: "UA-A",
    url: "http://www.uaa.alaska.edu/",
  },
  {
    label: "University of Alaska - Fairbanks",
    value: "UA-F",
    url: "http://www.uaf.edu/",
  },
  {
    label: "University of Alaska - Southeast",
    value: "UA-S",
    url: "http://www.uas.alaska.edu/",
  },
  {
    label: "University of Alaska (System)",
    value: "UA(",
    url: "http://www.alaska.edu/",
  },
  {
    label: "University of Arizona",
    value: "UA_2",
    url: "http://www.arizona.edu/",
  },
  {
    label: "University of Arkansas at Fayetteville",
    value: "UAAF",
    url: "http://www.uark.edu/",
  },
  {
    label: "University of Arkansas at Little Rock",
    value: "UAALR",
    url: "http://www.ualr.edu/",
  },
  {
    label: "University of Arkansas at Monticello",
    value: "UAAM",
    url: "http://www.uamont.edu/",
  },
  {
    label: "University of Arkansas at Pine Bluff",
    value: "UAAPB",
    url: "http://www.uapb.edu/",
  },
  {
    label: "University of Arkansas for Medical Sciences",
    value: "UAFMS",
    url: "http://www.uams.edu/",
  },
  {
    label: "University of Arkansas (System)",
    value: "UA(_1",
    url: "http://www.uasys.edu/",
  },
  {
    label: "University of Baltimore",
    value: "UB",
    url: "http://www.ubalt.edu/",
  },
  {
    label: "University of Bridgeport",
    value: "UB_1",
    url: "http://www.bridgeport.edu/",
  },
  {
    label: "University of California, Berkeley",
    value: "UCB",
    url: "http://www.berkeley.edu/",
  },
  {
    label: "University of California, Davis",
    value: "UCD",
    url: "http://www.ucdavis.edu/",
  },
  {
    label: "University of California, Hastings College of Law",
    value: "UCHCL",
    url: "http://www.uchastings.edu/",
  },
  {
    label: "University of California, Irvine",
    value: "UCI",
    url: "http://www.uci.edu/",
  },
  {
    label: "University of California, Los Angeles",
    value: "UCLA",
    url: "http://www.ucla.edu/",
  },
  {
    label: "University of California, Merced",
    value: "UCM",
    url: "http://www.ucmerced.edu/",
  },
  {
    label: "University of California, Oakland",
    value: "UCO",
    url: "http://www.ucop.edu/",
  },
  {
    label: "University of California, Riverside",
    value: "UCR",
    url: "http://www.ucr.edu/",
  },
  {
    label: "University of California, San Diego",
    value: "UCSD",
    url: "http://www.ucsd.edu/",
  },
  {
    label: "University of California, San Francisco",
    value: "UCSF",
    url: "http://www.ucsf.edu/",
  },
  {
    label: "University of California, Santa Barbara",
    value: "UCSB",
    url: "http://www.ucsb.edu/",
  },
  {
    label: "University of California, Santa Cruz",
    value: "UCSC",
    url: "http://www.ucsc.edu/",
  },
  {
    label: "University of California System",
    value: "UCS",
    url: "http://www.universityofcalifornia.edu/",
  },
  {
    label: "University of Central Arkansas",
    value: "UCA",
    url: "http://www.uca.edu/",
  },
  {
    label: "University of Central Florida",
    value: "UCF",
    url: "http://www.ucf.edu/",
  },
  {
    label: "University of Central Missouri",
    value: "UCM_1",
    url: "http://www.ucmo.edu/",
  },
  {
    label: "University of Central Oklahoma",
    value: "UCO_1",
    url: "http://www.ucok.edu/",
  },
  {
    label: "University of Central Texas",
    value: "UCT",
    url: "http://www.vvm.com/uct/",
  },
  {
    label: "University of Charleston",
    value: "UC_2",
    url: "http://www.uchaswv.edu/",
  },
  {
    label: "University of Charleston South Carolina",
    value: "UCSC_1",
    url: "http://univchas.cofc.edu/",
  },
  {
    label: "University of Chicago",
    value: "UC_3",
    url: "http://www.uchicago.edu/",
  },
  {
    label: "University of Cincinnati",
    value: "UC_4",
    url: "http://www.uc.edu/",
  },
  {
    label: "University of Colorado at Boulder",
    value: "UCAB",
    url: "http://www.colorado.edu/",
  },
  {
    label: "University of Colorado at Colorado Springs",
    value: "UCACS",
    url: "http://www.uccs.edu/",
  },
  {
    label: "University of Colorado at Denver",
    value: "UCAD",
    url: "http://www.cudenver.edu/",
  },
  {
    label: "University of Colorado Health Sciences Center",
    value: "UCHSC",
    url: "http://www.uchsc.edu/",
  },
  {
    label: "University of Connecticut",
    value: "UC_5",
    url: "http://www.uconn.edu/",
  },
  {
    label: "University of Connecticut at Avery Point",
    value: "UCAAP",
    url: "http://www.averypoint.uconn.edu/",
  },
  {
    label: "University of Connecticut at Hartford",
    value: "UCAH",
    url: "http://vm.uconn.edu/~wwwhtfd/ugrad/",
  },
  {
    label: "University of Connecticut at Stamford",
    value: "UCAS",
    url: "http://www.stamford.uconn.edu/",
  },
  {
    label: "University of Connecticut at Waterbury",
    value: "UCAW",
    url: "http://www.waterbury.uconn.edu/",
  },
  {
    label: "University of Connecticut Health Center",
    value: "UCHC",
    url: "http://www.uchc.edu/",
  },
  {
    label: "University of Dallas",
    value: "UD",
    url: "http://www.udallas.edu/",
  },
  {
    label: "University of Dayton",
    value: "UD_1",
    url: "http://www.udayton.edu/",
  },
  {
    label: "University of Delaware",
    value: "UD_2",
    url: "http://www.udel.edu/",
  },
  { label: "University of Denver", value: "UD_3", url: "http://www.du.edu/" },
  {
    label: "University of Detroit Mercy",
    value: "UDM",
    url: "http://www.udmercy.edu/",
  },
  { label: "University of Dubuque", value: "UD_4", url: "http://www.dbq.edu/" },
  {
    label: "University of Evansville",
    value: "UE",
    url: "http://www.evansville.edu/",
  },
  {
    label: "University of Findlay",
    value: "UF",
    url: "http://www.findlay.edu/",
  },
  { label: "University of Florida", value: "UF_1", url: "http://www.ufl.edu/" },
  { label: "University of Georgia", value: "UG", url: "http://www.uga.edu/" },
  {
    label: "University of Great Falls",
    value: "UGF",
    url: "http://www.ugf.edu/",
  },
  {
    label: "University of Hartford",
    value: "UH",
    url: "http://www.hartford.edu/",
  },
  {
    label: "University of Hawaii - Hilo",
    value: "UH-H",
    url: "http://www.uhh.hawaii.edu/",
  },
  {
    label: "University of Hawaii - Manoa",
    value: "UH-M",
    url: "http://www.uhm.hawaii.edu/",
  },
  {
    label: "University Of Hawaii - System",
    value: "UH-S",
    url: "http://www.hawaii.edu/",
  },
  {
    label: "University of Hawaii - West Oahu",
    value: "UH-WO",
    url: "http://www.uhwo.hawaii.edu/",
  },
  {
    label: "University of Health Sciences",
    value: "UHS",
    url: "http://www.uhs.edu/",
  },
  { label: "University of Houston", value: "UH_1", url: "http://www.uh.edu/" },
  {
    label: "University of Houston, Clear Lake",
    value: "UHCL",
    url: "http://www.cl.uh.edu/",
  },
  {
    label: "University of Houston, Downtown",
    value: "UHD",
    url: "http://www.dt.uh.edu/",
  },
  {
    label: "University of Houston, Victoria",
    value: "UHV",
    url: "http://www.vic.uh.edu/",
  },
  { label: "University of Idaho", value: "UI", url: "http://www.uidaho.edu/" },
  {
    label: "University of Illinois",
    value: "UI_1",
    url: "http://www.uillinois.edu/",
  },
  {
    label: "University of Illinois at Chicago",
    value: "UIAC",
    url: "http://www.uic.edu/",
  },
  {
    label: "University of Illinois at Springfield",
    value: "UIAS",
    url: "http://www.uis.edu/",
  },
  {
    label: "University of Illinois at Urbana-Champaign",
    value: "UIAU",
    url: "http://www.uiuc.edu/",
  },
  {
    label: "University of Indianapolis",
    value: "UI_2",
    url: "http://www.uindy.edu/",
  },
  { label: "University of Iowa", value: "UI_3", url: "http://www.uiowa.edu/" },
  { label: "University of Kansas", value: "UK", url: "http://www.ku.edu/" },
  {
    label: "University of Kentucky",
    value: "UK_1",
    url: "http://www.uky.edu/",
  },
  {
    label: "University of La Verne",
    value: "ULV",
    url: "http://www.ulaverne.edu/",
  },
  {
    label: "University of Louisiana at Lafayette",
    value: "ULAL",
    url: "http://www.louisiana.edu/",
  },
  {
    label: "University of Louisiana at Monroe",
    value: "ULAM",
    url: "http://www.ulm.edu/",
  },
  {
    label: "University of Louisville",
    value: "UL",
    url: "http://www.louisville.edu/",
  },
  {
    label: "University of Maine, Augusta",
    value: "UMA",
    url: "http://www.uma.maine.edu/",
  },
  {
    label: "University of Maine, Farmington",
    value: "UMF",
    url: "http://www.umf.maine.edu/",
  },
  {
    label: "University of Maine, Fort Kent",
    value: "UMFK",
    url: "http://www.umfk.maine.edu/",
  },
  {
    label: "University of Maine, Machias",
    value: "UMM",
    url: "http://www.umm.maine.edu/",
  },
  {
    label: "University of Maine, Orono",
    value: "UMO",
    url: "http://www.umaine.edu/",
  },
  {
    label: "University of Maine, Presque Isle",
    value: "UMPI",
    url: "http://www.umpi.maine.edu/",
  },
  {
    label: "University of Maine (System)",
    value: "UM(",
    url: "http://www.maine.edu/",
  },
  {
    label: "University of Management & Technology",
    value: "UMT",
    url: "http://www.umtweb.edu/",
  },
  { label: "University of Mary", value: "UM", url: "http://www.umary.edu/" },
  {
    label: "University of Mary Hardin-Baylor",
    value: "UMH",
    url: "http://www.umhb.edu/",
  },
  {
    label: "University of Maryland at Baltimore",
    value: "UMAB",
    url: "http://www.umbc.edu/",
  },
  {
    label: "University of Maryland at College Park",
    value: "UMACP",
    url: "http://www.umd.edu/",
  },
  {
    label: "University of Maryland Baltimore County",
    value: "UMBC",
    url: "http://www.umbc.edu/",
  },
  {
    label: "University of Maryland Eastern Shore",
    value: "UMES",
    url: "http://www.umes.edu/",
  },
  {
    label: "University of Maryland Medicine",
    value: "UMM_1",
    url: "http://www.umm.edu/",
  },
  {
    label: "University of Maryland (System)",
    value: "UM(_1",
    url: "http://www.ums.edu/",
  },
  {
    label: "University of Maryland University College",
    value: "UMUC",
    url: "http://www.umuc.edu/",
  },
  {
    label: "University of Massachusetts at Amherst",
    value: "UMAA",
    url: "http://www.umass.edu/",
  },
  {
    label: "University of Massachusetts at Boston",
    value: "UMAB_1",
    url: "http://www.umb.edu/",
  },
  {
    label: "University of Massachusetts at Dartmouth",
    value: "UMAD",
    url: "http://www.umassd.edu/",
  },
  {
    label: "University of Massachusetts at Lowell",
    value: "UMAL",
    url: "http://www.uml.edu/",
  },
  {
    label: "University of Massachusetts Medical Center at Worcester",
    value: "UMMCAW",
    url: "http://www.ummed.edu/",
  },
  {
    label: "University of Massachusetts (System)",
    value: "UM(_2",
    url: "http://www.massachusetts.edu/",
  },
  {
    label: "University of Medicine and Dentistry of New Jersey",
    value: "UMDNJ",
    url: "http://www.umdnj.edu/",
  },
  {
    label: "University of Memphis",
    value: "UM_1",
    url: "http://www.memphis.edu/",
  },
  { label: "University of Miami", value: "UM_2", url: "http://www.miami.edu/" },
  {
    label: "University of Michigan - Ann Arbor",
    value: "UM-AA",
    url: "http://www.umich.edu/",
  },
  {
    label: "University of Michigan - Dearborn",
    value: "UM-D",
    url: "http://www.umd.umich.edu/",
  },
  {
    label: "University of Michigan - Flint",
    value: "UM-F",
    url: "http://www.flint.umich.edu/",
  },
  {
    label: "University of Minnesota - Crookston",
    value: "UM-C",
    url: "http://www.crk.umn.edu/",
  },
  {
    label: "University of Minnesota - Duluth",
    value: "UM-D_1",
    url: "http://www.d.umn.edu/",
  },
  {
    label: "University of Minnesota - Morris",
    value: "UM-M",
    url: "http://www.mrs.umn.edu/",
  },
  {
    label: "University of Minnesota - Twin Cities Campus",
    value: "UM-TCC",
    url: "http://www1.umn.edu/twincities/",
  },
  {
    label: "University of Mississippi",
    value: "UM_3",
    url: "http://www.olemiss.edu/",
  },
  {
    label: "University of Mississippi Medical Center",
    value: "UMMC",
    url: "http://www.umc.edu/",
  },
  {
    label: "University of Missouri - Columbia",
    value: "UM-C_1",
    url: "http://www.missouri.edu/",
  },
  {
    label: "University of Missouri - Kansas City",
    value: "UM-KC",
    url: "http://www.umkc.edu/",
  },
  {
    label: "University of Missouri - Saint Louis",
    value: "UM-SL",
    url: "http://www.umsl.edu/",
  },
  {
    label: "University of Mobile",
    value: "UM_4",
    url: "http://www.umobile.edu/",
  },
  { label: "University of Montana", value: "UM_5", url: "http://www.umt.edu/" },
  {
    label: "University of Montana Western",
    value: "UMW",
    url: "http://www.umwestern.edu/",
  },
  {
    label: "University of Montevallo",
    value: "UM_6",
    url: "http://www.montevallo.edu/",
  },
  {
    label: "University of Nebraska - Kearney",
    value: "UN-K",
    url: "http://www.unk.edu/",
  },
  {
    label: "University of Nebraska - Lincoln",
    value: "UN-L",
    url: "http://www.unl.edu/",
  },
  {
    label: "University of Nebraska Medical Center",
    value: "UNMC",
    url: "http://www.unmc.edu/",
  },
  {
    label: "University of Nebraska - Omaha",
    value: "UN-O",
    url: "http://www.unomaha.edu/",
  },
  {
    label: "University of Nebraska (System)",
    value: "UN(",
    url: "http://www.nebraska.edu/",
  },
  {
    label: "University of Nevada - Las Vegas",
    value: "UN-LV",
    url: "http://www.unlv.edu/",
  },
  {
    label: "University of Nevada - Reno",
    value: "UN-R",
    url: "http://www.unr.edu/",
  },
  {
    label: "University of New England",
    value: "UNE",
    url: "http://www.une.edu/",
  },
  {
    label: "University of New England, Westbrook College Campus",
    value: "UNEWCC",
    url: "http://www.une.edu/wcdirctn.html",
  },
  {
    label: "University of New Hampshire",
    value: "UNH",
    url: "http://www.unh.edu/",
  },
  {
    label: "University of New Hampshire - Manchester",
    value: "UNH-M",
    url: "http://www.unh.edu/unhm/",
  },
  {
    label: "University of New Haven",
    value: "UNH_1",
    url: "http://www.newhaven.edu/",
  },
  {
    label: "University of New Mexico",
    value: "UNM",
    url: "http://www.unm.edu/",
  },
  {
    label: "University of New Orleans",
    value: "UNO",
    url: "http://www.uno.edu/",
  },
  {
    label: "University of North Alabama",
    value: "UNA",
    url: "http://www.una.edu/",
  },
  {
    label: "University of North America",
    value: "UNA_1",
    url: "http://www.universityofnorthamerica.org/",
  },
  {
    label: "University of North Carolina at Asheville",
    value: "UNCAA",
    url: "http://www.unca.edu/",
  },
  {
    label: "University of North Carolina at Chapel Hill",
    value: "UNCACH",
    url: "http://www.unc.edu/",
  },
  {
    label: "University of North Carolina at Charlotte",
    value: "UNCAC",
    url: "http://www.uncc.edu/",
  },
  {
    label: "University of North Carolina at Greensboro",
    value: "UNCAG",
    url: "http://www.uncg.edu/",
  },
  {
    label: "University of North Carolina at Pembroke",
    value: "UNCAP",
    url: "http://www.uncp.edu/",
  },
  {
    label: "University of North Carolina at Wilmington",
    value: "UNCAW",
    url: "http://www.uncwil.edu/",
  },
  {
    label: "University of North Dakota",
    value: "UND",
    url: "http://www.und.nodak.edu/",
  },
  {
    label: "University of Northern Colorado",
    value: "UNC",
    url: "http://www.univnorthco.edu/",
  },
  {
    label: "University of Northern Iowa",
    value: "UNI",
    url: "http://www.uni.edu/",
  },
  {
    label: "University of Northern Virginia",
    value: "UNV",
    url: "http://www.unva.edu/",
  },
  {
    label: "University of Northern Washington",
    value: "UNW",
    url: "http://www.unw.edu/",
  },
  {
    label: "University of North Florida",
    value: "UNF",
    url: "http://www.unf.edu/",
  },
  {
    label: "University of North Texas",
    value: "UNT",
    url: "http://www.unt.edu/",
  },
  {
    label: "University of North Texas Health Science Center at Fort Worth",
    value: "UNTHSCAFW",
    url: "http://www.hsc.unt.edu/",
  },
  { label: "University of NorthWest", value: "UN", url: "http://www.unw.ac/" },
  {
    label: "University of Notre Dame",
    value: "UND_1",
    url: "http://www.nd.edu/",
  },
  { label: "University of Oklahoma", value: "UO", url: "http://www.ou.edu/" },
  {
    label: "University of Oklahoma Health Sciences Center",
    value: "UOHSC",
    url: "http://www.ouhsc.edu/",
  },
  {
    label: "University of Oregon",
    value: "UO_1",
    url: "http://www.uoregon.edu/",
  },
  {
    label: "University of Osteopathic Medicine and Health Science",
    value: "UOMHS",
    url: "http://www.uomhs.edu/",
  },
  {
    label: "University of Pennsylvania",
    value: "UP",
    url: "http://www.upenn.edu/",
  },
  {
    label: "University of Phoenix",
    value: "UP_1",
    url: "http://www.phoenix.edu/",
  },
  {
    label: "University of Pittsburgh",
    value: "UP_2",
    url: "http://www.pitt.edu/",
  },
  {
    label: "University of Pittsburgh at Bradford",
    value: "UPAB",
    url: "http://www.upb.pitt.edu/",
  },
  {
    label: "University of Pittsburgh at Greensburg",
    value: "UPAG",
    url: "http://www.pitt.edu/~upg/",
  },
  {
    label: "University of Pittsburgh at Johnstown",
    value: "UPAJ",
    url: "http://www.pitt.edu/~upjweb/",
  },
  {
    label: "University of Portland",
    value: "UP_3",
    url: "http://www.uofport.edu/",
  },
  {
    label: "University of Puget Sound",
    value: "UPS",
    url: "http://www.ups.edu/",
  },
  {
    label: "University of Redlands",
    value: "UR",
    url: "http://www.redlands.edu/",
  },
  {
    label: "University of Rhode Island",
    value: "URI",
    url: "http://www.uri.edu/",
  },
  {
    label: "University of Richmond",
    value: "UR_1",
    url: "http://www.urich.edu/",
  },
  {
    label: "University of Rio Grande",
    value: "URG",
    url: "http://www.urgrgcc.edu/",
  },
  {
    label: "University of Rochester",
    value: "UR_2",
    url: "http://www.rochester.edu/",
  },
  {
    label: "University of San Diego",
    value: "USD",
    url: "http://www.sandiego.edu/",
  },
  {
    label: "University of San Francisco",
    value: "USF",
    url: "http://www.usfca.edu/",
  },
  {
    label: "University of Science and Arts of Oklahoma",
    value: "USAO",
    url: "http://www.usao.edu/",
  },
  { label: "University of Scranton", value: "US", url: "http://www.uofs.edu/" },
  {
    label: "University of Sioux Falls",
    value: "USF_1",
    url: "http://www.thecoo.edu/",
  },
  {
    label: "University of South Alabama",
    value: "USA",
    url: "http://www.usouthal.edu/",
  },
  {
    label: "University of South Carolina",
    value: "USC",
    url: "http://www.sc.edu/",
  },
  {
    label: "University of South Carolina - Aiken",
    value: "USC-A",
    url: "http://www.usca.sc.edu/",
  },
  {
    label: "University of South Carolina - Beaufort",
    value: "USC-B",
    url: "http://www.sc.edu/beaufort/",
  },
  {
    label: "University of South Carolina - Lancaster",
    value: "USC-L",
    url: "http://www.sc.edu/lancaster/",
  },
  {
    label: "University of South Carolina - Salkehatchie",
    value: "USC-S",
    url: "http://www.rcce.sc.edu/salkehatchie/",
  },
  {
    label: "University of South Carolina - Spartanburg",
    value: "USC-S_1",
    url: "http://www.uscs.edu/",
  },
  {
    label: "University of South Carolina - Sumter",
    value: "USC-S_2",
    url: "http://www.uscsumter.edu/",
  },
  {
    label: "University of South Carolina - Union",
    value: "USC-U",
    url: "http://www.sc.edu/union/",
  },
  {
    label: "University of South Dakota",
    value: "USD_1",
    url: "http://www.usd.edu/",
  },
  {
    label: "University of Southern California",
    value: "USC_1",
    url: "http://www.usc.edu/",
  },
  {
    label: "University of Southern Indiana",
    value: "USI",
    url: "http://www.usi.edu/",
  },
  {
    label: "University of Southern Maine",
    value: "USM",
    url: "http://www.usm.maine.edu/",
  },
  {
    label: "University of Southern Mississippi",
    value: "USM_1",
    url: "http://www.usm.edu/",
  },
  {
    label: "University of South Florida",
    value: "USF_2",
    url: "http://www.usf.edu/",
  },
  {
    label: "University of St. Francis",
    value: "USF_3",
    url: "http://www.stfrancis.edu/",
  },
  {
    label: "University of St. Thomas, Houston",
    value: "USTH",
    url: "http://www.stthom.edu/",
  },
  {
    label: "University of St. Thomas, St. Paul",
    value: "USTSP",
    url: "http://www.stthomas.edu/",
  },
  { label: "University of Tampa", value: "UT", url: "http://www.utampa.edu/" },
  {
    label: "University of Tennessee - Chattanooga",
    value: "UT-C",
    url: "http://www.utc.edu/",
  },
  {
    label: "University of Tennessee - Knoxville",
    value: "UT-K",
    url: "http://www.utk.edu/",
  },
  {
    label: "University of Tennessee - Martin",
    value: "UT-M",
    url: "http://www.utm.edu/",
  },
  {
    label: "University of Tennessee - Memphis",
    value: "UT-M_1",
    url: "http://www.utmem.edu/",
  },
  {
    label: "University of Tennessee Space Institute",
    value: "UTSI",
    url: "http://www.utsi.edu/",
  },
  {
    label: "University of Texas",
    value: "UT_1",
    url: "http://www.utsystem.edu/",
  },
  {
    label: "University of Texas at Arlington",
    value: "UTAA",
    url: "http://www.uta.edu/",
  },
  {
    label: "University of Texas at Austin",
    value: "UTAA_1",
    url: "http://www.utexas.edu/",
  },
  {
    label: "University of Texas at Brownsville",
    value: "UTAB",
    url: "http://www.utb.edu/",
  },
  {
    label: "University of Texas at Dallas",
    value: "UTAD",
    url: "http://www.utdallas.edu/",
  },
  {
    label: "University of Texas at El Paso",
    value: "UTAEP",
    url: "http://www.utep.edu/",
  },
  {
    label: "University of Texas at San Antonio",
    value: "UTASA",
    url: "http://www.utsa.edu/",
  },
  {
    label: "University of Texas at Tyler",
    value: "UTAT",
    url: "http://www.uttyl.edu/",
  },
  {
    label: "University of Texas Health Center at Houston",
    value: "UTHCAH",
    url: "http://www.uthouston.edu/",
  },
  {
    label: "University of Texas Health Center at Tyler",
    value: "UTHCAT",
    url: "http://www.uthct.edu/",
  },
  {
    label: "University of Texas Health Science Center at San Antonio",
    value: "UTHSCASA",
    url: "http://www.uthscsa.edu/",
  },
  {
    label: "University of Texas M.D. Anderson Cancer Center",
    value: "UTMACC",
    url: "http://www.mdanderson.org/",
  },
  {
    label: "University of Texas Medical Branch Galveston",
    value: "UTMBG",
    url: "http://www.utmb.edu/",
  },
  {
    label: "University of Texas of the Permian Basin",
    value: "UTTPB",
    url: "http://www.utpb.edu/",
  },
  {
    label: "University of Texas Pan American",
    value: "UTPA",
    url: "http://www.panam.edu/",
  },
  {
    label: "University of Texas Southwestern Medical Center at Dallas",
    value: "UTSMCAD",
    url: "http://www.utsouthwestern.edu/",
  },
  {
    label: "University of the Arts",
    value: "UTA",
    url: "http://www.uarts.edu/",
  },
  {
    label: "University of the District of Columbia",
    value: "UTDC",
    url: "http://www.udc.edu/",
  },
  {
    label: "University of the Incarnate World",
    value: "UTIW",
    url: "http://www.uiw.edu/",
  },
  {
    label: "University of the Ozarks",
    value: "UTO",
    url: "http://www.ozarks.edu/",
  },
  {
    label: "University of the Pacific",
    value: "UTP",
    url: "http://www.pacific.edu/",
  },
  {
    label: "University of the Sciences in Philadelphia",
    value: "UTSIP",
    url: "http://www.usip.edu/",
  },
  {
    label: "University of the South",
    value: "UTS",
    url: "http://www.sewanee.edu/",
  },
  {
    label: "University of the Southwest",
    value: "UTS_1",
    url: "http://www.usw.edu/",
  },
  {
    label: "University of Toledo",
    value: "UT_2",
    url: "http://www.utoledo.edu/",
  },
  {
    label: "University of Tulsa",
    value: "UT_3",
    url: "http://www.utulsa.edu/",
  },
  { label: "University of Utah", value: "UU_1", url: "http://www.utah.edu/" },
  { label: "University of Vermont", value: "UV", url: "http://www.uvm.edu/" },
  {
    label: "University of Virginia",
    value: "UV_1",
    url: "http://www.virginia.edu/",
  },
  {
    label: "University of Virginia, College at Wise",
    value: "UVCAW",
    url: "http://www.wise.virginia.edu/",
  },
  {
    label: "University of Washington",
    value: "UW",
    url: "http://www.washington.edu/",
  },
  {
    label: "University of Washington, Tacoma",
    value: "UWT",
    url: "http://www.tacoma.washington.edu/",
  },
  {
    label: "University of West Alabama",
    value: "UWA",
    url: "http://www.uwa.edu/",
  },
  {
    label: "University of West Florida",
    value: "UWF",
    url: "http://www.uwf.edu/",
  },
  {
    label: "University of West Los Angeles",
    value: "UWLA",
    url: "http://www.uwla.edu/",
  },
  {
    label: "University of Wisconsin - Eau Claire",
    value: "UW-EC",
    url: "http://www.uwec.edu/",
  },
  {
    label: "University of Wisconsin - Green Bay",
    value: "UW-GB",
    url: "http://www.uwgb.edu/",
  },
  {
    label: "University of Wisconsin - La Crosse",
    value: "UW-LC",
    url: "http://www.uwlax.edu/",
  },
  {
    label: "University of Wisconsin - Madison",
    value: "UW-M",
    url: "http://www.wisc.edu/",
  },
  {
    label: "University of Wisconsin - Milwaukee",
    value: "UW-M_1",
    url: "http://www.uwm.edu/",
  },
  {
    label: "University of Wisconsin - Oshkosh",
    value: "UW-O",
    url: "http://www.uwosh.edu/",
  },
  {
    label: "University of Wisconsin - Parkside",
    value: "UW-P",
    url: "http://www.uwp.edu/",
  },
  {
    label: "University of Wisconsin - Platteville",
    value: "UW-P_1",
    url: "http://www.uwplatt.edu/",
  },
  {
    label: "University of Wisconsin - River Falls",
    value: "UW-RF",
    url: "http://www.uwrf.edu/",
  },
  {
    label: "University of Wisconsin - Stevens Point",
    value: "UW-SP",
    url: "http://www.uwsp.edu/",
  },
  {
    label: "University of Wisconsin - Stout",
    value: "UW-S",
    url: "http://www.uwstout.edu/",
  },
  {
    label: "University of Wisconsin - Superior",
    value: "UW-S_1",
    url: "http://www.uwsuper.edu/",
  },
  {
    label: "University of Wisconsin - Whitewater",
    value: "UW-W",
    url: "http://www.uww.edu/",
  },
  {
    label: "University of Wyoming",
    value: "UW_1",
    url: "http://www.uwyo.edu/",
  },
  { label: "Upper Iowa University", value: "UIU", url: "http://www.uiu.edu/" },
  { label: "Urbana University", value: "UU_2", url: "http://www.urbana.edu/" },
  { label: "Ursinus College", value: "UC_6", url: "http://www.ursinus.edu/" },
  { label: "Ursuline College", value: "UC_7", url: "http://www.ursuline.edu/" },
  { label: "Utah State University", value: "USU", url: "http://www.usu.edu/" },
  {
    label: "Utah Valley State College",
    value: "UVSC",
    url: "http://www.uvsc.edu/",
  },
  { label: "Utica College", value: "UC_8", url: "http://www.ucsu.edu/" },
  {
    label: "Valdosta State University",
    value: "VSU",
    url: "http://www.valdosta.edu/",
  },
  {
    label: "Valley City State University",
    value: "VCSU",
    url: "http://www.vcsu.nodak.edu/",
  },
  {
    label: "Valley Forge Christian College",
    value: "VFCC",
    url: "http://www.vfcc.edu/",
  },
  { label: "Valparaiso University", value: "VU", url: "http://www.valpo.edu/" },
  {
    label: "Vanderbilt University",
    value: "VU_1",
    url: "http://www.vanderbilt.edu/",
  },
  {
    label: "VanderCook College of Music",
    value: "VCM",
    url: "http://www.vandercook.edu/",
  },
  {
    label: "Vanguard University of Southern California",
    value: "VUSC",
    url: "http://www.vanguard.edu/",
  },
  { label: "Vassar College", value: "VC", url: "http://www.vassar.edu/" },
  { label: "Vennard College", value: "VC_1", url: "http://www.vennard.edu/" },
  {
    label: "Vermont Law School",
    value: "VLS",
    url: "http://www.vermontlaw.edu/",
  },
  {
    label: "Vermont Technical College",
    value: "VTC",
    url: "http://www.vtc.vsc.edu/",
  },
  { label: "Villa Julie College", value: "VJC", url: "http://www.vjc.edu/" },
  {
    label: "Villanova University",
    value: "VU_2",
    url: "http://www.villanova.edu/",
  },
  { label: "Virginia College", value: "VC_2", url: "http://www.vc.edu/" },
  {
    label: "Virginia Commonwealth University",
    value: "VCU",
    url: "http://www.vcu.edu/",
  },
  {
    label: "Virginia Intermont College",
    value: "VIC",
    url: "http://www.vic.edu/",
  },
  {
    label: "Virginia International University",
    value: "VIU",
    url: "http://www.viu.edu/",
  },
  {
    label: "Virginia Military Institute",
    value: "VMI",
    url: "http://www.vmi.edu/",
  },
  {
    label:
      "Virginia Polytechnic Institute and State University (Virginia Tech)",
    value: "VPISU(T",
    url: "http://www.vt.edu/",
  },
  {
    label: "Virginia State University",
    value: "VSU_1",
    url: "http://www.vsu.edu/",
  },
  {
    label: "Virginia Union University",
    value: "VUU",
    url: "http://www.vuu.edu/",
  },
  {
    label: "Virginia Wesleyan College",
    value: "VWC",
    url: "http://www.vwc.edu/",
  },
  { label: "Viterbo College", value: "VC_3", url: "http://www.viterbo.edu/" },
  { label: "Voorhees College", value: "VC_4", url: "http://www.voorhees.edu/" },
  { label: "Wabash College", value: "WC", url: "http://www.wabash.edu/" },
  { label: "Wagner College", value: "WC_1", url: "http://www.wagner.edu/" },
  { label: "Wake Forest University", value: "WFU", url: "http://www.wfu.edu/" },
  { label: "Walden University", value: "WU", url: "http://www.waldenu.edu/" },
  { label: "Walla Walla College", value: "WWC", url: "http://www.wwc.edu/" },
  {
    label: "Walsh College of Accountancy and Business Administration",
    value: "WCABA",
    url: "http://www.walshcol.edu/",
  },
  { label: "Walsh University", value: "WU_1", url: "http://www.walsh.edu/" },
  {
    label: "Warner Pacific College",
    value: "WPC",
    url: "http://www.warnerpacific.edu/",
  },
  {
    label: "Warner Southern College",
    value: "WSC",
    url: "http://www.warner.edu/",
  },
  {
    label: "Warren Wilson College",
    value: "WWC_1",
    url: "http://www.warren-wilson.edu/",
  },
  { label: "Wartburg College", value: "WC_2", url: "http://www.wartburg.edu/" },
  {
    label: "Washburn University",
    value: "WU_2",
    url: "http://www.washburn.edu/",
  },
  {
    label: "Washington and Lee University",
    value: "WLU",
    url: "http://www.wlu.edu/",
  },
  {
    label: "Washington Bible College",
    value: "WBC",
    url: "http://www.bible.edu/",
  },
  {
    label: "Washington College",
    value: "WC_3",
    url: "http://www.washcoll.edu/",
  },
  {
    label: "Washington State University",
    value: "WSU",
    url: "http://www.wsu.edu/",
  },
  {
    label: "Washington State University, Spokane",
    value: "WSUS",
    url: "http://www.spokane.wsu.edu/",
  },
  {
    label: "Washington State University, Tri-Cities",
    value: "WSUT",
    url: "http://www.tricity.wsu.edu/",
  },
  {
    label: "Washington State University, Vancouver",
    value: "WSUV",
    url: "http://www.vancouver.wsu.edu/",
  },
  {
    label: "Washington University in St. Louis",
    value: "WUISL",
    url: "http://www.wustl.edu/",
  },
  {
    label: "Wayland Baptist University",
    value: "WBU",
    url: "http://www.wbu.edu/",
  },
  {
    label: "Waynesburg College",
    value: "WC_4",
    url: "http://www.waynesburg.edu/",
  },
  { label: "Wayne State College", value: "WSC_1", url: "http://www.wsc.edu/" },
  {
    label: "Wayne State University",
    value: "WSU_1",
    url: "http://www.wayne.edu/",
  },
  { label: "Webber College", value: "WC_5", url: "http://www.webber.edu/" },
  {
    label: "Webb Institute",
    value: "WI",
    url: "http://www.webb-institute.edu/",
  },
  {
    label: "Weber State University",
    value: "WSU_2",
    url: "http://www.weber.edu/",
  },
  {
    label: "Webster University",
    value: "WU_3",
    url: "http://www.webster.edu/",
  },
  {
    label: "Webster University North Florida",
    value: "WUNF",
    url: "http://www.webster.edu/jack/",
  },
  {
    label: "Weill Medical College of Cornell University",
    value: "WMCCU",
    url: "http://www.med.cornell.edu/",
  },
  {
    label: "Wellesley College",
    value: "WC_6",
    url: "http://www.wellesley.edu/",
  },
  { label: "Wells College", value: "WC_7", url: "http://www.wells.edu/" },
  {
    label: "Wentworth Institute of Technology",
    value: "WIT",
    url: "http://www.wit.edu/",
  },
  {
    label: "Wesleyan College",
    value: "WC_8",
    url: "http://www.wesleyan-college.edu/",
  },
  {
    label: "Wesleyan University",
    value: "WU_4",
    url: "http://www.wesleyan.edu/",
  },
  { label: "Wesley College", value: "WC_9", url: "http://www.wesley.edu/" },
  {
    label: "Wesley College Mississippi",
    value: "WCM",
    url: "http://www.wesleycollege.com/",
  },
  {
    label: "Westbrook University",
    value: "WU_5",
    url: "http://www.westbrooku.edu/",
  },
  {
    label: "West Chester University of Pennsylvania",
    value: "WCUP",
    url: "http://www.wcupa.edu/",
  },
  {
    label: "West Coast University",
    value: "WCU",
    url: "http://www.westcoastuniversity.com/",
  },
  {
    label: "Western Baptist College",
    value: "WBC_1",
    url: "http://www.wbc.edu/",
  },
  {
    label: "Western Bible College",
    value: "WBC_2",
    url: "http://www.westernbible.edu/",
  },
  {
    label: "Western Carolina University",
    value: "WCU_1",
    url: "http://www.wcu.edu/",
  },
  {
    label: "Western Connecticut State University",
    value: "WCSU",
    url: "http://www.wcsu.ctstateu.edu/",
  },
  {
    label: "Western Governors University",
    value: "WGU",
    url: "http://www.wgu.edu/",
  },
  {
    label: "Western Illinois University",
    value: "WIU",
    url: "http://www.wiu.edu/",
  },
  {
    label: "Western International University",
    value: "WIU_1",
    url: "http://www.west.edu/",
  },
  {
    label: "Western Kentucky University",
    value: "WKU",
    url: "http://www.wku.edu/",
  },
  {
    label: "Western Maryland College",
    value: "WMC",
    url: "http://www.wmdc.edu/",
  },
  {
    label: "Western Michigan University",
    value: "WMU",
    url: "http://www.wmich.edu/",
  },
  {
    label: "Western New England College",
    value: "WNEC",
    url: "http://www.wnec.edu/",
  },
  {
    label: "Western New Mexico University",
    value: "WNMU",
    url: "http://www.wnmu.edu/",
  },
  {
    label: "Western Oregon University",
    value: "WOU",
    url: "http://www.wou.edu/",
  },
  {
    label: "Western State College",
    value: "WSC_2",
    url: "http://www.western.edu/",
  },
  {
    label: "Western States Chiropractic College",
    value: "WSCC",
    url: "http://www.wschiro.edu/",
  },
  {
    label: "Western State University College of Law",
    value: "WSUCL",
    url: "http://www.wsulaw.edu/",
  },
  {
    label: "Western State University College of Law - Orange County",
    value: "WSUCL-OC",
    url: "http://www.wsulaw.edu/",
  },
  {
    label: "Western Washington University",
    value: "WWU",
    url: "http://www.wwu.edu/",
  },
  {
    label: "Westfield State College",
    value: "WSC_3",
    url: "http://www.wsc.mass.edu/",
  },
  {
    label: "West Liberty State College",
    value: "WLSC",
    url: "http://www.wlsc.wvnet.edu/",
  },
  {
    label: "Westminster College Fulton",
    value: "WCF",
    url: "http://www.wcmo.edu/",
  },
  {
    label: "Westminster College New Wilmington",
    value: "WCNW",
    url: "http://www.westminster.edu/",
  },
  {
    label: "Westminster College of Salt Lake City",
    value: "WCSLC",
    url: "http://www.wcslc.edu/",
  },
  {
    label: "Westmont College",
    value: "WC_10",
    url: "http://www.westmont.edu/",
  },
  {
    label: "West Suburban College of Nursing",
    value: "WSCN",
    url: "http://www.curf.edu/~wscasseyp/wscn.htm",
  },
  {
    label: "West Texas A&M University",
    value: "WTAU",
    url: "http://www.wtamu.edu/",
  },
  {
    label: "West Virginia School of Osteopathic Medicine",
    value: "WVSOM",
    url: "http://www.wvsom.edu/",
  },
  {
    label: "West Virginia State College",
    value: "WVSC",
    url: "http://www.wvsc.edu/",
  },
  {
    label: "West Virginia University",
    value: "WVU",
    url: "http://www.wvu.edu/",
  },
  {
    label: "West Virginia University Institute of Technology",
    value: "WVUIT",
    url: "http://wvit.wvnet.edu/",
  },
  {
    label: "West Virginia Wesleyan College",
    value: "WVWC",
    url: "http://www.wvwc.edu/",
  },
  {
    label: "Westwood College",
    value: "WC_11",
    url: "http://www.westwood.edu/",
  },
  {
    label: "Wheaton College Massachusetts",
    value: "WCM_1",
    url: "http://www.wheatonma.edu/",
  },
  {
    label: "Wheeling Jesuit University",
    value: "WJU",
    url: "http://www.wju.edu/",
  },
  {
    label: "Wheelock College",
    value: "WC_12",
    url: "http://www.wheelock.edu/",
  },
  { label: "Whitman College", value: "WC_13", url: "http://www.whitman.edu/" },
  {
    label: "Whittier College",
    value: "WC_14",
    url: "http://www.whittier.edu/",
  },
  {
    label: "Whitworth College",
    value: "WC_15",
    url: "http://www.whitworth.edu/",
  },
  {
    label: "Wichita State University",
    value: "WSU_3",
    url: "http://www.twsu.edu/",
  },
  {
    label: "Widener University",
    value: "WU_6",
    url: "http://www.widener.edu/",
  },
  {
    label: "Wilberforce University",
    value: "WU_7",
    url: "http://www.wilberforce.edu/",
  },
  {
    label: "Wilbur Wright College",
    value: "WWC_2",
    url: "http://www.ccc.edu/wright/",
  },
  { label: "Wiley College", value: "WC_16", url: "http://www.wileyc.edu/" },
  { label: "Wilkes University", value: "WU_8", url: "http://www.wilkes.edu/" },
  {
    label: "Willamette University",
    value: "WU_9",
    url: "http://www.willamette.edu/",
  },
  {
    label: "William Carey College",
    value: "WCC",
    url: "http://www.wmcarey.edu/",
  },
  {
    label: "William Jewell College",
    value: "WJC",
    url: "http://www.jewell.edu/",
  },
  {
    label: "William Mitchell College of Law",
    value: "WMCL",
    url: "http://www.wmitchell.edu/",
  },
  {
    label: "William Paterson University",
    value: "WPU",
    url: "http://www.wpunj.edu/",
  },
  {
    label: "William Penn College",
    value: "WPC_1",
    url: "http://www.wmpenn.edu/",
  },
  {
    label: "Williams Baptist College",
    value: "WBC_3",
    url: "http://www.wbcoll.edu/",
  },
  {
    label: "Williams College",
    value: "WC_17",
    url: "http://www.williams.edu/",
  },
  {
    label: "William Tyndale College",
    value: "WTC",
    url: "http://www.williamtyndale.edu/",
  },
  {
    label: "William Woods University",
    value: "WWU_1",
    url: "http://www.wmwoods.edu/",
  },
  {
    label: "Wilmington College",
    value: "WC_18",
    url: "http://www.wilmcoll.edu/",
  },
  {
    label: "Wilmington College",
    value: "WC_19",
    url: "http://www.wilmington.edu/",
  },
  { label: "Wilson College", value: "WC_20", url: "http://www.wilson.edu/" },
  {
    label: "Wingate University",
    value: "WU_10",
    url: "http://www.wingate.edu/",
  },
  {
    label: "Winona State University",
    value: "WSU_4",
    url: "http://www.winona.msus.edu/",
  },
  {
    label: "Winston-Salem State University",
    value: "WSU_5",
    url: "http://www.wssu.edu/",
  },
  {
    label: "Winthrop University",
    value: "WU_11",
    url: "http://www.winthrop.edu/",
  },
  {
    label: "Wisconsin Lutheran College",
    value: "WLC",
    url: "http://www.wlc.edu/",
  },
  {
    label: "Wisconsin School of Professional Psychology",
    value: "WSPP",
    url: "http://www.execpc.com/~wspp/",
  },
  {
    label: "Wittenberg University",
    value: "WU_12",
    url: "http://www.wittenberg.edu/",
  },
  { label: "Wofford College", value: "WC_21", url: "http://www.wofford.edu/" },
  {
    label: "Woodbury University",
    value: "WU_13",
    url: "http://www.woodburyu.edu/",
  },
  {
    label: "Worcester Polytechnic Institute",
    value: "WPI",
    url: "http://www.wpi.edu/",
  },
  {
    label: "Worcester State College",
    value: "WSC_4",
    url: "http://www.worc.mass.edu/",
  },
  {
    label: "Wright Institute",
    value: "WI_1",
    url: "http://www.wrightinst.edu/",
  },
  {
    label: "Wright State University",
    value: "WSU_6",
    url: "http://www.wright.edu/",
  },
  { label: "Xavier University", value: "XU", url: "http://www.xu.edu/" },
  {
    label: "Xavier University of Louisiana",
    value: "XUL",
    url: "http://www.xula.edu/",
  },
  { label: "Yale University", value: "YU", url: "http://www.yale.edu/" },
  { label: "Yeshiva University", value: "YU_1", url: "http://www.yu.edu/" },
  { label: "York College Nebraska", value: "YCN", url: "http://www.york.edu/" },
  {
    label: "York College of Pennsylvania",
    value: "YCP",
    url: "http://www.yorkcol.edu/",
  },
  {
    label: "Yorker International University",
    value: "YIU",
    url: "http://www.nyuniversity.net/",
  },
  {
    label: "York University",
    value: "YU_2",
    url: "http://www.yorkuniversity.us/",
  },
  {
    label: "Youngstown State University",
    value: "YSU",
    url: "http://www.ysu.edu/",
  },
];

export const collegesHashTable = colleges.reduce((hash, college) => {
  hash[college.label] = college;
  return hash;
}, {});

//export default CollegePicker;
