import { createClient } from "@supabase/supabase-js";

export const supabase = createClient(
  "https://jaupbyhwvfulpvkfxmgm.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImphdXBieWh3dmZ1bHB2a2Z4bWdtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODQ2MDM4MzUsImV4cCI6MjAwMDE3OTgzNX0.ng3kxKK2ZGkWtl2xkbaFoTqclkeTFNCr_Ca3e4O1tGc",
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
      detectSessionInUrl: true,
    },
  }
);

export const picURL =
  "https://jaupbyhwvfulpvkfxmgm.supabase.co/storage/v1/object/public/user_pictures/";
