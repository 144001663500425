import React, { useState } from "react";
import "./LandingPage.css";
import logo from "./cabanaAppLogoDupe.png";
import backgroundImage from "./cabana-website-background.png";
import appStoreImage from "./Unknown.png";
import connectionsImage from "./personal-connections.png";
import campusFilteringImage from "./campus-filtering.png";
import secureMessagingImage from "./secure-messaging.png";
import comminutyForumImage from "./community-forum.png";
import collegeStudentImage from "./college-students.png";
import instagramLogo from "./instagramLogo.png";

function LandingPage() {
  const [menuOpen, setMenuOpen] = useState(false);
  //alert("LandingPage");

  function goToApp() {
    window.location.href =
      "https://apps.apple.com/us/app/cabana-find-college-roomates/id6464455801"; // Replace with your desired URL
  }

  function goToInsta() {
    window.location.href =
      "https://www.instagram.com/thecabanaapp?igsh=cjFwdno1OTgyejY=";
  }

  return (
    <div className="landing-page">
      {/* Header with Navbar */}
      <header className="landing-header">
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
          <h1>Cabana</h1>
        </div>
        <nav className={`main-nav ${menuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href="#download">Download</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            {/*<li>
              <a href="#testimonials">Testimonials</a>
  </li>*/}
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
        <div
          className="hamburger-menu"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </header>

      <section
        className="hero-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <h2>Your Home, Your Choice</h2>
        <p>Dont take chances on a bad roommate</p>
        <button className="cta-button" onClick={goToApp}>
          Get Started
        </button>
      </section>
      {/* Features or Benefits */}

      {/* Testimonials */}
      <div className="curve-element-top"></div>
      <section className="we-offer">
        <div className="icon-item">
          <div className="icon-text-container">
            <img src={connectionsImage} className="icon" />
            <span>
              <div className="offer-item">Personalized Matching:</div> Advanced
              algorithms for pinpoint roommate matches based on your
              preferences.
            </span>
          </div>
        </div>
        <div className="icon-item">
          <div className="icon-text-container">
            <img src={campusFilteringImage} className="icon" />
            <span>
              <div className="offer-item">Campus-Specific Filters:</div> Find
              roommates in your dorm, college, or nearby residences easily.
            </span>
          </div>
        </div>
        <div className="icon-item">
          <div className="icon-text-container">
            <img src={secureMessagingImage} className="icon" />
            <span>
              <div className="offer-item">Secure Messaging:</div> Chat with
              potential roomies safely within our platform.
            </span>
          </div>
        </div>
        <div className="icon-item">
          <div className="icon-text-container">
            <img src={comminutyForumImage} className="icon" />
            <span>
              <div className="offer-item">Community Forum:</div> Share
              experiences, ask questions, and get tips from fellow students.
            </span>
          </div>
        </div>
        {/* Add more divs if you have other items or leave them empty for blank grid cells */}
      </section>
      <div className="curve-element-bottom"></div>

      <section id="about" className="about-section">
        <h2>About Cabana</h2>
        <div className="about-description">
          <div className="about-content">
            <p className="about-content">
              Founded by college students, for college students, Cabana was born
              out of personal experiences of navigating the challenges of
              finding the right roommate. We understand the importance of
              compatibility, whether it's related to study habits, sleep
              schedules, or hobbies.
            </p>
          </div>
          <img src={collegeStudentImage} className="image" />
        </div>
      </section>
      <section id="download" className="download-section">
        <div className="feature">
          {/*<img src="path_to_icon1.svg" alt="Feature 1" />*/}
          <h2>Download on the app store now</h2>
        </div>
        <div className="feature">
          <button className="download-button" onClick={goToApp}>
            <img className="download-image" src={appStoreImage} />
          </button>
        </div>
        {/* ... add other features similarly */}
      </section>
      <section id="contact" className="contact-information">
        Our Socials!
        <div className="socials-list">
          <button className="download-button">
            <img
              src={instagramLogo}
              className="social-item"
              onClick={goToInsta}
            />
          </button>
        </div>
      </section>
      {/* Footer */}

      <footer className="landing-footer">
        <p>&copy; 2023 Cabana App</p>
      </footer>
    </div>
  );
}

export default LandingPage;
