import React, { useState, useEffect } from "react";
import logo from "./cabanaAppLogoDupe.png";
import backgroundImage from "./congratulations-background.png";
import appStoreImage from "./Unknown.png";
import connectionsImage from "./personal-connections.png";
import campusFilteringImage from "./campus-filtering.png";
import secureMessagingImage from "./secure-messaging.png";
import comminutyForumImage from "./community-forum.png";
import collegeStudentImage from "./college-students.png";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import "./congratulations.css";
import "./LandingPage.css";
import "./forgotPassword.css";
import { supabase } from "./supabase.js";

function ForgotPassword() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [newPassword, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
      }
    });
  }, []);

  function goToApp() {
    window.location.href =
      "https://apps.apple.com/us/app/cabana-find-college-roomates/id6464455801"; // Replace with your desired URL
  }

  async function handleUpdatePassword() {
    // Ensure the password and confirmation password match
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    if (!newPassword || !confirmPassword) {
      alert("Please enter a new password");
      return;
    }

    if (newPassword.length < 6 || confirmPassword.length < 6) {
      alert("Password must be at least 6 characters");
      return;
    }

    try {
      // Call Supabase's API to reset the password
      const { data, error } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (error) {
        alert(error.message);
      } else {
        setPassword("");
        setConfirmPassword("");
        alert("Password updated successfully");
        goToApp();
      }
    } catch (error) {
      alert(error.message);
    }
  }

  function handlePassword(event) {
    setPassword(event.target.value);
  }

  function handleConfirmPassword(event) {
    setConfirmPassword(event.target.value);
  }

  const navigate = useNavigate();

  function goToHomePage() {
    navigate("/");
  }

  return (
    <div className="congrats-page">
      {/* Header with Navbar */}
      <header className="landing-header">
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
          <h1>Cabana</h1>
        </div>
        <nav className={`main-nav ${menuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href="/#download">Download</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            {/*<li>
              <a href="#testimonials">Testimonials</a>
  </li>*/}
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
        <div
          className="hamburger-menu"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </header>

      <section
        className="congrats-hero-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="congrats-platform">
          <h2>Forgot Password?</h2>
          <p className="congrats-hero-section-text">
            Fill out the following to get you back on track!
          </p>

          <p className="input-text">New Password:</p>
          <input
            className="input-control"
            type="text"
            color="white"
            value={newPassword}
            onChange={handlePassword}
          />
          <p className="input-text">Confirm Password:</p>
          <input
            className="input-control"
            type="text"
            color="white"
            value={confirmPassword}
            onChange={handleConfirmPassword}
          />
          <button className="go-to-app-button" onClick={handleUpdatePassword}>
            Go to the App
          </button>
        </div>
      </section>
      <section className="we-offer">
        <div className="icon-text-container">
          <img src={connectionsImage} className="icon" />
          <span>
            <div className="offer-item">Personalized Matching:</div> Advanced
            algorithms for pinpoint roommate matches based on your preferences.
          </span>
        </div>
        <div className="icon-text-container">
          <img src={campusFilteringImage} className="icon" />
          <span>
            <div className="offer-item">Campus-Specific Filters:</div> Find
            roommates in your dorm, college, or nearby residences easily.
          </span>
        </div>
        <div className="icon-text-container">
          <img src={secureMessagingImage} className="icon" />
          <span>
            <div className="offer-item">Secure Messaging:</div> Chat with
            potential roomies safely within our platform.
          </span>
        </div>
        <div className="icon-text-container">
          <img src={comminutyForumImage} className="icon" />
          <span>
            <div className="offer-item">Community Forum:</div> Share
            experiences, ask questions, and get tips from fellow students.
          </span>
        </div>
        {/* Add more divs if you have other items or leave them empty for blank grid cells */}
      </section>
      {/* Features or Benefits */}

      {/* Testimonials */}

      {/* Footer */}

      <footer className="landing-footer">
        <p>&copy; 2023 Cabana App</p>
      </footer>
    </div>
  );
}

export default ForgotPassword;
