import React, { useState } from "react";
import logo from "./cabanaAppLogoDupe.png";
import backgroundImage from "./congratulations-background.png";
import appStoreImage from "./Unknown.png";
import connectionsImage from "./personal-connections.png";
import campusFilteringImage from "./campus-filtering.png";
import secureMessagingImage from "./secure-messaging.png";
import comminutyForumImage from "./community-forum.png";
import collegeStudentImage from "./college-students.png";
import "./congratulations.css";
import "./LandingPage.css";

function Congratulations() {
  const [menuOpen, setMenuOpen] = useState(false);
  //alert("LandingPage");

  function goToApp() {
    window.location.href =
      "https://apps.apple.com/us/app/cabana-find-college-roomates/id6464455801"; // Replace with your desired URL
  }

  return (
    <div className="congrats-page">
      {/* Header with Navbar */}
      <header className="landing-header">
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
          <h1>Cabana</h1>
        </div>
        <nav className={`main-nav ${menuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href="/#download">Download</a>
            </li>
            <li>
              <a href="/#about">About</a>
            </li>
            {/*<li>
              <a href="#testimonials">Testimonials</a>
  </li>*/}
            <li>
              <a href="/#contact">Contact</a>
            </li>
          </ul>
        </nav>
        <div
          className="hamburger-menu"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </header>

      <section
        className="congrats-hero-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="congrats-platform">
          <h2>Success!</h2>
          <p className="congrats-hero-section-text">
            Your account is ready to go. We're over the moon to have you here.
            Let's start exploring the community together.
          </p>
          <button className="go-to-app-button" onClick={goToApp}>
            Go to the App
          </button>
        </div>
      </section>
      <section className="we-offer">
        <div className="icon-text-container">
          <img src={connectionsImage} className="icon" />
          <span>
            <div className="offer-item">Personalized Matching:</div> Advanced
            algorithms for pinpoint roommate matches based on your preferences.
          </span>
        </div>
        <div className="icon-text-container">
          <img src={campusFilteringImage} className="icon" />
          <span>
            <div className="offer-item">Campus-Specific Filters:</div> Find
            roommates in your dorm, college, or nearby residences easily.
          </span>
        </div>
        <div className="icon-text-container">
          <img src={secureMessagingImage} className="icon" />
          <span>
            <div className="offer-item">Secure Messaging:</div> Chat with
            potential roomies safely within our platform.
          </span>
        </div>
        <div className="icon-text-container">
          <img src={comminutyForumImage} className="icon" />
          <span>
            <div className="offer-item">Community Forum:</div> Share
            experiences, ask questions, and get tips from fellow students.
          </span>
        </div>
        {/* Add more divs if you have other items or leave them empty for blank grid cells */}
      </section>
      {/* Features or Benefits */}

      {/* Testimonials */}

      {/* Footer */}

      <footer className="landing-footer">
        <p>&copy; 2023 Cabana App</p>
      </footer>
    </div>
  );
}

export default Congratulations;
