import logo from "./logo.svg";
import "./App.css";
import HomePage from "./LandingPage";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Congratulations from "./congratulationsPage";
import ForgotPassword from "./forgotPassword.js";
import PrivacyPolicy from "./privacyPolicy.js";
import Advertising from "./advertising.js";

function App() {
  return (
    <Router>
      <div className="App">
        {/* Button to navigate to /hello */}

        <Routes>
          <Route path="/congratulations" element={<Congratulations />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/advertising" element={<Advertising />} />
          <Route path="/" element={<HomePage />} />
        </Routes>

        {/* Other routes can go here, e.g., /profile, /search */}
      </div>
    </Router>
  );
}

function Header() {
  return <header className="header"></header>;
}

export default App;
