import React, { useState, useEffect } from "react";
import logo from "./cabanaAppLogoDupe.png";
import backgroundImage from "./congratulations-background.png";
import appStoreImage from "./Unknown.png";
import connectionsImage from "./personal-connections.png";
import campusFilteringImage from "./campus-filtering.png";
import secureMessagingImage from "./secure-messaging.png";
import comminutyForumImage from "./community-forum.png";
import collegeStudentImage from "./college-students.png";
import "./congratulations.css";
import "./LandingPage.css";
import "./advertising.css";
import { colleges } from "./colleges.js";
import { supabase } from "./supabase.js";
import Compressor from "compressorjs";
import theHawkinImage from "./the-hawkin.png";
import nowLeasingImage from "./now-leasing.png";

//const colleges = ["college 1", "college 2", "college 3"];

function Advertising() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [adImage, setAdImage] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const [durationValue, setDurationValue] = useState(0);
  const [intensityValue, setIntensityValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [adLink, setAdLink] = useState("");
  const [adEmail, setAdEmail] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredColleges, setFilteredColleges] = useState([]);
  const [adHeader, setAdHeader] = useState("");
  const [adContent, setAdContent] = useState("");
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [totalDiscount, setTotalDiscount] = useState(0);

  const buttonClassName1 = isActive1 ? "tier-button-active1" : "tier-button1";
  const buttonClassName2 = isActive2 ? "tier-button-active2" : "tier-button2";
  const buttonClassName3 = isActive3 ? "tier-button-active3" : "tier-button3";

  const tiers = [
    {
      id: 1,
      price: "$" + 49.99,
      name: "Starter Exposure",
      description: (
        <ul>
          <li>Basic ad placement with limited frequency.</li>
          <li>
            Ideal for small businesses or newcomers to online advertising,
            seeking minimal but targeted visibility.
          </li>
        </ul>
      ),
    },
    {
      id: 2,
      price: "$" + 149.99,
      name: "Established Reach",
      description: (
        <ul>
          <li>Most popular choice</li>
          <li>Increased ad frequency and improved placement.</li>
          <li>
            Suitable for businesses looking to moderately increase their market
            presence.
          </li>
        </ul>
      ),
    },
    {
      id: 3,
      price: "$" + 299.99,
      name: "Elite Engagement",
      description: (
        <ul>
          <li>
            Priority placement ahead of other tiers, maximizing visibility and
            impact
          </li>
          <li>
            Ideal for established businesses seeking consistent traffic to their
            website
          </li>
        </ul>
      ),
    },
  ];

  const collegeList = colleges
    .filter((college) => college && college.label)
    .map((college) => college.label);

  // alert(collegeList);

  useEffect(() => {
    calculateCost();
  }, [durationValue, intensityValue]);

  const handleDurationChange = (event) => {
    setDurationValue(event.target.value);
  };
  const handleIntensityChange = (event) => {
    setIntensityValue(event.target.value);
  };
  const [fileObject, setFileObject] = useState(null);
  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      new Compressor(file, {
        quality: 0.5, // The quality of the output image, lower is more compressed
        // The maximum height of the output image
        success(result) {
          const imageURL = URL.createObjectURL(file);
          setAdImage(imageURL);

          const compressedFile = new File([result], result.name, {
            type: result.type,
            lastModified: Date.now(),
          });

          setFileObject(compressedFile);
        },
        error(err) {
          console.error(err.message);
        },
      });
    }
  };

  const handleAdHeaderChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input length exceeds 33 characters
    if (inputValue.length > 33) {
      // If it exceeds, truncate the input to 33 characters
      const truncatedValue = inputValue.substring(0, 33);

      // Update the state with the truncated value
      setAdHeader(truncatedValue);
    } else {
      // If within limit, update the state with the input value
      setAdHeader(inputValue);
    }

    const textarea = event.target;
    const foo = 30;
    textarea.style.height = `${foo}px`;

    if (textarea.scrollHeight > textarea.clientHeight) {
      // Increase the height just enough to fit the content and remove the scrollbar
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleAdContentChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input length exceeds 33 characters
    if (inputValue.length > 95) {
      // If it exceeds, truncate the input to 33 characters
      const truncatedValue = inputValue.substring(0, 95);

      // Update the state with the truncated value
      setAdContent(truncatedValue);
    } else {
      // If within limit, update the state with the input value
      setAdContent(inputValue);
    }
  };

  const handleAdLinkChange = (event) => {
    setAdLink(event.target.value);
  };

  const handleAdEmailChange = (event) => {
    setAdEmail(event.target.value);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (!value) {
      setFilteredColleges([]);
    } else {
      const filtered = collegeList.filter((college) =>
        college.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredColleges(filtered.slice(0, 10));
    }
  };

  const calculateCost = (e) => {
    let cost = 0;
    if (intensityValue === 1) {
      cost = 50 * values[durationValue];
    } else if (intensityValue === 2) {
      cost = 150 * values[durationValue];
    } else if (intensityValue === 3) {
      cost = 300 * values[durationValue];
    }

    // Calculate discount based on the selected value
    let discountPercentage = (durationValue - 1) * 0.1; // Discount starts from 10% for value 1
    let discount = cost * discountPercentage;

    setTotalDiscount(Math.round(discount * 100) / 100);

    // Subtract discount from the total cost
    let totalCost = Math.round((cost - discount) * 100) / 100 - 0.01;

    if (totalCost < 0) {
      totalCost = 0;
    } else {
    }

    setTotalCost(totalCost);
  };
  const values = [0, 1, 3, 6, 12];

  //const [amount, setAmount] = useState("");

  const handleCheckout = async () => {
    try {
      let imageFile;

      if (
        fileObject &&
        fileObject instanceof Blob &&
        intensityValue > 0 &&
        durationValue > 0 &&
        searchTerm &&
        adLink &&
        adHeader &&
        adContent &&
        adEmail
      ) {
        const reader = new FileReader();
        reader.readAsDataURL(fileObject); // Use the file object
        reader.onload = async () => {
          // Continue with your logic...
          imageFile = reader.result;
          let payload = {
            tier: intensityValue,
            duration: durationValue,
            college: searchTerm,
            image: imageFile,
            ad_link: adLink,
            ad_header: adHeader,
            ad_content: adContent,
            ad_email: adEmail,
            // Base64 encoded image
            // ... other payload properties ...
          };
          try {
            const response = await fetch(
              "https://jaupbyhwvfulpvkfxmgm.supabase.co/functions/v1/advertisements",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  // Add any additional headers as needed
                },
                // You can include an empty JSON object as the body
                body: JSON.stringify(payload),
              }
            );

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            //alert(response.status);

            const data = await response.json();
            console.log("Success:", data);

            if (response.ok) {
              // Redirect to Stripe Checkout
              window.location.href = data.payLink;
            }
          } catch (error) {
            console.error("Error:", error);
            alert("Something went wrong, please try again later");
          }
        };

        reader.onerror = (error) => alert("Error: ", error);
      } else {
        alert("Please enter all fields");
      }

      // Wait for the image to be converted to Base64

      //alert(payload);

      // Redirect to Stripe Checkout
      // window.location.href = data.link; // Ensure 'data.link' is the correct field from your response
    } catch (error) {
      alert("something went wrong, please try again later");
    }
  };

  const handleClick1 = () => {
    // Update intensity value to 1
    setIntensityValue(1);
    setIsActive1(true);
    setIsActive2(false);
    setIsActive3(false);
  };

  const handleClick2 = () => {
    // Update intensity value to 1
    setIntensityValue(2);
    setIsActive1(false);
    setIsActive2(true);
    setIsActive3(false);
  };

  const handleClick3 = () => {
    // Update intensity value to 1
    setIntensityValue(3);
    setIsActive1(false);
    setIsActive2(false);
    setIsActive3(true);
  };

  function goToApp() {
    window.location.href =
      "https://apps.apple.com/us/app/cabana-find-college-roomates/id6464455801"; // Replace with your desired URL
  }

  return (
    <div className="congrats-page">
      {/* Header with Navbar */}
      <header className="landing-header">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
          <h1>Cabana</h1>
        </div>
        <nav className={`main-nav ${menuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href="/#download">Download</a>
            </li>
            <li>
              <a href="/#about">About</a>
            </li>
            {/*<li>
              <a href="#testimonials">Testimonials</a>
  </li>*/}
            <li>
              <a href="/#contact">Contact</a>
            </li>
          </ul>
        </nav>
        <div
          className="hamburger-menu"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </header>

      <section className="advertising-hero-section">
        <div className="header-1">Advertise on Cabana</div>
        <p className="advertisement-hero-section-text">
          Strategically place your advertisement where it counts! Get your
          message across to college students.
        </p>
        <div className="center">
          <div className="header-2">Examples</div>
          <div className="ad-examples-container">
            <img src={nowLeasingImage} alt="Example" className="ad-examples" />
            <img src={theHawkinImage} alt="Example" className="ad-examples" />
          </div>
        </div>

        <div className="header-2">Advertise Now!</div>
        <p className="advertisement-hero-section-text">
          Welcome! We're excited to help you connect with your ideal audience.
          Here's how to get started:
        </p>
        <p class="advertisement-hero-section-text step">
          1. <strong>Select Your Image:</strong> Choose the perfect image that
          represents your message.
        </p>
        <p class="advertisement-hero-section-text step">
          2. <strong>Craft Your Message:</strong> Fill in the title, header, and
          description to capture the essence of your advertisement.
        </p>
        <p class="advertisement-hero-section-text step">
          3. <strong>Customize Your Campaign:</strong> Decide on the duration
          and tier of your ad, and select the college where you want to make an
          impact.
        </p>
        <p class="advertisement-hero-section-text step">
          4: <strong>Share Your Email</strong> – Give us your email. We'll send
          updates on your ad's performance.
        </p>
        <p class="advertisement-hero-section-text step">
          5: <strong>Link Your Site</strong> – Add your site's HTTPS link. This
          is where people go when they click your ad.
        </p>

        <input
          type="file"
          id="adImage"
          name="adImage"
          accept="image/*"
          className="input-image"
          onChange={handleImageChange}
        />

        <div className="ad-submission-container">
          {adImage ? (
            <>
              <div className="center">
                <div className="ad-image-container">
                  <img
                    id="displayedImage"
                    src={adImage}
                    alt="Selected"
                    className="ad-submission"
                  />
                </div>
              </div>
              <div className="ad-text-container">
                <textarea
                  className="input-control-ad-text-header"
                  type="text"
                  value={adHeader}
                  onChange={handleAdHeaderChange}
                  placeholder="Header..."
                  spellcheck="false"
                />

                <textarea
                  className="input-control-ad-text-content"
                  type="text"
                  value={adContent}
                  onChange={handleAdContentChange}
                  placeholder="Description..."
                  spellcheck="false"
                />
              </div>
            </>
          ) : (
            <>
              <div className="center">
                <div className="picture-outline"></div>
              </div>

              <div className="ad-text-container">
                <div className="header-outline"></div>

                <div className="content-outline"></div>
              </div>
            </>
          )}
        </div>

        <div className="warning">
          For the most accurate preview of your advertisement, please view it on
          a full-screen desktop browser.
        </div>

        <div className="slider">
          <div className="slider-value">{values[durationValue]} Months</div>
          <input
            type="range"
            min="0"
            max="4"
            value={durationValue}
            onChange={handleDurationChange}
          />
        </div>
        <div className="tier-container">
          <div className={buttonClassName1} onClick={handleClick1}>
            Tier 1
          </div>
          <div className={buttonClassName2} onClick={handleClick2}>
            Tier 2
          </div>
          <div className={buttonClassName3} onClick={handleClick3}>
            Tier 3
          </div>
        </div>
        <input
          className="input-control-link"
          type="text"
          value={adLink}
          onChange={handleAdLinkChange}
          placeholder="Link your site - https://www..."
        />

        <input
          className="input-control-link"
          type="text"
          value={adEmail}
          onChange={handleAdEmailChange}
          placeholder="Email - john@gmail.com..."
        />

        <div>
          <input
            className="input-control-advertising"
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search for colleges..."
          />
          {searchTerm && (
            <div className="column">
              {filteredColleges.map((college, index) => (
                <div
                  className="input-control-college"
                  key={index}
                  onClick={() => {
                    setSearchTerm(college);
                    setFilteredColleges([]);
                  }}
                >
                  {college}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="total-cost">Total Cost: ${totalCost}</div>

        <div className="total-cost">
          ${totalDiscount} in savings on your {values[durationValue]}
          -month plan. The longer your commitment, the more you save!
        </div>

        <button className="purchase-button" onClick={handleCheckout}>
          Checkout
        </button>
      </section>
      <section className="tiers-hero-section">
        <h1>About our Tiers</h1>
        <table className="ad-pricing-table">
          <thead>
            <tr>
              <th>Tier</th>
              <th>1 Month Price ($)</th>
              <th>Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {tiers.map((tier) => (
              <tr key={tier.id}>
                <td>Tier {tier.id}</td>
                <td>{tier.price}</td>
                <td>{tier.name}</td>
                <td>{tier.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Features or Benefits */}

      {/* Testimonials */}

      {/* Footer */}

      <footer className="landing-footer">
        <p>&copy; 2023 Cabana App</p>
      </footer>
    </div>
  );
}

export default Advertising;
