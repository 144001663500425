import React, { useState, useEffect } from "react";
import logo from "./cabanaAppLogoDupe.png";
import backgroundImage from "./congratulations-background.png";
import appStoreImage from "./Unknown.png";
import connectionsImage from "./personal-connections.png";
import campusFilteringImage from "./campus-filtering.png";
import secureMessagingImage from "./secure-messaging.png";
import comminutyForumImage from "./community-forum.png";
import collegeStudentImage from "./college-students.png";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import "./congratulations.css";
import "./LandingPage.css";
import "./forgotPassword.css";
import { supabase } from "./supabase.js";

function PrivacyPolicy() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [newPassword, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
      }
    });
  }, []);

  async function handleUpdatePassword() {
    // Ensure the password and confirmation password match
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    if (newPassword.length < 6 || confirmPassword.length < 6) {
      alert("Password must be at least 6 characters");
      return;
    }

    try {
      // Call Supabase's API to reset the password
      const { data, error } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (error) {
        alert(error.message);
      } else {
        setPassword("");
        setConfirmPassword("");
        alert("Password updated successfully");
        goToHomePage();
      }
    } catch (error) {
      alert(error.message);
    }
  }

  function handlePassword(event) {
    setPassword(event.target.value);
  }

  function handleConfirmPassword(event) {
    setConfirmPassword(event.target.value);
  }

  const navigate = useNavigate();

  function goToHomePage() {
    navigate("/");
  }

  return (
    <div className="congrats-page">
      {/* Header with Navbar */}
      <header className="landing-header">
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
          <h1>Cabana</h1>
        </div>
        <nav className={`main-nav ${menuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href="/#download">Download</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            {/*<li>
              <a href="#testimonials">Testimonials</a>
  </li>*/}
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
        <div
          className="hamburger-menu"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </header>
      <div>1. Introduction </div>
      <div>
        Welcome to Cabana, a dedicated platform designed to connect college
        students with potential roommates. Your privacy is of utmost importance
        to us. This Privacy Policy document outlines the types of information
        collected by Cabana, and how we use, maintain, protect, and disclose
        that information.
      </div>
      <div>2. Consent</div>
      <div>
        By using our app, you hereby consent to our Privacy Policy and agree to
        its terms.
      </div>
      <div>3. Information We Collect</div>
      <div>
        Personal Identification Information: We may collect personal
        identification information including, but not limited to, your name,
        email address, phone number, college/university details, and profile
        picture.
      </div>
      <div>
        Roommate Preferences: Information regarding your roommate preferences,
        living habits, interests, and hobbies to facilitate a compatible
        roommate match.
      </div>
      <div>
        Location Information: We may collect and process information about your
        geographical location to provide localized services.
      </div>
      <div>
        Usage Data: Information about how the user accesses and uses the app,
        which may include your IP address, browser type, and version, the pages
        of our app that you visit, the time and date of your visit, the time
        spent on those pages, and other diagnostic data.
      </div>
      <div>4. Cookies and Tracking Technologies</div>
      <div>
        We use cookies, beacons, tags, and other tracking technologies to
        collect and store information about your preferences and navigation to,
        from, and on our app.
      </div>
      <div>5. Use of Your Information</div>
      <div>
        We may use the collected data for various purposes including: - To
        provide and maintain our services. - To notify you about changes to our
        services. - To allow you to participate in interactive features of our
        service when you choose to do so. - To provide customer support. - To
        gather analysis or valuable information so that we can improve our
        service. - To monitor the usage of our service. - To detect, prevent,
        and address technical issues.
      </div>
      <div>6. Sharing and Disclosure of Information</div>
      <div>
        Third-Party Service Providers: We may share your information with
        third-party service providers to provide necessary services on our
        behalf, perform service-related services, or assist us in analyzing how
        our service is used.
      </div>
      <div>
        Business Transactions: If Cabana is involved in a merger, acquisition,
        or asset sale, your personal data may be transferred.
      </div>
      <div>7. Security of Data We employ</div>
      <div>
        Commercially acceptable means of protecting your data but remember that
        no method of transmission over the internet or method of electronic
        storage is 100% secure.
      </div>
      <div>8. Changes to This Privacy Policy</div>
      <div>
        We may update our Privacy Policy from time to time and will notify you
        of any changes by posting the new Privacy Policy on this page.
      </div>
      <div>9. Your Acceptance of These Terms</div>
      <div>
        By using Cabana, you signify your acceptance of this policy and terms of
        service. If you do not agree to this policy, please do not use our app.
      </div>
      <div>10. Contact Us</div>
      <div>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us at: Cabana team@thecabanaapp.com
      </div>

      <footer className="landing-footer">
        <p></p>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
